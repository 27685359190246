import { format } from 'date-fns';
import { AUDIT_SHEET_KEY } from '../constants';

function removeNullObjects(rawArray) {
  return rawArray.map(obj => {
    if (obj.data && Array.isArray(obj.data)) {
      const filteredData = obj.data.filter(dataObj => {
        const nonRequiredKeys = Object.keys(dataObj).filter(
          key =>
            key !== 'file_name' &&
            key !== 'chart_id' &&
            key !== 'row_id' &&
            key !== 'id'
        );
        const nonNullKeys = nonRequiredKeys.filter(
          key => dataObj[key] !== null
        );
        return nonNullKeys.length > 0;
      });
      obj.data = filteredData;
    }
    return obj;
  });
}


const sanitize = (sheets, providerOptions) => {
  //removing empty object from array
  // change the date, provider id
  const modified = [];
  sheets.forEach(sheet => {
    const updatedData = [];
    sheet.data.forEach(element => {
      if (Object.keys(element).length !== 0) {
        // const defaultColumnKeys = Object.keys(element);
        // const isColumnPresent = defaultColumnKeys.every(key => {
        // if(element[key]&&key==="file_name"){
        //   return false
        // }
        //   if (true || AUDIT_SHEET_KEY.includes(key) ) {
        //     if (true|| element[key] === null || element[key] === '') {
        //       return true;
        //     }
        //     return false;
        //   }
        //   return true;
        // });

        if (element.file_name) {
          const { old_rendering, ...newObj } = element;
          if (newObj.rendering) {
            if (
              typeof newObj.rendering === 'object' &&
              Object.keys(newObj.rendering).length
            ) {
              newObj.rendering = newObj.rendering.id;
            } else {
              const providerOption = providerOptions.find(
                p => p.value.toLowerCase() === newObj.rendering.toLowerCase()
              );
              if (providerOption) {
                newObj.rendering = providerOption.id;
              } else if (old_rendering) {
                newObj.rendering = old_rendering.id;
              }
            }
          }
          if (newObj.enc_dt) {
            const d = Date.parse(newObj.enc_dt);
            if (d) {
              newObj.enc_dt = format(new Date(d), 'yyyy-MM-dd');
            }
          }
          updatedData.push(newObj);
        }
      }
    });
    modified.push({
      ...sheet,
      data: updatedData,
    });
  });
  return removeNullObjects(modified);
};
export { sanitize,removeNullObjects };
