import { Box, Center, Text } from '@chakra-ui/react';
import { useContext, useMemo } from 'react';
import { defaultReactTableProp, getCellProps } from '../../../../utils/auditor_quality_table.util'
import { createColumnHelper } from '@tanstack/react-table';
import { ReportContext } from '../../../../providers/ReportProvider';
import { CQReactTable } from '@prasanna-transcend/code-quick-components';

const columnHelper = createColumnHelper();

const AuditorQualityTable = () => {
    const reportContext = useContext(ReportContext)
    const { auditorTableData, isAuditorTableLoading } = reportContext

    const columns = useMemo(() => [
        columnHelper.accessor('Quality', {
            header: () => 'Quality',
            enableSorting: false,
            cell: info => {
                const cellValue = info.getValue();

                return <Text py={2}>
                    {cellValue}
                </Text>
            },
        }),
        columnHelper.accessor('overall', {
            id: 'overall',
            enableSorting: false,
            header: () => <span>Overall</span>,
            cell: info => {
                const cellValue = info.getValue();
                if (!cellValue) {
                    return 'N/A';
                }

                return <Text>
                    {cellValue}
                </Text>
            },
        }),
        columnHelper.accessor('cpt', {
            id: 'cpt',
            enableSorting: false,
            header: () => <span>CPT</span>,
            cell: info => {
                const cellValue = info.getValue();
                if (cellValue === null) {
                    return 'N/A';
                }

                return <Text>
                    {cellValue}
                </Text>
            },
        }),
        columnHelper.accessor('e&m', {
            id: 'e&m',
            enableSorting: false,
            header: () => <span>E&M</span>,
            cell: info => {
                const cellValue = info.getValue();
                if (cellValue === null) {
                    return 'N/A';
                }

                return <Text>
                    {cellValue}
                </Text>
            },
        }),
        columnHelper.accessor('mod', {
            id: 'mod',
            enableSorting: false,
            header: () => <span>MOD</span>,
            cell: info => {
                const cellValue = info.getValue();
                if (cellValue === null) {
                    return 'N/A';
                }

                return <Text>
                    {cellValue}
                </Text>
            },
        }),
    ], []);

    return <Box m={10}>
        <CQReactTable
            data={auditorTableData}
            isLoading={isAuditorTableLoading}
            columns={columns}
            {...defaultReactTableProp}
            getCellProps={(cell, data) => getCellProps(cell, data, auditorTableData.length)}
            tableContainerProps={{
                borderRadius: '2xl'
            }}
        />
        {!auditorTableData.length ? <Center mt={10}> No data available</Center> : null}
    </Box>
}
export default AuditorQualityTable