/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { CQReactTable } from '@prasanna-transcend/code-quick-components';

import { createColumnHelper } from '@tanstack/react-table';
import useCQMediaQuery from '../../../../hooks/useCQMediaQuery';

import {
  defaultReactTableProp,
  paginationProps,
} from '../../../../utils/team.utils';
import { useNavigate } from 'react-router-dom';
import AppColors from '../../../../constants/AppColors';
import { AvatarEllipses } from '../../../../components/AvatarEllipses';
import { ActionComponent } from '../ActionComponent';
import { TeamContext } from '../../../../providers/TeamProvider';
import { TEAM_ORDERING } from '../../../../constants/constants';
import { toTitleCase } from '../../../../utils/common.utils';

const columnHelper = createColumnHelper();

const TeamTable = ({ setIsOpen, setCurrentTeam }) => {
  const teamContext = useContext(TeamContext);
  const navigate = useNavigate();
  const [ordering, setOrdering] = useState('');

  const { isUpdating, getTeamList, filterParams, handleApplyFilter } =
    teamContext;

  useEffect(() => {
    if (TEAM_ORDERING.includes(ordering)) {
      handleApplyFilter({ ...filterParams, ordering });
    }
  }, [ordering]);
  // media query
  const canScroll = useCQMediaQuery({
    lg: false,
    md: false,
    sm: true,
  });

  useEffect(() => {
    getTeamList.loadAPI();
  }, [filterParams]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        header: () => 'Name',
        cell: info => {
          const cellValue = info.getValue();
          return (
            <Text
              alignItems={'self-start'}
              fontWeight={'bold'}
              color={AppColors.secondary}
              mt="2"
            >
              {toTitleCase(cellValue)}
            </Text>
          );
        },
      }),
      columnHelper.accessor('members', {
        id: 'members',
        header: () => <span>Members</span>,
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return 'n/a';
          }

          return (
            <AvatarEllipses
              users={cellValue.map(member => ({
                ...member,
                name: `${member.first_name} ${member.last_name}`,
              }))}
              outerIndex={0}
              teamId={info.row.original.id}
            />
          );
        },
      }),
      columnHelper.accessor('open_rebutals', {
        id: 'open_rebutals',
        header: () => <span>Open Rebuttals</span>,
        cell: info => {
          const cellValue = info.getValue();
          if (cellValue === null) {
            return 'n/a';
          }

          return (
            <Text fontWeight={'normal'} color={AppColors.secondary}>
              {cellValue}
            </Text>
          );
        },
      }),
      columnHelper.accessor('active_audits', {
        header: () => 'Active Audits',
        id: 'active_audits',
        cell: info => {
          const cellValue = info.getValue();
          if (cellValue === null) {
            return 'n/a';
          }

          return (
            <Text fontWeight={'normal'} color={AppColors.secondary}>
              {cellValue}
            </Text>
          );
        },
      }),

      columnHelper.accessor('last_90_assigned', {
        header: () => 'Assigned(Last 90 Days)',
        id: 'last_90_assigned',
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return 'n/a';
          }

          return (
            <Text fontWeight={'normal'} color={AppColors.secondary}>
              {cellValue}
            </Text>
          );
        },
      }),
      columnHelper.accessor('avg_completion_time', {
        header: () => 'Avg. Completion Time',
        id: 'avg_completion_time',
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return 'n/a';
          }

          return (
            <Text fontWeight={'bold'} color={AppColors.secondary}>
              {cellValue}
            </Text>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: 'actions',
        header: () => 'Actions',
        enableSorting: false,
        cell: info => {
          return (
            <ActionComponent
              team={info.row.original}
              navigate={navigate}
              setIsOpen={setIsOpen}
              setCurrentTeam={setCurrentTeam}
            />
          );
        },
      }),
    ],
    [teamContext.isTeamListLoading]
  );

  function handlePreviousPage() {
    teamContext.getTeamList.loadPrevious();
  }

  function handleNextPage() {
    teamContext.getTeamList.loadNext();
  }

  function handleSizeChange(size) {
    teamContext.getTeamList({ size });
  }

  return (
    <Box
      pointerEvents={isUpdating ? 'none' : 'inherit'}
      overflowX={canScroll ? 'scroll' : 'inherit'}
    >
      <CQReactTable
        isLoading={teamContext.isTeamListLoading}
        data={teamContext.teamList}
        columns={columns}
        {...defaultReactTableProp}
        {...paginationProps(getTeamList)}
        onPreviousPage={handlePreviousPage}
        onNextPage={handleNextPage}
        onSizeChange={handleSizeChange}
        onAsc={header => {
          setOrdering(header);
        }}
        onDesc={header => {
          setOrdering(`-${header}`);
        }}
      />
    </Box>
  );
};
export default TeamTable;
