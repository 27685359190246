import { HStack } from '@chakra-ui/react';
import {
  DotMenu,
  PrimaryButton,
} from '@prasanna-transcend/code-quick-components';
import AppColors from '../../../constants/AppColors';
import { DotMenuIcon } from '../../../constants/IconData';
import ROUTES from '../../../constants/Routes';
import { replaceRoute } from '../../../utils/common.utils';

const ActionComponent = ({ team, navigate, setIsOpen, setCurrentTeam }) => {
  const teamId = team.id;
  const createNewUser = replaceRoute(ROUTES.CREATE_TEAM_USER, { teamId });
  const teamprofile = replaceRoute(ROUTES.TEAM_PROFILE, { teamId });
  const editTeam = replaceRoute(ROUTES.EDIT_TEAM, { teamId });
  return (
    <HStack justifyContent={'center'}>
      <PrimaryButton
        bg={AppColors.secondary}
        onClick={() => navigate(teamprofile)}
        fontSize={'sm'}
        fontWeight="normal"
        size="md"
        borderRadius="3xl"
        px={10}
        outline={`2px`}
        _hover={{
          transition: 'all .3s ease',
          bgColor: AppColors.white,
          color: AppColors.secondary,
          outline: `2px solid ${AppColors.secondary}`,
        }}
      >
        Manage
      </PrimaryButton>
      <DotMenu
        icon={<DotMenuIcon w={1} />}
        menuDataItem={[
          {
            onClick: () => {
              navigate(createNewUser);
            },
            children: (
              <div style={{ color: AppColors.black }}>Add New User</div>
            ),
          },
          {
            onClick: () => {
              navigate(editTeam);
            },
            children: <div style={{ color: AppColors.black }}>Edit Team</div>,
          },
          {
            onClick: () => {
              setIsOpen(true);
              setCurrentTeam(team.id);
            },
            children: <div style={{ color: AppColors.red }}> Delete Team</div>,
          },
        ]}
      />
    </HStack>
  );
};

export { ActionComponent };
