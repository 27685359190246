import { useBoolean } from '@prasanna-transcend/code-quick-components';
import React, { useState } from 'react';
import DatePickerPopup from '../../../../components/modal/date_picker_popup/DatePickerPopup';
import { DotMenuIcon } from '../../../../constants/IconData';
import { Text } from '@chakra-ui/react';
import AppColors from '../../../../constants/AppColors';
import { formatDate } from '../../../../utils/formatDate.util';

function AuditAccuracyDatePicker({ onFilterChange, isLoading,filterParams }) {
  const [isModalOpen, imoState] = useBoolean(false);

  const [dateRanges, setDateRanges] = useState({
    currentDate:formatDate(filterParams?.end_date),
    endDate: formatDate( filterParams?.start_date),
    previousStartDate: formatDate( filterParams?.previous_end_date),
    previousEndDate: formatDate(filterParams?.previous_start_date),
  });

  const onSubmit = async dates => {
    onFilterChange({ ...dates });
    imoState.off();
  };
  return (
    <>
      {/* <DotMenuIcon
        style={{ cursor: 'pointer' }}
        onClick={() => {
          imoState.on();
        }}
      /> */}

      <Text
        cursor={'pointer'}
        _hover={{ textDecoration: 'underline' }}
        onClick={() => imoState.on()}
        color={AppColors.primary}
      >
        {' '}
        Select Dates
      </Text>

      <DatePickerPopup
        isOpen={isModalOpen}
        onClose={imoState.off}
        onSubmit={onSubmit}
        isLoading={isLoading}
        dateRanges={dateRanges}
      />
    </>
  );
}

export default AuditAccuracyDatePicker;
