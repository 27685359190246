import { HStack, Text } from '@chakra-ui/react';
import { PrimaryButton } from '@prasanna-transcend/code-quick-components';
import React from 'react';
import AppColors from '../../../constants/AppColors';
import { DownIcon, SortIcon, UpIcon } from '../../../constants/IconData';
import ROUTES from '../../../constants/Routes';
import { FONT_FAMILY } from '../../../constants/Theme';
import FileViewAction from '../../assigned_audits/components/FileViewAction';

const renderActionComponent = (auditUpload, navigate) => {
  const buttonObj = getRowObject(auditUpload.status);
  const buttonProps = buttonObj.buttonProps ?? {};
  return (
    <HStack justifyContent={'center'}>
      <PrimaryButton
        disabled={auditUpload?.status === 'AWAITING AUDIT'}
        bg={buttonObj.color}
        fontSize={'14px'}
        fontWeight="normal"
        size="md"
        borderRadius="3xl"
        px="10"
        {...buttonProps}
        onClick={() => {
          navigate(
            ROUTES.VIEW_AUDIT_SHEET.replace(':uploadId', auditUpload.id)
          );
        }}
      >
        {buttonObj.data}
      </PrimaryButton>
      <FileViewAction auditUpload={auditUpload} />
    </HStack>
  );
};

const renderHeader = (header, accessor, sorting, order, sort) => {
  return (
    <HStack
      display={'flex'}
      alignItems="center"
      justifyContent={'center'}
      gap={0}
      onClick={() => {
        sorting(accessor);
      }}
      style={{ cursor: 'pointer' }}
    >
      <Text
        style={{
          fontSize: '14px',
          fontWeight: 'bold',
          fontFamily: FONT_FAMILY.ptSans,
          marginRight: 1,
        }}
      >
        {header}
      </Text>
      {accessor ? (
        order === accessor ? (
          <UpIcon
            style={{
              width: 10,
              height: 10,
              marginLeft: 1,
            }}
          />
        ) : order === `-${accessor}` ? (
          <DownIcon
            style={{
              width: 10,
              height: 10,
              marginLeft: 1,
            }}
          />
        ) : (
          <SortIcon
            style={{
              width: 15,
              height: 15,
              marginLeft: 1,
            }}
          />
        )
      ) : null}
    </HStack>
  );
};

const getRowObject = status => {
  let buttonObj;
  switch (status) {
    case 'AWAITING REVIEW':
      buttonObj = {
        data: 'View',
        color: AppColors.primary,
        bgColor: AppColors.loginFormBg,
      };
      break;
    case 'AWAITING AUDIT':
      buttonObj = {
        data: 'View',
        color: AppColors.offWhite,
        bgColor: 'white',
        buttonProps: { color: AppColors.darkGray },
      };
      break;
    case 'ON HOLD':
      buttonObj = {
        data: 'View',
        color: AppColors.primary,
        bgColor: AppColors.loginFormBg,
      };
      break;
    case 'IN PROGRESS':
      buttonObj = {
        data: 'View',
        color: AppColors.secondary,
        bgColor: AppColors.loginFormBg,
      };
      break;
    case 'IN REVIEW':
      buttonObj = {
        data: 'View',
        color: AppColors.primary,
        bgColor: AppColors.loginFormBg,
      };
      break;
    case 'ARCHIVED':
      buttonObj = {
        data: 'View',
        color: AppColors.secondary,
        bgColor: AppColors.loginFormBg,
      };
      break;
    case 'AWAITING ASSIGNMENT':
      buttonObj = {
        data: 'View',
        color: AppColors.primary,
        bgColor: AppColors.loginFormBg,
      };
      break;
    case 'QA REBUTTAL':
      buttonObj = {
        data: 'View',
        color: AppColors.danger,
        bgColor: AppColors.lightDanger,
      };
      break;
    case 'CLIENT REBUTTAL':
      buttonObj = {
        data: 'View',
        color: AppColors.danger,
        bgColor: AppColors.lightDanger,
      };
      break;
    default:
      buttonObj = {
        data: 'View',
        color: AppColors.secondary,
        bgColor: 'white',
      };
  }
  return buttonObj;
};

export { renderActionComponent, renderHeader, getRowObject };
