/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import { Motion, spring } from 'react-motion';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  HStack,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import AppColors from '../../../constants/AppColors';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { defaultStyle, OPEN_STATUS, REBUTTAL_STATUS } from '../constants';
import { MentionsInput, Mention } from 'react-mentions';
import { formatDistanceStrict } from 'date-fns';
import { AuditSheetContext } from '../../../providers/AuditSheetProvider';
import { useContext } from 'react';
import { getRowColArray } from '../getRowColumn';
import { AuthContext } from '../../../providers/AuthProvider';
import RenderMessage from './RenderMessage';
import CustomIcon from './CustomComponent';
import { RebuttalIcon } from '../../../constants/IconData';
import { successToast } from '../../../components/successToast';
import { useToastr } from '@prasanna-transcend/code-quick-components';

const CommentBox = ({
  showMenu,
  metaData,
  closeComment,
  sheet,
  isSelected,
  onRebuttalCheck,
  coords
}) => {
  const authContext = React.useContext(AuthContext);
  const toast = useToastr();


  const auditSheetContext = useContext(AuditSheetContext);
  const {
    currentUpload,
    users,
    isPostCommentLoading,
    comments,
    setComments,
    isCommentLoading,
    postComment,
    commentFlags,
    setCommentFlags,
  } = auditSheetContext;
  let clientName = '';
  if (currentUpload) {
    clientName = `${authContext?.currentUser?.first_name} ${authContext?.currentUser?.last_name}`;
  }
  const { pageX, pageY, canRebuttal, selection } = metaData;
  const [message, setMessage] = useState('');
  const [rebuttalMessage, setRebuttalMessage] = useState('');

  const onCommentSubmit = async updateBody => {
    const { row, column } = getRowColArray(selection[0], sheet);
    const body = {
      audit_sheet_rows: row,
      audit_sheet_columns: column,
      action: 'OPEN',
      chart: currentUpload.id,
      ...updateBody,
    };
    let userTagged = [];
    const strArray = updateBody.comment.match(/@\[(.\d*?)]/g);
    if (strArray && strArray.length) {
      strArray.map(str => {
        const matchedRegex = str.match(/@\[(.\d*?)]/);
        // Handle user mention
        if (
          matchedRegex &&
          matchedRegex.length > 0 &&
          !userTagged.includes(matchedRegex[1])
        ) {
          const userId = matchedRegex[1];
          userTagged.push(Number(userId));
        }
      });
    }
    if (userTagged.length) {
      body.tagged_user = userTagged;
    }
    if (
      updateBody.action !== REBUTTAL_STATUS &&
      comments.length &&
      comments[0].id
    ) {
      body.parent = comments[0].id;
    }
    const response = await postComment(body);
    if (response.id) {
      setComments(prev => [...prev, response]);
      response.audit_sheet_rows.map(rowId => {
        if (commentFlags[rowId]) {
          commentFlags[rowId] = [
            ...commentFlags[rowId],
            ...response.audit_sheet_columns,
          ];
        } else {
          commentFlags[rowId] = [...response.audit_sheet_columns];
        }
      });
      setCommentFlags({ ...commentFlags });
      const toastProps = {
        header: 'Success!',
        description: `${
          response.action === REBUTTAL_STATUS
            ? 'Marked as rebuttal.'
            : !response.parent
            ? 'Comment sent'
            : 'Reply sent'
        }`,
      };
      successToast(toast, toastProps);
    }
  };

  const clearState = () => {
    setMessage('');
    setRebuttalMessage('');
    // closeComment()
  };
  const onCommentClick = async () => {
    const body = {
      comment: message,
    };
    body.action = OPEN_STATUS;
    await onCommentSubmit(body);
    clearState();
  };
  const onSubmitClick = async () => {
    const body = {
      comment: rebuttalMessage,
    };
    if (isSelected) {
      body.action = REBUTTAL_STATUS;
    } else {
      body.action = OPEN_STATUS;
    }
    await onCommentSubmit(body);
    clearState();
    closeComment();
  };

  const renderCommentInput = (message, setMessage) => {
    return (
      <MentionsInput
        placeholder="Comment or tag others with @"
        value={message}
        onChange={e => {
          setMessage(e.target.value);
        }}
        style={defaultStyle}
        allowSpaceInQuery={true}
      >
        <Mention
          displayTransform={login => {
            const currentUser = users.find(u => u.id == login);
            return `@${currentUser.first_name} ${currentUser.last_name}`;
          }}
          trigger="@"
          data={users}
          markup="@[__id__]"
          style={{
            fontWeight: '50px',
            backgroundColor: AppColors.bgColor,
          }}
        />
      </MentionsInput>
    );
  };

  const renderFooter = (successButtonName, onSuccessClick, message) => {
    return (
      <HStack m={1} mt={2} justifyContent={'space-between'}>
        <Button
          mr={1}
          bgColor={AppColors.yellowColor}
          borderRadius="lg"
          fontSize={'16px'}
          color={'white'}
          paddingInline={10}
          disabled={!message}
          isLoading={isPostCommentLoading}
          onClick={async () => onSuccessClick()}
        >
          {successButtonName}
        </Button>
        <Button
          mr={1}
          borderRadius="lg"
          fontSize={'16px'}
          color={AppColors.secondary}
          paddingInline={10}
          borderColor={AppColors.secondary}
          borderWidth={2}
          bgColor={'transparent'}
          onClick={() => {
            setMessage('');
            closeComment();
          }}
        >
          Cancel
        </Button>
      </HStack>
    );
  };

  const renderRebuttal = () => {
    return (
      <>
        <HStack mb={3} justifyContent={'space-between'}>
          <Text fontSize={'sm'} m={1}>
            {clientName}
          </Text>
          <HStack>
            <Text
              fontSize={'sm'}
              fontWeight={'bold'}
              color={AppColors.yellowColor}
            >
              Mark as rebuttal
            </Text>
            <Checkbox
              icon={<CustomIcon />}
              defaultChecked={isSelected}
              onChange={async e => {
                if (!e.target.checked) {
                  onRebuttalCheck(false);
                } else {
                  onRebuttalCheck(true);
                }
              }}
              colorScheme="transparent"
              border={'black'}
            />
          </HStack>
        </HStack>
        {renderCommentInput(rebuttalMessage, setRebuttalMessage)}
        {renderFooter('Submit', onSubmitClick, rebuttalMessage)}
      </>
    );
  };

  const renderCreateComment = () => {
    return (
      <>
        <HStack justifyContent={'space-between'}>
          <Text fontSize={'sm'} m={1}>
            {clientName}
          </Text>
        </HStack>
        <Box>{renderCommentInput(message, setMessage)}</Box>
        {renderFooter('Comment', onCommentClick, message)}
      </>
    );
  };

  const renderCommentLoader = () => {
    return (
      <Stack>
        <Skeleton height="15px" width="30px" />
        <Skeleton height="15px" />
        <Skeleton height="15px" width="30px" mt={5} />
        <Skeleton height="15px" />
      </Stack>
    );
  };

  const renderCommentBody = (comment, i) => {
    return (
      <Box
        key={`${i}-comment`}
        m={1}
        fontSize={'sm'}
        bg={
          comment.action === 'REBUTTAL' ? AppColors.lightDanger : 'transparent'
        }
      >
        <HStack p={1} justifyContent="space-between">
          <HStack>
            <Text fontSize={'sm'} fontWeight="bold" color={AppColors.secondary}>
              {`${comment.user.first_name} ${comment.user.last_name}`}
            </Text>
            <Text fontSize={'xs'} color={'gray'} style={{ paddingTop: 2 }}>
              {comment.date
                ? formatDistanceStrict(new Date(comment.date), new Date(), {
                    addSuffix: true,
                  })
                : 'yesterday'}
            </Text>
          </HStack>
          {comment.action === 'REBUTTAL' ? (
            <RebuttalIcon style={{ width: 20, height: 20, marginRight: 5 }} />
          ) : null}
        </HStack>
        {
          <Box p={1}>
            <RenderMessage comment={comment} key={`${i}-message`} />
          </Box>
        }
        <Divider my={1} />
        {/* {i < comments.length - 1 ? <Divider my={1} /> : null} */}
      </Box>
    );
  };
  const renderComments = comments => {
    if (isCommentLoading) {
      return (
        <>
          {renderCommentLoader()}
          <Divider my={2} />
          <Box>{renderCommentInput(message, setMessage)}</Box>
          {renderFooter('Comment', onCommentClick, message)}
        </>
      );
    }
    return (
      <>
        <PerfectScrollbar
          style={{
            maxHeight: '150px',
          }}
        >
          {comments.map((comment, i) => {
            return (
              <>
                {renderCommentBody(comment, i)}
                {comment.reply_comments.length
                  ? comment.reply_comments.map((c, index) => {
                      return renderCommentBody(c, `${i}-${index}`);
                    })
                  : null}
              </>
            );
          })}
        </PerfectScrollbar>
        <Divider my={2} />
        <Box>{renderCommentInput(message, setMessage)}</Box>
        {renderFooter('Comment', onCommentClick, message)}
      </>
    );
  };

  const renderCommentContainer = () => {
    return (
      <>
        {comments.length
          ? renderComments(comments)
          : renderCreateComment(comments)}
      </>
    );
  };


  return (
    <Motion
      defaultStyle={{ opacity: 0 }}
      style={{ opacity: !showMenu ? spring(0) : spring(1) }}
    >
      {interpolatedStyle => (
        <>
          {showMenu ? (
            <Box
              zIndex={1000}
              shadow={'md'}
              bg="white"
              p={4}
              className="menu-container"
              style={{
                borderRadius: '12px',
                position: 'absolute',
                // top: window.pageYOffset + (pageY < 100 ? 100 : pageY - 300),
                // left:pageX ,
                top:coords.y+15,
                left:coords.x,
                opacity: interpolatedStyle.opacity,
              }}
            >
              {canRebuttal ? renderRebuttal() : renderCommentContainer()}
            </Box>
          ) : (
            <></>
          )}
        </>
      )}
    </Motion>
  );
};
export default CommentBox;
