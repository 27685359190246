import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Spinner,
  Text,
  useBoolean,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import CQContainer from '../../../components/layout/CQContainer';
import AppColors from '../../../constants/AppColors';
import { BackArrowIcon, LinkIcon } from '../../../constants/IconData';
import { FONT_FAMILY } from '../../../constants/Theme';
import { DeleteModal } from '../components/DeleteModal';
import ROUTES from '../../../constants/Routes';
import AccountsProvider, {
  AccountContext,
} from '../../../providers/AccountsProvider';
import { replaceRoute } from '../../../utils/common.utils';
import { useForm } from 'react-hook-form';
import FormInputField from '../../../components/form/form_text_input/FormTextInput';
import { withProvider } from '../../../hoc/withProvider';
import { sendMail } from '../../../utils/send_mail.util';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { onlyAlphabetsRegex } from '../../../constants/constants';

const EditPhysicianForm = () => {
  const accountsContext = useContext(AccountContext);
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const editPoviderSchema = yup.object({
    first_name: yup
      .string()
      .required('First Name is required')
      .matches(onlyAlphabetsRegex, 'First Name must be valid'),
    last_name: yup
      .string()
      .required('Last Name is required')
      .matches(onlyAlphabetsRegex, 'Last Name must be valid'),
    email: yup.string().email().required('Email is required'),
  });

  const forms = useForm({
    resolver: yupResolver(editPoviderSchema),
    mode: 'onSubmit',
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
    },
  });

  const { accountId, hospitalId, departmentId, physicianId } = params;
  const [isDeleteModalOpen, idmoState] = useBoolean(false);
  const [, ifpState] = useBoolean(false);
  const { control, handleSubmit, reset } = forms;
  const [values, setValue] = useState({});
  const [isChecked, icState] = useBoolean(false)

  const hospitalRoute = replaceRoute(ROUTES.GET_HOSPITAL, {
    hospitalId,
    accountId,
  });

  const healthSystemRoute = replaceRoute(ROUTES.EDIT_ACCOUNT, {
    accountId,
  });

  const departmentRoute = replaceRoute(ROUTES.GET_DEPARTMENT, {
    accountId,
    hospitalId,
    departmentId,
  });

  function renderBreadCrumb() {
    return (
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink
            onClick={() => {
              navigate(healthSystemRoute, {
                state: { ...location.state },
              });
            }}
          >
            <Heading
              variant={'h6'}
              fontSize={'x-large'}
              fontFamily={FONT_FAMILY.baiJamurjee}
              fontWeight={600}
              textTransform="capitalize"
            >
              {' '}
              {location.state?.accountName}
            </Heading>
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink
            onClick={() => {
              navigate(hospitalRoute, {
                state: { ...location.state },
              });
            }}
          >
            <Text fontFamily={FONT_FAMILY.baiJamurjee}>
              {' '}
              {location.state?.hospitalName}
            </Text>
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink
            onClick={() => {
              navigate(departmentRoute, {
                state: { ...location.state },
              });
            }}
          >
            <Text fontFamily={FONT_FAMILY.baiJamurjee}>
              {' '}
              {location.state?.departmentName}
            </Text>
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <Text
            textTransform={'capitalize'}
            fontFamily={FONT_FAMILY.baiJamurjee}
            color={AppColors.secondary}
            fontWeight={'semibold'}
          >
            {location.state?.providerName}
          </Text>
        </BreadcrumbItem>
      </Breadcrumb>
    );
  }

  function renderBackToAccounts() {
    return (
      // Without HStack, this NavLink is occupying entire width
      <HStack>
        <NavLink to={ROUTES.ACCOUNTS}>
          <Box>
            <HStack my={2} spacing={'md'}>
              <BackArrowIcon
                style={{ width: 40, height: 40, cursor: 'pointer' }}
              />
              <Text
                fontSize={'md'}
                fontWeight="bold"
                size={'md'}
                mx={2}
                fontFamily={FONT_FAMILY.openSans}
                color={AppColors.secondary}
              >
                Back to All Accounts
              </Text>
            </HStack>
          </Box>
        </NavLink>
      </HStack>
    );
  }

  async function fetchPhysicians() {
    try {
      ifpState.on();
      const response = await accountsContext.getProvider(physicianId);
      reset({
        first_name: response.first_name,
        last_name: response.last_name,
        email: response.email,
      });
      setValue(response);
    } catch (e) {
    } finally {
      ifpState.off();
    }
  }

  useEffect(() => {
    fetchPhysicians();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateSubmit = fromData => {
    const { email, id, is_active, ...providerBody } = values;

    accountsContext.onProviderUpdate(physicianId, fromData);
  };

  function renderMainContent() {
    return (
      <Box
        mt={6}
        bg={AppColors.white}
        p={4}
        borderRadius={'lg'}
        boxShadow={'md'}
      >
        {Object.keys(values).length !== 0 ? (
          <>
            <Grid
              templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
              gap={6}
              px={3}
            >
              <GridItem>
                <Text
                  color={AppColors.secondary}
                  fontSize={'md'}
                  fontWeight="bold"
                  size={'md'}
                  my={3}
                  fontFamily={FONT_FAMILY.openSans}
                >
                  First Name
                </Text>

                <FormInputField
                  name="first_name"
                  control={control}
                  id="first_name"
                  leftIcon={''}
                  // value={values?.first_name}
                  // onChange={e => {
                  //   setValue({ ...values, first_name: e.target.value });
                  // }}
                />
              </GridItem>
              <GridItem>
                <Text
                  color={AppColors.secondary}
                  fontSize={'md'}
                  fontWeight="bold"
                  size={'md'}
                  my={3}
                  fontFamily={FONT_FAMILY.openSans}
                >
                  Last Name
                </Text>
                <FormInputField
                  name="last_name"
                  control={control}
                  id="last_name"
                  // value={values?.last_name ? values?.last_name : ''}
                  leftIcon={''}
                  // onChange={e => {
                  //   setValue({ ...values, last_name: e.target.value });
                  // }}
                />
              </GridItem>
              <GridItem>
                <Text
                  color={AppColors.secondary}
                  fontSize={'md'}
                  fontWeight="bold"
                  size={'md'}
                  my={3}
                  fontFamily={FONT_FAMILY.openSans}
                >
                  Email
                </Text>
                <FormInputField
                  name="email"
                  control={control}
                  id="email"
                  // value={values?.email}
                  leftIcon={''}
                  rightIcon={
                    <LinkIcon onClick={() => sendMail(values?.email)} />
                  }
                />
              </GridItem>
            </Grid>
            <Flex gap={6} my={3} p={5} justifyContent={'center'}>
              <Box>
                <NavLink to={ROUTES.ACCOUNTS}>
                  <Button
                    borderRadius={'full'}
                    bg={AppColors.white}
                    color={AppColors.black}
                    fontWeight={'normal'}
                    border={`2px solid ${AppColors.secondary}`}
                    w={150}
                  >
                    Cancel
                  </Button>
                </NavLink>
              </Box>
              <Box>
                <Button
                  borderRadius={'full'}
                  bg={AppColors.primary}
                  color={AppColors.white}
                  fontWeight={'normal'}
                  w={150}
                  onClick={handleSubmit(handleUpdateSubmit)}
                >
                  Update
                </Button>
              </Box>
            </Flex>
          </>
        ) : (
          <Flex justifyContent="center">
            <Spinner />
          </Flex>
        )}
      </Box>
    );
  }

  function renderMiddleContent() {
    return (
      <HStack>
        <Text
          color={AppColors.red}
          fontWeight="sm"
          cursor={'pointer'}
          onClick={() => idmoState.on()}
        >
          Delete Provider
        </Text>
        <Button
          borderColor={AppColors.secondary}
          borderWidth={1}
          bg={'white'}
          fontWeight={'normal'}
          color={AppColors.secondary}
          borderRadius={'full'}
          onClick={() => {
            navigate(
              replaceRoute(ROUTES.GET_ACCOUNT_DEPARTMENT, {
                accountId,
                hospitalId,
                departmentId,
              }),
              { state: location.state }
            );
          }}
        >
          Go to Dashboard
        </Button>
      </HStack>
    );
  }

  return (
    <CQContainer>
      <Box mx={'10%'}>
        {renderBreadCrumb()}
        <Flex justifyContent={'space-between'}>
          {renderBackToAccounts()}
          {renderMiddleContent()}
        </Flex>
        {renderMainContent()}

        <DeleteModal
          //   isDeleteLoading={accountsContext.isDepartmentLoading}
          onDelete={async () => {
            // await accountsContext.onDeleteDepartment(departmentId);
            await accountsContext.onDeleteProvider(physicianId);
            idmoState.off();
            // navigate to previous page
            const departmentRoute = replaceRoute(ROUTES.GET_DEPARTMENT, {
              accountId,
              hospitalId,
              departmentId,
            });
            navigate(departmentRoute);
          }}
          isDeleteModalOpen={isDeleteModalOpen}
          closeDeleteModal={() => {
            idmoState.off();
            icState.off();
          }}
          name={`${values?.first_name} ${values?.last_name}`}
          condition={
            'I understand this will remove the physician from this department.'
          }
          isChecked={isChecked} 
          icState={icState}
        />
      </Box>
    </CQContainer>
  );
};

export default withProvider(AccountsProvider, EditPhysicianForm);
