/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Center,
  HStack,
  Modal,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { useToastr } from '@prasanna-transcend/code-quick-components';
import React, { useContext, useEffect, useState } from 'react';
import AppColors from '../../../constants/AppColors';
import {
  CheckedIcon,
  CloseModalIcon,
  UncheckedIcon,
} from '../../../constants/IconData';
import { FONT_FAMILY } from '../../../constants/Theme';
import { TeamContext } from '../../../providers/TeamProvider';
import './modal.css';

const scrollStyle = {
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 4px lightGrey',
    width: '4px',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'grey',
    borderRadius: '4px',
  },
};

const SpecialtiesModal = ({
  isOpen,
  onClose,
  selected,
  setSelected,
  specialties,
  setCustomError,
}) => {
  // context
  const previousState = selected;
  const teamContext = useContext(TeamContext);
  const { getUserFromSpecialties, isMemberListLoading } = teamContext;
  const { isSpecialtiesLoading } = teamContext;
  const toast = useToastr();

  // state
  const [selectedSpecialties, setSelectedSpecialties] = useState(selected);

  useEffect(() => {
    setSelected(selectedSpecialties);
  }, [selectedSpecialties]);

  function handleSpecialtySelect(specialty, isSelected) {
    if (!isSelected) {
      const temp = selectedSpecialties.filter(id => id !== specialty.id);
      setSelectedSpecialties([...temp]);
    } else {
      setSelectedSpecialties([...selectedSpecialties, specialty.id]);
    }
  }

  function handleSave() {
    if (selectedSpecialties.length) {
      setCustomError('specialties', { ref: { name: 'specialties' } });
      getUserFromSpecialties({ specialties: selectedSpecialties }).then(
        onClose
      );
    } else {
      toast.showError({
        description: `Atleast one specialties should be selected`,
      });
    }
  }

  useEffect(() => {
    setSelectedSpecialties(selected);
  }, [selected]);

  function renderSpecialtyItem(specialty, index) {
    const isSelected = selectedSpecialties.includes(specialty.id);

    return (
      <HStack key={`add-member-${index}-specialties`}>
        {isSelected ? (
          <CheckedIcon
            style={{ cursor: 'pointer', width: 20, height: 20 }}
            onClick={() => handleSpecialtySelect(specialty, false)}
          />
        ) : (
          <UncheckedIcon
            style={{ cursor: 'pointer', width: 20, height: 20 }}
            onClick={() => handleSpecialtySelect(specialty, true)}
          />
        )}
        <Text fontSize={'sm'} textTransform={'capitalize'}>
          {specialty.name !== 'ent' ? specialty.name : 'ENT'}
        </Text>
      </HStack>
    );
  }

  function renderSpecialty() {
    if (isSpecialtiesLoading) {
      return <Spinner />;
    }

    if (specialties.length < 0) {
      return <Text>No Specialties found</Text>;
    }

    return (
      <React.Fragment>{specialties.map(renderSpecialtyItem)}</React.Fragment>
    );
  }
  return (
    <>
      <Modal
        size={'xl'}
        onClose={onClose}
        isOpen={isOpen}
        isCentered={true}
        onOverlayClick={() => {
          if (previousState.length) {
            setCustomError('specialties', { ref: { name: 'specialties' } });
          }
          setSelected(previousState);
          onClose();
        }}
      >
        <ModalOverlay
          bg={AppColors.modalOverlayColor}
          style={{ opacity: 0.8 }}
        />
        <ModalContent
          position={'fixed'}
          style={{ background: 'inherit', boxShadow: 'none', maxWidth: '50%' }}
        >
          <CloseModalIcon
            style={{
              cursor: 'pointer',
              height: '30px',
              width: '30px',
              alignSelf: 'end',
            }}
            onClick={onClose}
          />
          <Box
            bg="white"
            p={4}
            pb={20}
            w="100%"
            h="150%"
            borderRadius="md"
            my={3}
          >
            <Center
            mt={5}
              fontFamily={FONT_FAMILY.baiJamurjee}
              color={AppColors.secondary}
              fontSize={'2xl'}
              mb={5}
              fontWeight="medium"
            >
              Specialties
            </Center>
            <SimpleGrid
              sx={scrollStyle}
              overflowY={'scroll'}
              className={'modalBody'}
              maxHeight={'400px'}
              columns={3}
              spacing={2}
              m={10}
            >
              {renderSpecialty()}
            </SimpleGrid>

            <Center position={'relative'}>
              <HStack position={'absolute'} bottom={-5} top={4} >
                <Button
                  mr={1}
                  variant="outline"
                  color={AppColors.black}
                  style={{
                    paddingInline: '35px',
                    border:`2px solid ${AppColors.secondary}`
                  }}
                  fontSize="sm"
                  height={'50px'}
                  borderRadius="3xl"
                  
                  _hover={{
                    transition: 'all .3s ease',
                    bgColor: AppColors.secondary,
                    color: AppColors.white,
                    // outline: `2px solid ${AppColors.secondary}`,
                  }}
                  onClick={() => {
                    if (previousState.length) {
                      setCustomError('specialties', {
                        ref: { name: 'specialties' },
                      });
                    }
                    setSelected(previousState);
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  borderRadius="3xl"
                  color="white"
                  height={'50px'}
                  fontSize="sm"
                  bg={AppColors.secondary}
                  style={{ paddingInline: '35px' }}
                  onClick={handleSave}
                  outline={`2px`}
                  _hover={{
                    transition: 'all .3s ease',
                    bgColor: AppColors.white,
                    color: AppColors.secondary,
                    outline: `2px solid ${AppColors.secondary}`,
                  }}
                  isLoading={isMemberListLoading}
                >
                  Save Specialties
                </Button>
              </HStack>
            </Center>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};
export default SpecialtiesModal;
