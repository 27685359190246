/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Grid,
  GridItem,
  Heading,
  HStack,
  Text,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import CQContainer from '../../../components/layout/CQContainer';
import AppColors from '../../../constants/AppColors';
import { CREATE_MODE } from '../../../constants/constants';
import { BackArrowIcon } from '../../../constants/IconData';
import ROUTES from '../../../constants/Routes';
import { FONT_FAMILY } from '../../../constants/Theme';
import { withProvider } from '../../../hoc/withProvider';
import AccountsProvider, {
  AccountContext,
} from '../../../providers/AccountsProvider';
import accountsRepository from '../../../repositories/AccountsRepository';
import { getHeaderCellProps } from '../../../utils/assigned_audit.util';
import { replaceRoute } from '../../../utils/common.utils';
import { DeleteModal } from '../components/DeleteModal';
import AddDepartmentForm from '../forms/AddDepartmentForm';
import HospitalProfileSection from './components/HospitalProfileSection';
import RightContainer from './components/RightContainer';
import { EditIcon } from '@chakra-ui/icons';

const columnHelper = createColumnHelper();

const HospitalProfileViewContainer = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [mode, setMode] = useState(null);

  const accountsContext = useContext(AccountContext);
  const [searchFilters, setSearchFilters] = useState({ search: '' });
  const STATUS = {
    ACTIVE: { label: 'Active', color: AppColors.secondary },
    INACTIVE: { label: 'Inactive', color: AppColors.darkGray },
  };
  const { accountId, hospitalId } = params;

  const [isFetchingDepartment, ifdState] = useBoolean(false);
  const [isDeleteModalOpen, idmoState] = useBoolean(false);

  const [departments, setDepartments] = useState([]);
  const [departmentFormVisible, dfvState] = useBoolean(false);
  const [departmentData] = useState({});
  const [isGetDepartmentLoading] = useBoolean(false);

  async function fetchDepartments() {
    try {
      ifdState.on();
      const response = await accountsRepository.getDepartments({
        hospital: hospitalId,
        ...searchFilters,
      });
      setDepartments(response);
    } catch (e) {
    } finally {
      ifdState.off();
    }
  }

  useEffect(() => {
    accountsContext.getHospital(hospitalId);
  }, [hospitalId]);

  useEffect(() => {
    fetchDepartments();
  }, [searchFilters]);

  useEffect(() => {
    if (!accountsContext.ehrOptions.length) {
      accountsContext.fetchEHROptions();
    }
    if (!accountsContext.insuranceOptions.length) {
      accountsContext.fetchInsuranceOptions();
    }
    if (!accountsContext.specialtyOptions.length) {
      accountsContext.fetchSpecialtyOptions();
    }
  }, []);

  // department columns
  const departmentColumns = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        header: () => 'Department Name',
        enableSorting: true,
        cell: info => {
          const name = info.row.original.name;
          return (
            <Text fontSize="sm" fontWeight={'bold'}>
              {name}
            </Text>
          );
        },
      }),
      columnHelper.accessor('status', {
        header: () => 'Status',
        enableSorting: true,
        cell: info => {
          const status = info.row.original.is_active ? 'ACTIVE' : 'INACTIVE';
          return (
            <Text
              fontSize="sm"
              color={status === 'ACTIVE' ? 'black' : AppColors.darkGray}
            >
              {STATUS[status].label}
            </Text>
          );
        },
      }),
      columnHelper.accessor('actions', {
        header: () => 'Actions',
        enableSorting: false,
        cell: info => {
          const status = info.row.original.is_active ? 'ACTIVE' : 'INACTIVE';
          const departmentId = info.row.original.id;
          return (
            <Button
              size={'sm'}
              px={12}
              fontSize="sm"
              borderRadius={'full'}
              isLoading={isGetDepartmentLoading}
              rightIcon={<EditIcon />}
              textColor={'#fff'}
              color={status === 'ACTIVE' ? 'white' : AppColors.darkGray}
              outline={`2px solid ${AppColors.secondary}`}
              _hover={{
                transition: 'all .1s ease',
                bgColor: AppColors.white,
                color: AppColors.secondary,
              }}
              bg={
                status === 'ACTIVE' ? AppColors.secondary : AppColors.offWhite
              }
              onClick={async () => {
                // igdlState.on()
                // const response = await accountsContext.getDepartment(info.row.original.id)
                // setDepartmentData(response)
                // igdlState.off()
                // setMode(EDIT_MODE)
                // dfvState.on()
                navigate(
                  replaceRoute(ROUTES.GET_DEPARTMENT, {
                    accountId: accountId,
                    hospitalId: hospitalId,
                    departmentId: departmentId,
                  }),
                  {
                    state: {
                      ...location.state,
                      departmentName:
                        info.row.original?.name || 'Department Name ',
                    },
                  }
                );
              }}
            >
              Edit
            </Button>
          );
        },
      }),
    ];
  }, []);

  function _getHeaderCellProps(header) {
    return {
      ...getHeaderCellProps(header),
      justifyContent: header.id === 'name' ? 'flex-start' : 'center',
    };
  }

  function _getCellProps(row, cell) {
    const isName = cell.column.id === 'name';
    const textAlign = isName ? 'left' : 'center';
    const textTransform = isName ? 'capitalize' : 'none';
    const status = row.original.is_active ? 'ACTIVE' : 'INACTIVE';
    return {
      fontSize: 'md',
      textAlign,
      textTransform,
      style: {
        fontSize: 'sm',
        color: STATUS[status].color,
      },
    };
  }

  const healthSystemRoute = replaceRoute(ROUTES.EDIT_ACCOUNT, {
    accountId: params.accountId,
  });

  function renderBreadCrumb() {
    return (
      <Breadcrumb justifyContent={'center'}>
        <BreadcrumbItem>
          <BreadcrumbLink
            onClick={() => {
              navigate(healthSystemRoute, {
                state: { ...location.state },
              });
            }}
          >
            <Heading
              variant={'h6'}
              fontSize={'x-large'}
              fontFamily={FONT_FAMILY.baiJamurjee}
              fontWeight={600}
              textTransform="capitalize"
            >
              {location.state?.accountName}
            </Heading>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Text
            color={AppColors.secondary}
            fontWeight={'semibold'}
            textTransform={'capitalize'}
          >
            {location.state?.hospitalName}
          </Text>
        </BreadcrumbItem>
      </Breadcrumb>
    );
  }

  function renderBackToAccounts() {
    return (
      // Without HStack, this NavLink is occupying entire width
      <HStack>
        <NavLink to={ROUTES.ACCOUNTS}>
          <Box>
            <HStack my={2} spacing={'md'}>
              <BackArrowIcon
                style={{ width: 40, height: 40, cursor: 'pointer' }}
              />
              <Text
                fontSize={'md'}
                fontWeight="bold"
                size={'md'}
                mx={2}
                fontFamily={FONT_FAMILY.openSans}
                color={AppColors.secondary}
              >
                Back to All Accounts
              </Text>
            </HStack>
          </Box>
        </NavLink>
      </HStack>
    );
  }

  function renderHospitalInfo() {
    return (
      <HospitalProfileSection
        profileData={accountsContext.hospital}
        accountId={accountId}
      />
    );
  }

  function renderMiddleContent() {
    return (
      <HStack>
        <Text
          color={AppColors.red}
          fontWeight="sm"
          cursor={'pointer'}
          onClick={() => idmoState.on()}
        >
          {' '}
          Delete Hospital
        </Text>
        <Button
          borderRadius={'full'}
          bg={AppColors.primary}
          color={AppColors.white}
          fontWeight={'normal'}
          outline={`2px solid ${AppColors.primary}`}
          _hover={{
            transition: 'all .1s ease',
            bgColor: AppColors.white,
            color: AppColors.primary,
          }}
          onClick={() => {
            setMode(CREATE_MODE);
            dfvState.on();
          }}
        >
          + Add Department
        </Button>
        <Button
          borderColor={AppColors.secondary}
          borderWidth={1}
          bg={'white'}
          fontWeight={'normal'}
          color={AppColors.secondary}
          borderRadius={'full'}
          _hover={{
            transition: 'all .1s ease',
            bgColor: AppColors.secondary,
            color: AppColors.white,
            outline: `2px solid ${AppColors.secondary}`,
          }}
          onClick={() => {
            navigate(
              replaceRoute(ROUTES.GET_ACCOUNT_HOSPITAL, {
                accountId,
                hospitalId,
              }),
              { state: location.state }
            );
          }}
        >
          Go to Dashboard
        </Button>
      </HStack>
    );
  }

  function renderDepartments() {
    return (
      <RightContainer
        sectionTitle={'Departments'}
        columns={departmentColumns}
        data={departments}
        isLoading={isFetchingDepartment}
        tableProps={{
          getCellProps: _getCellProps,
          getHeaderCellProps: _getHeaderCellProps,
        }}
        renderMiddleContent={renderMiddleContent}
        onSearch={data => {
          setSearchFilters(prev => ({ ...prev, search: data }));
        }}
      />
    );
  }

  function renderTable() {
    return (
      <GridItem colSpan={2}>
        <VStack align={'stretch'}>{renderDepartments()}</VStack>
      </GridItem>
    );
  }

  function renderMainContent() {
    return (
      <Box>
        <Grid templateColumns="repeat(3, 1fr)" gap={4}>
          {renderHospitalInfo()}
          {renderTable()}
        </Grid>
      </Box>
    );
  }

  const onModalClose = () => {
    icState.off();
    idmoState.off();
  };

  const [isChecked, icState] = useBoolean(false);

  return (
    <CQContainer>
      {renderBreadCrumb()}
      {renderBackToAccounts()}
      {renderMainContent()}
      <DeleteModal
        isDeleteLoading={accountsContext.isHospitalLoading}
        onDelete={async () => {
          //delete hospital
          await accountsContext.onDeleteHospital(hospitalId);
          idmoState.off();
          //navigate to previous page
          navigate(ROUTES.ACCOUNTS);
        }}
        icState={icState}
        isChecked={isChecked}
        isDeleteModalOpen={isDeleteModalOpen}
        closeDeleteModal={onModalClose}
        name={accountsContext.hospital.name}
        condition={
          'I understand this will remove all departments and physicians related to this hospital.'
        }
      />
      <AddDepartmentForm
        mode={mode}
        isOpen={departmentFormVisible}
        onClose={dfvState.off}
        hospital={accountsContext.hospital}
        account={{
          id: accountId,
          name: location.state?.accountName,
        }}
        departmentId={departmentData?.id}
        departmentData={departmentData}
              />
    </CQContainer>
  );
};
export default withProvider(AccountsProvider, HospitalProfileViewContainer);
