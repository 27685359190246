import {
  useBoolean,
  usePagination,
  useToastr,
} from '@prasanna-transcend/code-quick-components';
import { createContext, useState } from 'react';
import myAuditRepository from '../repositories/MyAuditRepository';
import {
  ALL,
  ARCHIVED,
  NEW_UPLOAD,
  REBUTTAL,
  SELF,
  URGENT,
} from '../constants/constants';
import auditSheetRepository from '../repositories/AuditSheetRepository';

export const MyAuditContext = createContext({
  isAuditUploadListLoading: false,
  getAuditUpload: () => { },
  getAuditNewUploads: () => { },
  getAuditArchived: () => { },
  getAuditRebuttals: () => { },
  getAuditUrgent: () => { },

  auditUploadList: [],
  auditNewUploadsList: [],
  auditArchivedList: [],
  auditRebuttalsList: [],
  auditUrgentList: [],

  auditUploadListCount: 0,
  auditNewUploadsListCount: 0,
  auditArchivedListCount: 0,
  auditRebuttalsListCount: 0,
  auditUrgentListCount: 0,

  filterParams: {},
  handleApplyFilter: () => { },
  getRecentAudits: async () => { },
  isRecentAuditLoading: false,
  recentAudits: [],
  monitorAuditHour: async () => { },

});
const initialFilterParams = {
  newUploadStatus: '',
  searchText: '',
};
const MyAuditProvider = props => {
  const toast = useToastr();

  const [isAuditUploadListLoading, iaState] = useBoolean(false);
  const [isRecentAuditLoading, iraState] = useBoolean(false);
  const [recentAudits, setRecentAudits] = useState([]);

  const [auditUploadList, setAuditUploadList] = useState([]);
  const [auditNewUploadsList, setAuditNewUploadsList] = useState([]);
  const [auditArchivedList, setAuditArchivedList] = useState([]);
  const [auditRebuttalsList, setAuditRebuttalsList] = useState([]);
  const [auditUrgentList, setAuditUrgentList] = useState([]);
  const [auditUploadListCount, setAuditUploadListCount] = useState(0);
  const [auditNewUploadsListCount, setAuditNewUploadsListCount] = useState(0);
  const [auditArchivedListCount, setAuditArchivedListCount] = useState(0);
  const [auditRebuttalsListCount, setAuditRebuttalsListCount] = useState(0);
  const [auditUrgentListCount, setAuditUrgentListCount] = useState(0);

  const [filterParams, setFilterParams] = useState(
    Object.assign({}, initialFilterParams)
  );

  const getFilterParams = () => {
    const queryParams = {};
    const { searchText, ordering } = filterParams;
    if (searchText) queryParams['search'] = searchText;
    if (ordering) queryParams['ordering'] = ordering;

    return queryParams;
  };

  async function getAuditUpload(paginationParams) {
    const filterParams = getFilterParams();
    if (NEW_UPLOAD) {
      filterParams.user = SELF;
      filterParams.status = ALL;
    }
    try {
      iaState.on();
      const response = await myAuditRepository.getAuditUploads({
        ...paginationParams,
        ...filterParams,
      });
      setAuditUploadListCount(response.count);
      setAuditUploadList(response.results);
      return response;
    } catch (e) {
      toast.showError({
        description: `${e}`,
      });
    } finally {
      iaState.off();
    }
  }

  async function getAuditNewUploads(paginationParams) {
    const filterParams = getFilterParams();
    if (NEW_UPLOAD) {
      //user should not be sent
      filterParams.status = NEW_UPLOAD;
      filterParams.user = SELF;
    }
    try {
      iaState.on();
      const response = await myAuditRepository.getAuditUploads({
        ...paginationParams,
        ...filterParams,
      });
      setAuditNewUploadsListCount(response.count);
      setAuditNewUploadsList(response.results);
      return response;
    } catch (e) {
      toast.showError({
        description: `${e}`,
      });
    } finally {
      iaState.off();
    }
  }

  async function getAuditArchived(paginationParams) {
    const filterParams = getFilterParams();
    if (ARCHIVED) {
      filterParams.status = ARCHIVED;
      filterParams.user = SELF;
    }
    try {
      iaState.on();
      const response = await myAuditRepository.getAuditUploads({
        ...paginationParams,
        ...filterParams,
      });
      setAuditArchivedListCount(response.count);
      setAuditArchivedList(response.results);
      return response;
    } catch (e) {
      toast.showError({
        description: `${e}`,
      });
    } finally {
      iaState.off();
    }
  }

  async function getAuditRebuttals(paginationParams) {
    const filterParams = getFilterParams();
    if (REBUTTAL) {
      filterParams.status = REBUTTAL;
      filterParams.user = SELF;
    }
    try {
      iaState.on();
      const response = await myAuditRepository.getAuditUploads({
        ...paginationParams,
        ...filterParams,
      });
      setAuditRebuttalsListCount(response.count);
      setAuditRebuttalsList(response.results);
      return response;
    } catch (e) {
      toast.showError({
        description: `${e}`,
      });
    } finally {
      iaState.off();
    }
  }

  async function getAuditUrgent(paginationParams) {
    const filterParams = getFilterParams();
    if (REBUTTAL) {
      filterParams.urgent = true;
      filterParams.user = SELF;
      filterParams.status = URGENT;
    }
    try {
      iaState.on();
      const response = await myAuditRepository.getAuditUploads({
        ...paginationParams,
        ...filterParams,
      });
      setAuditUrgentListCount(response.count);
      setAuditUrgentList(response.results);
      return response;
    } catch (e) {
      toast.showError({
        description: `${e}`,
      });
    } finally {
      iaState.off();
    }
  }

  async function getRecentAudits() {
    try {
      iraState.on();
      const response = await myAuditRepository.getRecentAudits();
      setRecentAudits(response)
    } catch (e) {
      toast.showError({
        description: `${e}`,
      });
    } finally {
      iraState.off();
    }
  }
  async function monitorAuditHour(uploadId) {
    try {
      await auditSheetRepository.auditHourMonitor(uploadId)
    } catch (err) {
      toast.showError({
        description: `${err}`,
      });
    }
  }

  const handleApplyFilter = selectedFilters => {
    setFilterParams(selectedFilters);
  };
  const getAuditUpload1 = usePagination(getAuditUpload);
  const getAuditNewUploads1 = usePagination(getAuditNewUploads);
  const getAuditArchived1 = usePagination(getAuditArchived);
  const getAuditRebuttals1 = usePagination(getAuditRebuttals);
  const getAuditUrgent1 = usePagination(getAuditUrgent);

  const mContext = {
    isAuditUploadListLoading,
    getAuditUpload: getAuditUpload1,
    getAuditNewUploads: getAuditNewUploads1,
    getAuditArchived: getAuditArchived1,
    getAuditRebuttals: getAuditRebuttals1,
    getAuditUrgent: getAuditUrgent1,

    auditUploadList,
    auditNewUploadsList,
    auditArchivedList,
    auditRebuttalsList,
    auditUrgentList,

    auditUploadListCount,
    auditNewUploadsListCount,
    auditArchivedListCount,
    auditRebuttalsListCount,
    auditUrgentListCount,

    filterParams,
    handleApplyFilter,
    monitorAuditHour,
    getRecentAudits,
    isRecentAuditLoading,
    recentAudits
  };

  return (
    <MyAuditContext.Provider value={mContext}>
      {props.children}
    </MyAuditContext.Provider>
  );
};

export default MyAuditProvider;
