import { Box } from '@chakra-ui/react';
import React from 'react';
import CQContainer from '../../components/layout/CQContainer';
import { Heading } from '@prasanna-transcend/code-quick-components';
import UserManagementForm from './components/UserManagementForm';
import UserManagementProvider from '../../providers/UserManagementProvider';
import { withProvider } from '../../hoc/withProvider';
import { FONT_FAMILY } from '../../constants/Theme';
import { CREATE_MODE} from '../../constants/constants';

const UserManagementContainer = props => {

  return (
    <>
      <CQContainer py={30} px={30} pb={20} mx="10%">
        <Heading variant="h4" style={{ fontFamily: FONT_FAMILY.baiJamurjee }} mb={4}>
        Add New User
        </Heading>
        <Box
          bg="white"
          p={4}       
          w="100%"
          h="150%"
          borderRadius="md"
          ml={0}
        >
          <UserManagementForm mode={CREATE_MODE} />
        </Box>
      </CQContainer>
    </>
  );
};

export default withProvider(UserManagementProvider, UserManagementContainer);
