/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, HStack, Text, Tooltip } from '@chakra-ui/react';
import {
  CQReactTable,
  DotMenu,
  useBoolean,
} from '@prasanna-transcend/code-quick-components';
import React, { useContext, useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { AccountContext } from '../../../providers/AccountsProvider';
import {
  getHeaderCellProps,
  paginationProps,
  commonReactTableProps,
  defaultReactTableProp,
} from '../../../utils/account.utils';
import { DotMenuIcon } from '../../../constants/IconData';
import AppColors from '../../../constants/AppColors';
import { FONT_FAMILY } from '../../../constants/Theme';
import ROUTES from '../../../constants/Routes';
import { NavLink, useNavigate } from 'react-router-dom';
import { replaceRoute, toTitleCase } from '../../../utils/common.utils';
import { ACCOUNT_STATUS, ACTIVE, INACTIVE } from '../../../constants/constants';
import { DeleteModal } from '../components/DeleteModal';

const columnHelper = createColumnHelper();

function AccountsTable({ searchValue, setOrdering }) {
  const navigate = useNavigate();

  const accountsContext = useContext(AccountContext);

  const [accountId, setAccountId] = React.useState();
  const [accountName, setAccountName] = React.useState('');
  const [isDeleteModalOpen, idmoState] = useBoolean(false);
  const [isChecked, icState] = useBoolean(false);

  function handlePreviousPage() {
    accountsContext.getAccountsList.loadPrevious();
  }

  function handleNextPage() {
    accountsContext.getAccountsList.loadNext();
  }

  function handleSizeChange(size) {
    accountsContext.getAccountsList({ size });
  }

  function getCellProps(row, cell) {
    let fontWeight = 'normal';
    let textAlign = 'left';
    let status = row.original?.is_active ? ACTIVE : INACTIVE;
    if (cell.id.includes('name')) {
      fontWeight = 'bold';
    }

    if (
      cell.id.includes('active_audits') ||
      cell.id.includes('total_audits') ||
      cell.id.includes('acc_actions') ||
      cell.id.includes('is_active') ||
      cell.id.includes('specialty')
    ) {
      textAlign = 'center';
    }

    return {
      style: {
        fontFamily: FONT_FAMILY.ptSans,
        color: ACCOUNT_STATUS[status]?.color ?? AppColors.secondary,
        fontWeight: fontWeight,
        textAlign: textAlign,
      },
    };
  }

  const onModalClose = () => {
    icState.off();
    idmoState.off();
  };

  const renderActionComponent = row => {
    let status = row.is_active ? ACTIVE : INACTIVE;
    return (
      <HStack justifyContent={'center'} spacing={'sm'}>
        <NavLink
          state={{ accountName: row.name }}
          to={ROUTES.GET_ACCOUNT.replace(':accountId', row.id)}
        >
          <Button
            variant="solid"
            borderRadius={'full'}
            fontWeight={'bold'}
            px={12}
            backgroundColor={
              status === INACTIVE ? AppColors.darkGray : AppColors.secondary
            }
            color={'white'}
            disabled={!row.is_active}
            outline={`2px`}
            _hover={{
              transition: 'all .1s ease',
              bgColor: AppColors.white,
              color: AppColors.secondary,
              outline: `2px solid ${AppColors.secondary}`,
            }}
          >
            Manage
          </Button>
        </NavLink>
        <DotMenu
          icon={<DotMenuIcon />}
          menuDataItem={[
            {
              onClick: () =>
                navigate(
                  replaceRoute(ROUTES.EDIT_ACCOUNT, { accountId: row.id }),
                  {
                    state: { accountName: row.name },
                  }
                ),
              children: <Box color={'black'}>Edit Account</Box>,
            },
            {
              onClick: () => {
                idmoState.on();
                setAccountId(row.id);
                setAccountName(row.name);
              },
              children: <Box color={'red'}>Delete Account</Box>,
            },
          ]}
        />
      </HStack>
    );
  };

  const renderSpecialtyValue = specialty => {
    // eslint-disable-next-line array-callback-return
    const specialtyLabel = specialty.slice(0, 2).map(s => {
      if (s.name === 'ent') {
        return 'ENT';
      }
      if (s.name !== null || s.id !== null) {
        return toTitleCase(s.name);
      }
    });
    // eslint-disable-next-line array-callback-return
    const showSpecialities = specialty?.slice(2, specialty.length).map(s => {
      if (s.name === 'ent') {
        return 'ENT';
      } else if (s.name !== null || s.id !== null) {
        return s.name;
      }
    });
    return (
      <>
        <HStack>
          <Text fontSize={'16px'}>{specialtyLabel.join(', ')}</Text>

          {specialty.length > 2 ? (
            <>
              <Text>+</Text>
              <Tooltip
                hasArrow
                label={showSpecialities.join(' | ')}
                bg={AppColors.secondary}
                border={'none'}
              >
                <Text
                  bg={AppColors.secondary}
                  color={'#fff'}
                  px={2}
                  cursor={'pointer'}
                  py={1}
                  borderRadius={'full'}
                >
                  {specialty.length - 2}
                </Text>
              </Tooltip>
            </>
          ) : (
            ''
          )}
        </HStack>
      </>
    );
    //
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: () => 'Name',
        cell: info => {
          let name = info.row.original?.name;
          if (!name) {
            return 'N/A';
          }
          return <Text fontSize={'16px'}>{name}</Text>;
        },
      }),
      columnHelper.accessor('specialty', {
        header: () => 'Specialty',
        enableSorting: false,
        cell: info => {
          let specialty = info.row.original?.specialty;
          if (specialty.length === 0) {
            return (
              <span style={{ display: 'flex', justifyContent: 'left' }}>
                N/A
              </span>
            );
          }
          return renderSpecialtyValue(specialty);
        },
      }),
      columnHelper.accessor('active_audits', {
        header: () => 'Active Audits',
        enableSorting: false,
        cell: info => {
          let activeAudits = info.row.original?.active_audits;
          if (!activeAudits) {
            return 'N/A';
          }
          return <Text fontSize={'16px'}>{activeAudits}</Text>;
        },
      }),
      columnHelper.accessor('total_audits', {
        header: () => 'Total Audits',
        enableSorting: false,
        cell: info => {
          let totalAudits = info.row.original?.total_audits;
          if (!totalAudits) {
            return 'N/A';
          }
          return <Text fontSize={'16px'}>{totalAudits}</Text>;
        },
      }),
      columnHelper.accessor('is_active', {
        header: () => 'Status',
        cell: info => {
          let status = info.row.original?.is_active ? ACTIVE : INACTIVE;
          if (!status) {
            return 'N/A';
          }
          return (
            <Text fontSize={'16px'}>
              {ACCOUNT_STATUS[status]?.label ?? 'N/A'}
            </Text>
          );
        },
      }),
      columnHelper.accessor('acc_actions', {
        header: () => 'Actions',
        enableSorting: false,
        cell: info => {
          return renderActionComponent(info.row.original);
        },
      }),
    ],
    [accountsContext.isLoadingAccountsList]
  );

  return (
    <>
      <CQReactTable
        isLoading={accountsContext.isLoadingAccountsList}
        data={accountsContext.accountsList}
        columns={columns}
        {...commonReactTableProps}
        {...defaultReactTableProp}
        getHeaderCellProps={getHeaderCellProps}
        getCellProps={getCellProps}
        {...paginationProps(accountsContext.getAccountsList)}
        onPreviousPage={handlePreviousPage}
        onNextPage={handleNextPage}
        onSizeChange={handleSizeChange}
        onAsc={header => {
          setOrdering(header);
        }}
        onDesc={header => {
          setOrdering(`-${header}`);
        }}
      />
      <DeleteModal
        isDeleteLoading={accountsContext.isDepartmentLoading}
        onDelete={async () => {
          await accountsContext.deleteAccount(accountId);
          idmoState.off();
          //navigate to previous page
          navigate(ROUTES.ACCOUNTS);
        }}
        isChecked={isChecked}
        icState={icState}
        isDeleteModalOpen={isDeleteModalOpen}
        closeDeleteModal={() => onModalClose()}
        name={accountName}
        condition={
          'I understand this will remove all hospitals, departments, and physicians related to this Health System.'
        }
      />
    </>
  );
}

export default AccountsTable;
