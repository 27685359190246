import {
  Box,
  Button,
  Checkbox,
  Circle,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import AppColors from '../../../constants/AppColors';
import { FONT_FAMILY } from '../../../constants/Theme';

const DeleteModal = ({
  isDeleteLoading,
  onDelete,
  isDeleteModalOpen,
  closeDeleteModal,
  name,
  isChecked,
  icState,
  condition,
}) => {
  return (
    <>
      <Modal
        isOpen={isDeleteModalOpen}
        size={'lg'}
        onClose={() => closeDeleteModal()}
        isCentered
      >
        <ModalOverlay style={{ backgroundColor: '#001A41' }} />
        <ModalContent p={10} style={{ alignItems: 'center' }}>
          <ModalHeader>
            <Text
              textAlign={'center'}
              fontFamily={FONT_FAMILY.baiJamurjee}
              fontWeight="medium"
              fontSize={'lg'}
              color={AppColors.black}
            >
              Are you sure you want to delete
              <Text fontWeight={'bold'}>"{name}" ?</Text>
            </Text>
            <Checkbox
              size="md"
              colorScheme={'secondary'}
              onChange={e => icState.toggle()}
              checked={isChecked}
            >
              <Text
                mt={5}
                fontSize={'sm'}
                fontWeight={'extrabold'}
                textAlign={'center'}
              >
                {condition}
              </Text>
            </Checkbox>
          </ModalHeader>
          <Box
            style={{
              zIndex: '1',
            }}
            position={'absolute'}
            top={-14}
            right={-10}
          >
            <Circle>
              <ModalCloseButton
                size={'md'}
                style={{ color: AppColors.white }}
                backgroundColor={'#8894A6'}
                p={3}
                onClick={closeDeleteModal}
                borderRadius={50}
              />
            </Circle>
          </Box>
          <ModalBody mb={2}>
            <HStack>
              <Button
                mr={1}
                color={'black'}
                borderColor={AppColors.secondary}
                bg={'white'}
                borderWidth={1}
                fontSize="sm"
                borderRadius="full"
                height={'50px'}
                onClick={() => {
                  closeDeleteModal();
                }}
                _hover={{
                  bg: AppColors.white,
                }}
                px={14}
              >
                Cancel
              </Button>
              <Button
                borderRadius="full"
                color="white"
                fontSize="sm"
                height={'50px'}
                bg={AppColors.red}
                px={14}
                isLoading={isDeleteLoading}
                disabled={!isChecked}
                _hover={{
                  bg: AppColors.red,
                }}
                onClick={() => {
                  onDelete();
                  icState.off();
                }}
              >
                Delete
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
export { DeleteModal };
