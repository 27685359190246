/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useMemo } from 'react';
import { Avatar, Box, HStack, Text } from '@chakra-ui/react';
import { CQReactTable } from '@prasanna-transcend/code-quick-components';
import { RebuttalIcon } from '../../../../constants/IconData';
import { AuditContext } from '../../../../providers/AuditProvider';
import '../style.css';
import SpecialtyRow from '../../../../components/audit_table/SpecialityRow';
import { createColumnHelper } from '@tanstack/react-table';
import { toTitleCase } from '../../../../utils/common.utils';
import {
  ASSIGNED_AUDITS_ORDERING,
  ENABLE_EDIT_STATUS,
  getAuditStatus,
} from '../../../../constants/constants';
import useCQMediaQuery from '../../../../hooks/useCQMediaQuery';
import {
  defaultReactTableProp,
  paginationProps,
} from '../../../../utils/assigned_audit.util';
import { format, formatDistanceStrict } from 'date-fns';
import AssignAuditorCell from '../../../../components/audit_table/AssignAuditorCell';
import FileViewAction from '../FileViewAction';
import AppColors from '../../../../constants/AppColors';

const columnHelper = createColumnHelper();

const RebuttalUploadTable = () => {
  const auditContext = useContext(AuditContext);

  // media query
  const canScroll = useCQMediaQuery({
    lg: false,
    md: false,
    sm: true,
  });

  const { isUpdating, getAuditRebuttals, updateSubmittedChart, filterParams } =
    auditContext;

  const [ordering, setOrdering] = useState('');

  useEffect(() => {
    getAuditRebuttals.loadAPI();
  }, [filterParams, getAuditRebuttals.showOrdering]);

  useEffect(() => {
    if (ASSIGNED_AUDITS_ORDERING.includes(ordering)) {
      getAuditRebuttals.setParams({ ordering });
    }
  }, [ordering]);

  async function assignAuditor(auditorId, rowId) {
    return auditContext.assignAuditor(auditorId, rowId);
  }

  async function updateSpecialty(specialtyId, rowId) {
    return updateSubmittedChart(rowId, {
      assigned_specialties: specialtyId,
      assigned_auditor: null,
    });
  }

  const renderActionComponent = auditUpload => {
    return (
      <HStack>
        <FileViewAction auditUpload={auditUpload} />
      </HStack>
    );
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: '',
        enableSorting: false,
        cell: info => {
          return <RebuttalIcon style={{ height: 20, width: 20 }} />;
        },
      }),
      columnHelper.accessor('upload_id', {
        header: () => 'Upload #',
        id: 'chart_id',
        cell: info => {
          return (
            <HStack
              textAlign={'left'}
              justifyContent={'flex-start'}
              alignItems={'center'}
              spacing={3}
            >
              <Text pl={3}>{info.renderValue()}</Text>
            </HStack>
          );
        },
      }),
      columnHelper.accessor(row => row.uploaded_date, {
        id: 'upload_date',
        header: () => <span>Chart Upload</span>,
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return 'N/A';
          }

          return format(new Date(cellValue), 'MM/dd/yyyy');
        },
      }),
      columnHelper.accessor(p => p.client_name?.first_name, {
        header: () => 'Uploaded By',
        id: 'client',
        cell: info => {
          const name = toTitleCase(
            `${info.row.original.client_name?.first_name} ${info.row.original.client_name?.last_name}`
          );
          return <Text>{name}</Text>;
        },
      }),
      columnHelper.accessor('specialties', {
        header: () => 'Specialties',
        id: 'specialty__name',
        enableSorting: false,
        cell: info =>
          !info.row?.original?.specialties?.name ||
            !info.row.original?.assigned_auditor ||
            ENABLE_EDIT_STATUS.includes(info.row?.original.status) ? (
            <SpecialtyRow
              selectedValue={info.row.original?.specialties?.id}
              specialties={
                info.row.original.client_name.specialties?.length
                  ? info.row.original.client_name.specialties.map(d => ({
                    label: d.name,
                    value: d.id,
                  }))
                  : []
              }
              onChange={specialtyId =>
                updateSpecialty(specialtyId, info.row.original.id)
              }
            />
          ) : (
            toTitleCase(info.row?.original?.specialties?.name) ?? 'N/A'
          ),
      }),
      columnHelper.accessor('total_page', {
        header: () => 'Pages',
        id: 'total_pages',
        enableSorting: false,
        cell: info => {
          return info.row.original?.total_page;
        },
      }),
      columnHelper.accessor('status', {
        header: () => 'Status',
        id: 'status',
        cell: info => <Text>{getAuditStatus(info.row.original?.status)}</Text>,
      }),
      columnHelper.accessor('updated_date', {
        header: () => 'Last Updated',
        id: 'updated_date',
        cell: info => {
          let updatedAt = info.getValue();

          updatedAt = updatedAt
            ? formatDistanceStrict(new Date(updatedAt), new Date(), {
              addSuffix: true,
            })
            : 'N/A';

          return (
            <Text>
              {updatedAt !== 'N/A' ? toTitleCase(updatedAt) : updatedAt}
            </Text>
          );
        },
      }),
      columnHelper.accessor('assigned_auditor', {
        header: () => 'Assigned to',
        id: 'auditor',
        enableSorting: false,
        cell: info => {
          const assignedAuditor = info.row.original?.assigned_auditor;

          let selectedValue = null;
          if (assignedAuditor) {
            selectedValue = {
              label: toTitleCase(
                `${assignedAuditor.first_name} ${assignedAuditor.last_name}`
              ),
              value: assignedAuditor.id,
            };
          }

          return !info.row?.original?.specialties?.name ||
            !selectedValue ||
            ENABLE_EDIT_STATUS.includes(info.row?.original.status) ? (
            <Box w={200}>
              <AssignAuditorCell
                auditUpload={info.row.original}
                selectedValue={selectedValue}
                onChange={e => {
                  assignAuditor(e, info.row.original.id);
                }}
                selectedSpecialtyId={info.row.original.specialties?.id}
              />
            </Box>
          ) : (
            <HStack>
              <Avatar
                name={selectedValue?.label}
                bg={AppColors.secondary}
                size="sm"
                color="white"
                mr={2}
              />
              <Text>{selectedValue?.label ?? 'N/A'}</Text>
            </HStack>
          );
        },
      }),
      columnHelper.accessor('actions', {
        header: '',
        enableSorting: false,
        cell: info => {
          return renderActionComponent(info.row.original);
        },
      }),
    ],
    [auditContext.isFetchingRebuttals, auditContext.auditRebuttalsList]
  );

  function handlePreviousPage() {
    auditContext.getAuditRebuttals.loadPrevious();
  }

  function handleNextPage() {
    auditContext.getAuditRebuttals.loadNext();
  }

  function handleSizeChange(size) {
    auditContext.getAuditRebuttals({ size });
  }

  return (
    <Box
      mt={3}
      pointerEvents={isUpdating ? 'none' : 'inherit'}
      overflowX={canScroll ? 'scroll' : 'inherit'}
    >
      <CQReactTable
        manualSortBy={true}
        isLoading={auditContext.isFetchingRebuttals}
        data={auditContext.auditRebuttalsList}
        columns={columns}
        {...defaultReactTableProp}
        {...paginationProps(getAuditRebuttals)}
        onPreviousPage={handlePreviousPage}
        onNextPage={handleNextPage}
        onSizeChange={handleSizeChange}
        onAsc={header => {
          setOrdering(header);
        }}
        onDesc={header => {
          setOrdering(`-${header}`);
        }}
      />
    </Box>
  );
};
export default RebuttalUploadTable;
