/* eslint-disable react-hooks/exhaustive-deps */
import {
  Divider,
  Skeleton,
  Stack,
  Text,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import { TextInput } from '@prasanna-transcend/code-quick-components';
import React, { useEffect, useState } from 'react';
import AppColors from '../../../constants/AppColors';
import { SearchIcon } from '../../../constants/IconData';
import accountsRepository from '../../../repositories/AccountsRepository';

const scrollStyle = {
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 4px lightGrey',
    width: '4px',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'grey',
    borderRadius: '4px',
  },
};

const MembersSection = props => {
  const { queryParam = {} } = props;

  const [isFetching, ifState] = useBoolean(false);

  const [members, setMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  async function fetchMembers() {
    try {
      ifState.on();
      const response = await accountsRepository.getAccountMembers(
        props.accountId,
        {
          search: searchTerm,
          ...queryParam,
        }
      );
      setMembers(response?.results ?? []);
    } catch (e) {
      // TODO: Log Exception
    } finally {
      ifState.off();
    }
  }

  useEffect(() => {
    fetchMembers();
  }, [searchTerm]);

  function renderMemberItem(member, index) {
    const name = member?.first_name + member?.last_name;
    return (
      <React.Fragment
        key={JSON.stringify(queryParam) + '-members-' + member?.id}
      >
        {/* <ScaleFade  initialScale={0.1} in={true}> */}
          <Stack p={3} w={'100%'} >
            <Text
              fontSize={'lg'}
              fontWeight={'bold'}
              color={AppColors.secondary}
            >
              {name}
            </Text>
            <Text color={AppColors.primary}>{member?.email}</Text>
            <Divider w={'100%'} my={6} />
          </Stack>
        {/* </ScaleFade> */}
      </React.Fragment>
    );
  }

  function renderLoader() {
    return (
      <Skeleton>
        <Skeleton height={'20px'} mt={2} />
        <Skeleton height={'20px'} mt={2} />
        <Skeleton height={'20px'} mt={2} />
      </Skeleton>
    );
  }

  function handleSearch(e) {
    if (e.keyCode === 13) {
      setSearchTerm(e.target.value);
    }
  }

  function renderMembers() {
    if (isFetching) {
      return renderLoader();
    }

    return (
      <VStack
        maxH={500}
        overflowX={'hidden'}
        sx={scrollStyle}
        overflowY={'scroll'}
        my={4}
        width={'100%'}
        alignItems={'flex-start'}
      >
        {members.map(renderMemberItem)}
      </VStack>
    );
  }

  function renderContent() {
    return (
      <React.Fragment>
        <Text fontWeight={'bold'} fontSize={'lg'}>
          Members
        </Text>
        <Divider mt={4} mb={2} />

        <TextInput
          wrapperProps={{ bg: 'transparent' }}
          bg={'white'}
          style={{height:'50px'}}
          rightIconProps={{ zIndex: 0 }}
          placeholder="Search"
          rightIcon={<SearchIcon style={{marginTop:'10px'}} />}
          onKeyDown={handleSearch}

        />
       <Stack width={'100%'} >{ renderMembers()}</Stack>
      </React.Fragment>
    );
  }
  return renderContent();
};

export default MembersSection;
