import { Box, HStack, Text } from '@chakra-ui/react';
import { CQReactTable, TextInput } from '@prasanna-transcend/code-quick-components';
import React from 'react';
import { SearchIcon } from '../../../constants/IconData';
import { defaultReactTableProp } from '../../../utils/assigned_audit.util';

const SubCollectionSection = props => {
  const { columns, tableProps, data = [], isLoading, } = props
  const { sectionTitle, onSearch } = props

  // boolean

  function handleSearch(e) {
    if(onSearch && e.keyCode === 13) {
      onSearch(e.target.value);
    }
  }

  return (
    <React.Fragment>
      <HStack justifyContent={'space-between'}>
        <Box flex={2}>
          <Text textTransform={'uppercase'} fontSize={'lg'} fontWeight={'bold'}>{sectionTitle}</Text>
        </Box>

        <Box flex={1}>
          <TextInput
            wrapperProps={{ bg: 'transparent' }}
            style={{height:'50px'}}
            bg={'white'}
            rightIconProps={{ zIndex: 0 }}
            placeholder="Search"
            rightIcon={<SearchIcon  style={{marginTop:'10px'}} />}
            onKeyDown={handleSearch}
          />
        </Box>
      </HStack>

      <Box my={4} p={4} bgColor={"#fff"} borderRadius={'lg'} shadow={'lg'}>
        <CQReactTable
          {...defaultReactTableProp}
          {...tableProps}
          isLoading={isLoading}
          columns={columns}
          data={data}
        />
      </Box>
    </React.Fragment>
  );
};

export default React.memo(SubCollectionSection);
