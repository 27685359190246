import { API } from '../config/AppConfig';
import {
  AUDIT_UPLOADS,
  GET_SPECIALTIES,
  GET_ALL_USER_API,
  DELETE_UPLOADED_FILE,
  UPDATE_SUBMITTED_CHART,
  GET_SPECIALTIES_FOR_AUDITORS,
  SPLIT_AUDIT,
} from '../constants/Endpoints';
import { ALL, ARCHIVED, NEW_UPLOAD, REBUTTAL } from '../constants/Filter';

class AuditRepository {
  constructor() {
    this.specialtyBasedAuditors = {};
  }

  async getAuditUploads(filterParams) {
    const response = await API.get(AUDIT_UPLOADS, {
      queryParams: filterParams,
    });
    return response;
  }

  async getAllAuditUploads(filterParams = {}) {
    filterParams = {
      ...filterParams,
      // status: ALL
    };

    const response = await API.get(AUDIT_UPLOADS, {
      queryParams: filterParams,
    });
    return response;
  }

  async getNewUploads(filterParams = {}) {
    filterParams = {
      ...filterParams,
      status: NEW_UPLOAD,
    };

    const response = await API.get(AUDIT_UPLOADS, {
      queryParams: filterParams,
    });
    return response;
  }

  async getArchivedUploads(filterParams = {}) {
    filterParams = {
      ...filterParams,
      status: ARCHIVED,
    };

    const response = await API.get(AUDIT_UPLOADS, {
      queryParams: filterParams,
    });
    return response;
  }

  async getRebuttalUploads(filterParams = {}) {
    filterParams = {
      ...filterParams,
      status: REBUTTAL,
    };

    const response = await API.get(AUDIT_UPLOADS, {
      queryParams: filterParams,
    });
    return response;
  }

  async getSpecialties() {
    const response = await API.get(GET_SPECIALTIES);
    return response;
  }

  async getUsers() {
    const response = await API.get(GET_ALL_USER_API);
    return response;
  }
  async updateSubmittedChart(id, params) {
    const response = await API.put(UPDATE_SUBMITTED_CHART, {
      formData: params,
      pathParams: { id },
    });
    return response;
  }

  async updateChart(id, data) {
    const response = await API.put(UPDATE_SUBMITTED_CHART, {
      ...data,
      pathParams: { id },
    });
    return response;
  }

  async deleteUploadedFile(uploadID) {
    const response = await API.delete(DELETE_UPLOADED_FILE, {
      pathParams: { uploadID },
    });
    return response;
  }

  async getAuditorList() {
    const specialties = await API.get(GET_SPECIALTIES_FOR_AUDITORS);
    for (let i = 0; i < specialties.length; i++) {
      const specialty = specialties[i];

      // specialty id -> auditor.id
      this.specialtyBasedAuditors[specialty.id] = specialty.auditors;
    }

    return specialties;
  }

  async getAuditors(specialtyId) {
    return this.specialtyBasedAuditors[specialtyId] ?? [];
  }

  async createSplitAudit(uploadId, data) {
    const response = await API.post(SPLIT_AUDIT, {
      pathParams: { uploadId },
      body: data,
    });
    return response;
  }
}

const auditRepository = new AuditRepository();
export default auditRepository;
