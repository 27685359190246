// export const BASE_URL = process.env.REACT_APP_BASE_URL ?? "http://localhost:5839";
const BASE_URL = process.env.REACT_APP_BASE_URL;
export const MANAGER_LOGIN = BASE_URL + '/login';
export const FORGOT_PASSWORD = BASE_URL + '/forgot-password';

export const USER_LOOKUP = BASE_URL + '/users/me';

export const GET_USER_API = BASE_URL + '/users/:userId/';
export const USER_CREATE_API = BASE_URL + '/users/';
export const USER_UPDATE_API = BASE_URL + '/users/:userId/';
export const DELETE_USER = BASE_URL + '/users/:userId';

export const AUDIT_UPLOADS = BASE_URL + '/manager/charts';
export const RECENT_AUDITS = BASE_URL + '/auditsheet/audithours/recent_audits';

export const UPDATE_SUBMITTED_CHART = BASE_URL + '/manager/charts/:id/';

export const GET_SPECIALTIES = BASE_URL + '/manager/specialties';

export const GET_ALL_USER_API = BASE_URL + '/users';

export const GET_SPECIALTIES_FOR_AUDITORS =
  BASE_URL + '/manager/specialties/auditors/';

//chart upload
export const UPLOAD_CHART = BASE_URL + '/manager/charts/files/';
export const SUBMIT_UPLOADED_CHART = BASE_URL + '/manager/charts/';
export const DELETE_CHART = BASE_URL + '/manager/charts/files/:fileId';
export const LOGOUT = BASE_URL + '/logout';
export const GET_CLIENT_USER = BASE_URL + '/manager/charts/clients';
export const DELETE_UPLOADED_FILE = BASE_URL + '/manager/charts/:uploadID';

//TEAMS
export const TEAMS = BASE_URL + '/manager/teams/';
export const MANAGE_TEAMS = BASE_URL + '/manager/teams/:teamId/';
export const MEMBER_LIST = BASE_URL + '/manager/teams/members_list';
export const TEAM_CHART_ACCURACY =
  BASE_URL + '/auditsheet/metric/chart-accuracy/';
export const TEAM_AVERAGE_AUDIT_HOURS =
  BASE_URL + '/auditsheet/audithours/average/';

//SPLIT AUDIT
export const SPLIT_AUDIT = BASE_URL + '/manager/split-audit/:uploadId/';
export const GET_AUDITOR = BASE_URL + '/manager/specialties/auditors/';
export const CHART_ACCURACY = BASE_URL + '/auditsheet/metric/chart-accuracy/';
export const AVERAGE_AUDIT_HOURS = BASE_URL + '/auditsheet/audithours/average/';

//AUDIT SHEET
export const AUDIT_SHEET = BASE_URL + '/auditsheet/:uploadId/';
export const MONITOR_HOUR = BASE_URL + '/auditsheet/audithours/:uploadId/';
export const AUDIT_UPLOAD = BASE_URL + '/manager/charts/:uploadId/';
export const AUDIT_SHEET_INDUSTRY = BASE_URL + '/auditsheet/industry/';
export const UPDATE_ACTIVITY =
  BASE_URL + '/auditsheet/comment/updation_activity/';
export const AUDITSHEET_USER = BASE_URL + '/auditsheet/cqusers/';
export const DELETE_TEAMS_USER = BASE_URL + '/users/:userId/';
export const GET_COMMENT = BASE_URL + '/auditsheet/comment/:uploadId';
export const MANAGE_COMMENT = BASE_URL + '/auditsheet/comment/:commentId/';
export const POST_COMMENT = BASE_URL + '/auditsheet/comment/';
export const GET_COMMENT_FLAG =
  BASE_URL + '/auditsheet/comment/:uploadId/cell_flag/';
export const AUDIT_SHEET_PROVIDER_DROPDOWN =
  BASE_URL + '/client/dashboard/:chartId/provider_dropdown/';
// users/2/

// accounts
export const ACCOUNTS_LIST = BASE_URL + '/manager/accounts/healthsystem/';
export const DELETE_ACCOUNT =
  BASE_URL + '/manager/accounts/healthsystem/:healthsystemId/';

// department account
export const CREATE_DEPARTMENT =
  BASE_URL + '/manager/accounts/department/:hospitalId/';
export const DEPARTMENT =
  BASE_URL + '/manager/accounts/department/:departmentId/';
export const ALL_DEPARTMENT = BASE_URL + '/manager/accounts/department/';
export const GET_DEPARTMENT_DROPDOWN =
  BASE_URL + '/manager/accounts/department/:hospitalId/department_dropdown/';

//hospital
export const HOSPITAL = BASE_URL + '/manager/accounts/hospital/:hospitalId/';
export const CREATE_HOSPITAL =
  BASE_URL + '/manager/accounts/hospital/:health_system/';

//create accounts
export const EHR_LIST = BASE_URL + '/manager/ehr/';
export const INSURANCE_LIST = BASE_URL + '/manager/insurance/';

//hospitals
export const HOSPITAL_LIST = BASE_URL + '/manager/accounts/hospital/';
export const HEALTH_SYSTEM_INFO =
  BASE_URL + '/manager/accounts/healthsystem/:healthSystemId/';

//REPORT
export const WEEK_WISE_ERROR =
  // BASE_URL + '/auditsheet/report/week-wise-error-trend/';
  BASE_URL + '/auditsheet/report/week-wise-error/';

export const WEEK_WISE_ERROR_TREND =
  BASE_URL + '/auditsheet/report/week-wise-error-trend/';

export const WEEKDAYS_ACCURACY_CHART =
  BASE_URL + '/auditsheet/report/weekdays-accuracy/';
export const WEEK_WISE_CQ_ACCURACY_ERROR =
  BASE_URL + '/auditsheet/report/cq-accuracy-error/';
export const ERROR_DROPDOWN =
  BASE_URL + '/auditsheet/report/error-parameter-dropdown/';

export const AUDITOR_QUALITY_DROPDOWN =
  BASE_URL + '/auditsheet/report/auditor-dropdown/';
export const AUDITOR_QUALITY_REPORT =
  BASE_URL + '/auditsheet/report/auditor-quality/';

export const GET_ACCOUNT =
  BASE_URL + '/manager/accounts/healthsystem/:accountId/';
export const GET_ACCOUNT_MEMBERS =
  BASE_URL + '/manager/accounts/healthsystem/:accountId/clients/';
export const GET_ACCOUNT_HOSPITALS =
  BASE_URL + '/manager/accounts/healthsystem/:accountId/hospitals/';
export const GET_ACCOUNT_TEAM_STATISTICS =
  BASE_URL + '/manager/accounts/healthsystem/team-statistics/';
export const GET_ACCOUNT_DEPARTMENTS =
  BASE_URL + '/manager/accounts/healthsystem/:accountId/department/';

export const GET_ACCOUNT_AVERAGE_AUDITHOURS =
  BASE_URL + '/auditsheet/audithours/average/';

export const METRIC_CHART_ACCURACY =
  BASE_URL + '/auditsheet/metric/chart-accuracy/';

export const GET_HEALTH_SYSTEM = BASE_URL + '/auditsheet/health-system/';
export const GET_HOSPITAL = BASE_URL + '/auditsheet/health-system/hospitals/';
export const GET_SPECIALTY =
  BASE_URL + '/auditsheet/health-system/specialties/';

export const GET_ACCOUNT_PROVIDERS =
  BASE_URL + '/manager/accounts/healthsystem/:accountId/providers/';

export const GET_HOSPITAL_DATA =
  BASE_URL + '/manager/accounts/hospital/:hospitalID/';
export const GET_PROVIDER_LIST = BASE_URL + '/manager/accounts/provider/';
export const CREATE_PROVIDER_WITH_ID =
  BASE_URL + '/manager/accounts/provider/:departmentID/';
export const CREATE_PROVIDER =
  BASE_URL + '/manager/accounts/provider/:departmentID/';
export const GET_PROVIDER_BY_DEPARTMENT =
  BASE_URL + '/manager/accounts/department/:departmentID/providers';
export const GET_PROVIDER =
  BASE_URL + '/manager/accounts/provider/:providerId/';
export const UPDATE_PROVIDER =
  BASE_URL + '/manager/accounts/provider/:providerId/';
export const DELETE_PROVIDER =
  BASE_URL + '/manager/accounts/provider/:providerId/';

export const CREATE_HEALTH_SYSTEM =
  BASE_URL + '/manager/accounts/healthsystem/';

// notifications
export const GET_NOTIFICATIONS = BASE_URL + '/notifications/';

export const CHANGE_NOTIFICATION_STATUS =
  BASE_URL + '/notifications/:notificationId/';

// validations
export const HEALTH_SYSTEM_VALIDATION =
  BASE_URL + '/manager/accounts/healthsystem/validation';

// all users mail id

export const CQ_USERS_MAIL_ID = BASE_URL + '/users/all';

export const CQ_HOSPITAL_DATA =
  BASE_URL + '/manager/accounts/hospital/validation';

export const CQ_DEPARTMENTS_DATA =
  BASE_URL + '/manager/accounts/department/validation';

export const GET_FAQ = BASE_URL + '/faq/';
