/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Grid,
  GridItem,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, NavLink, useLocation, useParams } from 'react-router-dom';
import CQContainer from '../../components/layout/CQContainer';
import AppColors from '../../constants/AppColors';
import { BackArrowIcon } from '../../constants/IconData';
import ROUTES from '../../constants/Routes';
import { FONT_FAMILY } from '../../constants/Theme';
import { withProvider } from '../../hoc/withProvider';
import AccountsViewProvider, {
  AccountsViewContext,
} from '../../providers/AccountsViewProvider';
import { getHeaderCellProps } from '../../utils/assigned_audit.util';
import { replaceRoute, toCheckTitleCase } from '../../utils/common.utils';
import AccountsChartSection from './sections/AccountsChartSection';
import AuditAccuracySection from './sections/AuditAccuracySection';
import MembersSection from './sections/MembersSection';
import MetricSection from './sections/MetricSection';
import StatisticsSection from './sections/StatisticsSection';
import SubCollectionSection from './sections/SubCollectionSection';

const columnHelper = createColumnHelper();

const AccountsViewContainer = () => {
  const params = useParams();
  const location = useLocation();

  // context
  const accountsViewContext = useContext(AccountsViewContext);

  const queryParam = useMemo(
    () => ({ health_system_id: params.accountId }),
    []
  );

  // state
  const [search, setSearch] = useState('');

  // columns
  const statisticRows = [
    {
      label: 'Total Audits',
      accessor: 'total_audits',
    },
    {
      label: 'In Progress',
      accessor: 'in_progress',
    },
    {
      label: 'Completed',
      accessor: 'completed',
    },
    {
      label: 'Open Rebuttals',
      accessor: 'open_rebuttals',
    },
  ];

  // hospital_columns
  const hospitalColumns = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        header: () => 'Hospitals',
      }),
      columnHelper.accessor('active_audits', {
        header: () => 'Active Audits',
      }),
      columnHelper.accessor('chart_accuracy', {
        header: () => 'Chart Accuracy',
        cell: info => {
          if (info.row.original.chart_accuracy) {
            return info.row.original.chart_accuracy;
          } else {
            return 'N/A';
          }
        },
      }),
      columnHelper.accessor('cq_rev_opp', {
        header: () => 'RI Rev. Opp.',
      }),
      columnHelper.accessor('actions', {
        header: () => 'Actions',
        enableSorting: false,
        cell: info => {
          const hospitalId = info.row.original.id ?? 1;
          const accountId = params.accountId;

          const navigationRoute = replaceRoute(ROUTES.GET_ACCOUNT_HOSPITAL, {
            hospitalId,
            accountId,
          });

          return (
            <Link
              to={navigationRoute}
              state={{
                accountName: location.state?.accountName,
                hospitalName: info.row.original?.name,
              }}
            >
              <Button
                size={'md'}
                px={16}
                outline={`2px`}
                _hover={{
                  transition: 'all .3s ease',
                  bgColor: AppColors.white,
                  color: AppColors.secondary,
                  outline: `2px solid ${AppColors.secondary}`,
                }}
                borderRadius={'full'}
                colorScheme={'secondary'}
              >
                View
              </Button>
            </Link>
          );
        },
      }),
    ];
  }, []);

  function fetchData() {
    accountsViewContext.fetchAccountHospitals(params.accountId, { search });
  }

  useEffect(() => {
    if (params?.accountId) {
      fetchData();
    }
  }, [params?.accountId, search]);

  function getHospitalHeaderProps(header) {
    return {
      ...getHeaderCellProps(header),
      justifyContent: header.id === 'name' ? 'flex-start' : 'center',
    };
  }

  function getHospitalCellProps(row, cell) {
    const textAlign = cell.column.id === 'name' ? 'left' : 'center';
    return {
      fontSize: 'md',
      textAlign,
      style: {
        fontWeight: 'bold',
        color: AppColors.secondary,
      },
    };
  }

  function renderBreadCrumb() {
    return (
      <Breadcrumb>
        <BreadcrumbItem>
          <NavLink to={ROUTES.ACCOUNTS}>
            <Text
              fontFamily={FONT_FAMILY.baiJamurjee}
              fontWeight="bold"
              fontSize={'2xl'}
            >
              Accounts
            </Text>
          </NavLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink href="#">
            {' '}
            {toCheckTitleCase(location.state?.accountName)}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
    );
  }

  function renderBackToAccounts() {
    return (
      // Without HStack, this NavLink is occupying entire width
      <HStack>
        <NavLink to={ROUTES.ACCOUNTS}>
          <Box>
            <HStack my={2} spacing={'md'}>
              <BackArrowIcon
                style={{ width: 40, height: 40, cursor: 'pointer' }}
              />
              <Text
                fontSize={'md'}
                fontWeight="bold"
                size={'md'}
                mx={2}
                fontFamily={FONT_FAMILY.openSans}
                color={AppColors.secondary}
              >
                Back to All Accounts
              </Text>
            </HStack>
          </Box>
        </NavLink>
      </HStack>
    );
  }

  function renderTeamStatistics() {
    return (
      <GridItem bgColor={'#fff'} p={8} borderRadius={'lg'} shadow={'md'}>
        <StatisticsSection
          rows={statisticRows}
          sectionTitle={'Account Statistics'}
          queryParam={queryParam}
        />
      </GridItem>
    );
  }

  function renderAuditAccuracy() {
    return (
      <GridItem bgColor={'#fff'} p={8} borderRadius={'lg'} shadow={'md'}>
        <AuditAccuracySection queryParam={queryParam} />
      </GridItem>
    );
  }

  function renderMetric() {
    return (
      <GridItem bgColor={'#fff'} p={8} borderRadius={'lg'} shadow={'md'}>
        <MetricSection queryParam={queryParam} />
      </GridItem>
    );
  }

  function renderMembers() {
    return (
      <GridItem bgColor={'#fff'} p={8} borderRadius={'lg'} shadow={'md'}>
        <MembersSection accountId={params?.accountId} queryParam={{}} /> 
      </GridItem>
    );
  }

  function renderHospitals() {
    return (
      <SubCollectionSection
        sectionTitle={'Hospitals'}
        columns={hospitalColumns}
        search={search}
        onSearch={setSearch}
        data={accountsViewContext.accountHospitals}
        isLoading={accountsViewContext.isFetchingHospitals}
        tableProps={{
          getCellProps: getHospitalCellProps,
          getHeaderCellProps: getHospitalHeaderProps,
        }}
      />
    );
  }

  function renderTable() {
    return (
      <GridItem colSpan={2}>
        <VStack align={'stretch'}>
          {renderHospitals()}
          <Box my={4} />
          <AccountsChartSection queryParam={queryParam} />
        </VStack>
      </GridItem>
    );
  }

  function renderMainContent() {
    return (
      <Box>
        <Grid templateColumns="repeat(3, 1fr)" mt={4} gap={4}>
          {renderTeamStatistics()}
          {renderAuditAccuracy()}
          {renderMetric()}
        </Grid>

        <Box my={4} />

        <Grid templateColumns="repeat(3, 1fr)" gap={4}>
          {renderMembers()}
          {renderTable()}
        </Grid>
      </Box>
    );
  }

  return (
    <CQContainer>
      {renderBreadCrumb()}
      {renderBackToAccounts()}
      {renderMainContent()}
    </CQContainer>
  );
};

export default withProvider(AccountsViewProvider, AccountsViewContainer);
