/* eslint-disable react-hooks/exhaustive-deps */
import { Box, HStack } from "@chakra-ui/react"
import { CQSelect } from "@prasanna-transcend/code-quick-components"
import { useContext, useEffect, useState } from "react"
import AppColors from "../../../constants/AppColors"
import { ReportContext } from "../../../providers/ReportProvider"
import AccuracyChart from "./components/AccuracyChart"
import AuditorQualityTable from "./components/AuditorQualityTable"

const AuditorQualityContainer = ({ isLoading, ilState, page1Ref }) => {
    const reportContext = useContext(ReportContext)
    const { getAuditorDropdown, getAuditorTableData, auditorOptions } = reportContext
    const [auditorFilterParams, setAuditorFilterParams] = useState({})

    useEffect(() => {
        getAuditorDropdown()
    }, [])

    const getTableData = async () => {
        ilState.on()
        await getAuditorTableData(auditorFilterParams)
        ilState.off()
    }

    useEffect(() => {
        getTableData()
    }, [auditorFilterParams])

    const commonSelectStyles = {
        control: props => ({
            ...props,
            borderRadius: 7,
            height:'50px',
            paddingRight: 10,
            paddingLeft: 10,
            fontSize: 'sm',
            color: AppColors.secondary,
            borderColor: AppColors.secondary
        }),
        placeholder: props => ({
            ...props,
            color: AppColors.secondary,
            fontWeight: 'bold'
        }),
        dropdownIndicator: props => ({
            ...props,
            color: AppColors.secondary
        }),
        singleValue: props => ({
            ...props,
            fontWeight: 'bold',
            color: AppColors.secondary,
        }),

    }

    return <Box mt={6} ref={page1Ref[0].ref}>
        <HStack justifyContent={'center'}>
            <Box w='xs'>
                <CQSelect
                    options={[{ label: 'Overall', value: '' }, ...auditorOptions]}
                    placeholder='Overall'
                    variant='primary'
                    isSearchable={false}
                    styles={commonSelectStyles}
                    onChange={e => {
                        setAuditorFilterParams({ auditor: e.value })
                    }}
                />
            </Box>
        </HStack>
        <AuditorQualityTable />
        <AccuracyChart />
    </Box>
}
export default AuditorQualityContainer