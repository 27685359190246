/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import {
  CQReactTable,
  useToastr,
} from '@prasanna-transcend/code-quick-components';
import { UrgentIcon } from '../../../../constants/IconData';
import { AuditContext } from '../../../../providers/AuditProvider';
import '../style.css';
import SpecialtyRow from '../../../../components/audit_table/SpecialityRow';
import { createColumnHelper } from '@tanstack/react-table';
import { toTitleCase } from '../../../../utils/common.utils';
import {
  ASSIGNED_AUDITS_ORDERING,
  getAuditStatus,
} from '../../../../constants/constants';
import useCQMediaQuery from '../../../../hooks/useCQMediaQuery';
import {
  defaultReactTableProp,
  paginationProps,
} from '../../../../utils/assigned_audit.util';
import { format, formatDistanceStrict } from 'date-fns';
import TotalPageCell from '../../../../components/audit_table/TotalPageCell';
import AssignAuditorCell from '../../../../components/audit_table/AssignAuditorCell';
import FileViewAction from '../FileViewAction';
import { successToast } from '../../../../components/successToast';
import { AuthContext } from '../../../../providers/AuthProvider';

const columnHelper = createColumnHelper();

const NewUploadTable = ({ tabName }) => {
  const auditContext = useContext(AuditContext);
  const authContext = useContext(AuthContext);

  const [ordering, setOrdering] = useState('');
  const toast = useToastr();
  // media query
  const canScroll = useCQMediaQuery({
    lg: false,
    md: false,
    sm: true,
  });

  const { isUpdating, getAuditNewUploads, updateSubmittedChart, filterParams } =
    auditContext;

  useEffect(() => {
    // if (getAuditNewUploads.showOrdering !== undefined) {
    getAuditNewUploads.loadAPI();
    // }
  }, [filterParams, getAuditNewUploads.showOrdering]);

  useEffect(() => {
    if (ASSIGNED_AUDITS_ORDERING.includes(ordering)) {
      getAuditNewUploads.setParams({ ordering });
    }
  }, [ordering]);

  async function assignAuditor(auditorId, rowId) {
    updateSubmittedChart(rowId, { assigned_auditor: auditorId })
      .then(r => {
        const toastProps = {
          header: 'Success!',
          description: 'Auditor Assigned successfully',
        };
        successToast(toast, toastProps);
      })
      .catch(() => { });
  }

  async function updateTotalPage(totalPage, rowId) {
    return updateSubmittedChart(rowId, { total_page: totalPage });
  }

  async function updateSpecialty(specialtyId, rowId) {
    updateSubmittedChart(rowId, {
      assigned_specialties: specialtyId,
      assigned_auditor: null,
    });
  }

  const renderActionComponent = auditUpload => {
    return (
      <HStack>
        <FileViewAction auditUpload={auditUpload} />
      </HStack>
    );
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: '',
        enableSorting: false,
        cell: info => {
          return (
            <HStack
              justifyContent={'flex-start'}
              alignItems={'center'}
              spacing={3}
            >
              {info.row.original?.urgent ? <UrgentIcon /> : <Box ml={2} />}
            </HStack>
          );
        },
      }),
      columnHelper.accessor('upload_id', {
        header: () => 'Upload #',
        id: 'chart_id',
        cell: info => {
          return (
            <HStack
              justifyContent={'flex-start'}
              alignItems={'center'}
              spacing={3}
            >
              <Text>{info.renderValue()}</Text>
            </HStack>
          );
        },
      }),
      columnHelper.accessor(row => row.uploaded_date, {
        id: 'upload_date',
        header: () => <span>Chart Upload</span>,
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return 'N/A';
          }

          return format(new Date(cellValue), 'MM/dd/yyyy');
        },
      }),
      columnHelper.accessor(p => p.client_name?.first_name, {
        header: () => 'Uploaded By',
        id: 'client',
        cell: info => {
          const name = toTitleCase(
            `${info.row.original.client_name?.first_name} ${info.row.original.client_name?.last_name}`
          );
          return <Text>{name}</Text>;
        },
      }),
      columnHelper.accessor('specialties', {
        header: () => 'Specialties',
        id: 'specialty__name',
        enableSorting: false,
        cell: info => (
          <SpecialtyRow
            selectedValue={info.row.original?.specialties?.id}
            specialties={
              info.row.original.client_name?.specialties?.length
                ? info.row.original.client_name.specialties.map(d => ({
                  label: d.name,
                  value: d.id,
                }))
                : []
            }
            onChange={specialtyId =>
              updateSpecialty(specialtyId, info.row.original.id)
            }
          />
        ),
      }),
      columnHelper.accessor('total_page', {
        header: () => 'Pages',
        id: 'total_pages',
        enableSorting: false,
        cell: info => {
          return (
            <TotalPageCell
              value={info.row.original?.total_page}
              onSave={v => updateTotalPage(v, info.row.original.id)}
            />
          );
        },
      }),
      columnHelper.accessor('status', {
        header: () => 'Status',
        id: 'status',
        cell: info => <Text lineHeight={'27px'} >{getAuditStatus(info.row.original?.status)}</Text>,
      }),
      columnHelper.accessor('updated_date', {
        header: () => 'Last Updated',
        id: 'updated_date',
        cell: info => {
          let updatedAt = info.getValue();

          updatedAt = updatedAt
            ? formatDistanceStrict(new Date(updatedAt), new Date(), {
              addSuffix: true,
            })
            : 'N/A';

          return (
            <Text>
              {updatedAt !== 'N/A' ? toTitleCase(updatedAt) : updatedAt}
            </Text>
          );
        },
      }),
      columnHelper.accessor('assigned_auditor', {
        header: () => 'Assigned to',
        id: 'auditor',
        enableSorting: false,
        cell: info => {
          // const assignedAuditor = info.row.original?.assigned_auditor;

          // let selectedValue = null;
          // if (assignedAuditor) {
          //   selectedValue = {
          //     label: toTitleCase(
          //       `${assignedAuditor.first_name} ${assignedAuditor.last_name}`
          //     ),
          //     value: assignedAuditor.id,
          //   };
          // }
          const assignedAuditor = info.row.original?.assigned_auditor;
          let selectedValue = null;
          if (assignedAuditor) {
            selectedValue = {
              label: toTitleCase(
                `${assignedAuditor.first_name} ${assignedAuditor.last_name}`
              ),
              value: assignedAuditor.id,
              isCurrentUser: assignedAuditor.id === authContext.currentUser?.id,
            };
          }
          return (
            <AssignAuditorCell
              auditUpload={info.row.original}
              selectedValue={selectedValue}
              onChange={e => {
                assignAuditor(e, info.row.original.id);
              }}
              selectedSpecialtyId={info.row.original.specialties?.id}
            />
          );
        },
      }),
      columnHelper.accessor('actions', {
        header: '',
        enableSorting: false,
        cell: info => {
          return renderActionComponent(info.row.original);
        },
      }),
    ],
    [auditContext.isFetchingNewUploads, auditContext.auditNewUploadsList]
  );

  function handlePreviousPage() {
    auditContext.getAuditNewUploads.loadPrevious();
  }

  function handleNextPage() {
    auditContext.getAuditNewUploads.loadNext();
  }

  function handleSizeChange(size) {
    auditContext.getAuditNewUploads({ size });
  }

  return (
    <Box
      mt={3}
      pointerEvents={isUpdating ? 'none' : 'inherit'}
      overflowX={canScroll ? 'scroll' : 'inherit'}
    >
      <Box>
        <CQReactTable
          manualSortBy={true}
          isLoading={auditContext.isFetchingNewUploads}
          data={auditContext.auditNewUploadsList}
          columns={columns}
          {...defaultReactTableProp}
          {...paginationProps(getAuditNewUploads)}
          onPreviousPage={handlePreviousPage}
          onNextPage={handleNextPage}
          onSizeChange={handleSizeChange}
          onAsc={header => {
            setOrdering(header);
          }}
          onDesc={header => {
            setOrdering(`-${header}`);
          }}
        />
      </Box>
    </Box>
  );
};
export default NewUploadTable;
