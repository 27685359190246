import AppColors from './AppColors';

export const MANAGER = 'MANAGER';

export const SELF = 'self';
export const ALL = ['AWAITING AUDIT', 'IN PROGRESS'];
export const NEW_UPLOAD = ['AWAITING AUDIT'];
export const ARCHIVED = 'ARCHIVED';
export const REBUTTAL = ['QA REBUTTAL'];
export const URGENT = ['QA REBUTTAL', 'IN PROGRESS', 'AWAITING AUDIT'];
export const AUDITED = [
  'QA REBUTTAL',
  'CLIENT REBUTTAL',
  'IN REVIEW',
  'AWAITING REVIEW',
];
export const REBUTTAL_STATUS = ['QA REBUTTAL', 'CLIENT REBUTTAL'];

export const MY_AUDITS = ['IN REVIEW'];
export const getAuditStatus = props => {
  switch (props) {
    case 'ON HOLD':
      return 'On Hold';
    case 'AWAITING AUDIT':
      return 'Awaiting Audit';
    case 'IN PROGRESS':
      return 'In Progress';
    case 'IN REVIEW':
      return 'In Review';
    case 'AWAITING REVIEW':
      return 'Awaiting Review';
    case 'ARCHIVED':
      return 'Archived';
    case 'AWAITING ASSIGNMENT':
      return 'Awaiting Assignment';
    case 'QA REBUTTAL':
      return 'QA Rebuttal';
    case 'CLIENT REBUTTAL':
      return 'Client Rebuttal';
    default:
      return 'n/a';
  }
};
export const SIZE = 10;
export const CREATE_MODE = 'CREATE';
export const EDIT_MODE = 'EDIT';

export const ROLES = [
  { label: 'QA', value: 'QA' },
  { label: 'Auditor', value: 'AUDITOR' },
];
export const OVERLAPPED_MESSAGE = 'You have overlapped some pages';
export const EXCEEDED_MESSAGE = 'You have exceeded some pages';
export const LARGER_MESSAGE = 'Enter a page number smaller than "To" page';
export const SMALLER_MESSAGE = 'Enter a page number greater than "From" page';

export const ASSIGNED_TO_SELF = 'Assign to Self';
export const AWAITING_AUDIT = 'AWAITING AUDIT';
export const AWAITING_ASSIGNMENT = 'AWAITING ASSIGNMENT';
export const ON_HOLD = 'ON HOLD';
export const ROLES_VALUES = {
  QA: 'QA',
  AUDITOR: 'Auditor',
  MANAGER: 'Manager',
  CLIENT: 'Client',
};
export const TEAM_ORDERING = ['name', 'members', '-name', '-members'];
export const ASSIGNED_AUDITS_ORDERING = [
  'chart_id',
  'upload_date',
  'client',
  'specialty__name',
  'total_pages',
  'status',
  'updated_date',
  'auditor',
  '-chart_id',
  '-upload_date',
  '-client',
  '-specialty__name',
  '-total_pages',
  '-status',
  '-updated_date',
  '-auditor',
];
export const MY_AUDITS_ORDERING = [
  'chart_id',
  'upload_date',
  'client',
  'specialty__name',
  'total_pages',
  'status',
  'updated_date',
  'auditor',
  'qa',
  'audited_date',
  '-chart_id',
  '-upload_date',
  '-client',
  '-specialty__name',
  '-total_pages',
  '-status',
  '-updated_date',
  '-auditor',
  '-qa',
  '-audited_date',
];
export const REQUIRED_FIELDS = [
  'encounter_no',
  'rendering',
  'enc_dt',

  'srvcs_no',
  // 'billed_cpt',
  'provider_rvu',
  'provider_dollar_value',

  'response',
  'agree',
  'disagree',

  'audited_cpt',

  'audited_rvu',
  'audited_dollar_value',
];
export const ENABLE_EDIT_STATUS = ['AWAITING ASSIGNMENT', 'AWAITING AUDIT'];
export const ACCOUNT_STATUS = {
  ACTIVE: { label: 'Active', color: AppColors.secondary },
  INACTIVE: { label: 'Inactive', color: AppColors.darkGray },
};
export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';
export const DEPARTMENT = 'DEPARTMENT';

// team table ordering
export const TEAMS_ORDERING = [
  'chart_id',
  'upload_date',
  'audited_date',
  'status',
  '-chart_id',
  '-upload_date',
  '-audited_date',
  '-status',
];
export const ACCOUNTS_ORDERING = [
  'chart_id',
  'upload_date',
  'audited_date',
  'status',
  '-chart_id',
  '-upload_date',
  '-audited_date',
  '-status',
];

export const firstSetColumnKeyOrder = ['encounter_no', 'rendering', 'enc_dt'];

export const billedIctColumn = ['srvcs_no'];

export const billedCptColumn = ['billed_cpt'];

export const billedModifierColumn = ['billed_modifier'];

export const rvuColumnsOrder = [
  'provider_rvu',
  'provider_dollar_value',
  'response',
  'agree',
  'disagree',
];

export const auditedIctColumn = ['audited_icd'];

export const auditedCptColumn = ['audited_cpt'];

export const auditedModifierColumn = ['audited_modifier'];

export const auditedRuvColumns = [
  'audited_rvu',
  'audited_dollar_value',
  'notes',
];

export const defaultColumns = [
  'provider_education',
  'number_complexity',
  'amount_complexity',
  'risk_complications',
];

export const addionalAttributesKeys = [
  'srvcs_no_',

  'billed_cpt',
  'billed_cpt_',

  'billed_modifier',
  'billed_modifier_',

  'audited_icd_',

  'audited_cpt',
  'audited_cpt_',

  'audited_modifier',
  'audited_modifier_',

  ...defaultColumns,
];

export const defaultDataProps = ['file_name', 'chart_id', 'id', 'row_id'];

export const dynamicColumnKeywords = [
  'srvcs_no',
  'billed_icd',
  'billed_modifier',
  'audited_icd',
  'audited_cpt',
  'audited_modifier',
];

export const STATES = [
  {
    label: 'Alabama, AL',
    value: 'AL',
  },
  {
    label: 'Alaska, AK',
    value: 'AK',
  },
  {
    label: 'Arizona, AZ',
    value: 'AZ',
  },
  {
    label: 'Arkansas, AR',
    value: 'AR',
  },
  {
    label: 'California, CA',
    value: 'CA',
  },
  {
    label: 'Colorado, CO',
    value: 'CO',
  },
  {
    label: 'Connecticut, CT',
    value: 'CT',
  },
  {
    label: 'Delaware, DE',
    value: 'DE',
  },
  {
    label: 'Florida, FL',
    value: 'FL',
  },
  {
    label: 'Georgia, GA',
    value: 'GA',
  },
  {
    label: 'Hawaii, HI',
    value: 'HI',
  },
  {
    label: 'Idaho, ID',
    value: 'ID',
  },
  {
    label: 'Illinois, IL',
    value: 'IL',
  },
  {
    label: 'Indiana, IN',
    value: 'IN',
  },
  {
    label: 'Iowa, IA',
    value: 'IA',
  },
  {
    label: 'Kansas, KS',
    value: 'KS',
  },
  {
    label: 'Kentucky, KY',
    value: 'KY',
  },
  {
    label: 'Louisiana, LA',
    value: 'LA',
  },
  {
    label: 'Maine, ME',
    value: 'ME',
  },
  {
    label: 'Maryland, MD',
    value: 'MD',
  },
  {
    label: 'Massachusetts, MA',
    value: 'MA',
  },
  {
    label: 'Michigan, MI',
    value: 'MI',
  },
  {
    label: 'Minnesota, MN',
    value: 'MN',
  },
  {
    label: 'Mississippi, MS',
    value: 'MS',
  },
  {
    label: 'Missouri, MO',
    value: 'MO',
  },
  {
    label: 'Montana, MT',
    value: 'MT',
  },
  {
    label: 'Nebraska, NE',
    value: 'NE',
  },
  {
    label: 'Nevada, NV',
    value: 'NV',
  },
  {
    label: 'New Hampshire, NH',
    value: 'NH',
  },
  {
    label: 'New Jersey, NJ',
    value: 'NJ',
  },
  {
    label: 'New Mexico, NM',
    value: 'NM',
  },
  {
    label: 'New York, NY',
    value: 'NY',
  },
  {
    label: 'North Carolina, NC',
    value: 'NC',
  },
  {
    label: 'North Dakota, ND',
    value: 'ND',
  },
  {
    label: 'Ohio, OH',
    value: 'OH',
  },
  {
    label: 'Oklahoma, OK',
    value: 'OK',
  },
  {
    label: 'Oregon, OR',
    value: 'OR',
  },
  {
    label: 'Pennsylvania, PA',
    value: 'PA',
  },
  {
    label: 'Rhode Island, RI',
    value: 'RI',
  },
  {
    label: 'South Carolina, SC',
    value: 'SC',
  },
  {
    label: 'South Dakota, SD',
    value: 'SD',
  },
  {
    label: 'Tennessee, TN',
    value: 'TN',
  },
  {
    label: 'Texas, TX',
    value: 'TX',
  },
  {
    label: 'Utah, UT',
    value: 'UT',
  },
  {
    label: 'Vermont, VT',
    value: 'VT',
  },
  {
    label: 'Virginia, VA',
    value: 'VA',
  },
  {
    label: 'Washington, WA',
    value: 'WA',
  },
  {
    label: 'West Virginia, WV',
    value: 'WV',
  },
  {
    label: 'Wisconsin, WI',
    value: 'WI',
  },
  {
    label: 'Wyoming, WY',
    value: 'WY',
  },
  {
    label: 'Armed Forces (the) Americas, AA',
    value: 'AA',
  },
  {
    label: 'Armed Forces Europe, AE',
    value: 'AE',
  },
  {
    label: 'Armed Forces Pacific, AP',
    value: 'AP',
  },
  {
    label: 'American Samoa, AS',
    value: 'AS',
  },
  {
    label: 'District of Columbia, DC',
    value: 'DC',
  },
  {
    label: 'Guam, GU',
    value: 'GU',
  },
  {
    label: 'Northern Mariana Islands, MP',
    value: 'MP',
  },
  {
    label: 'Puerto Rico, PR',
    value: 'PR',
  },
  {
    label: 'Virgin Islands, VI',
    value: 'VI',
  },
  {
    label: 'National totals, XX',
    value: 'XX',
  },
  {
    label: 'Generic field, ZZ',
    value: 'ZZ',
  },
];

export const headingValidationRegex = /^(?!^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$)(?!^[\s]*$)(?!^[0-9]*$).+$/ ;

export const onlyAlphabetsRegex = /^[a-zA-Z]+$/;