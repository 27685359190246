import { useBoolean } from '@prasanna-transcend/code-quick-components';
import React, { useState } from 'react';
import { DotMenuIcon } from '../constants/IconData';
import DatePickerPopup from './modal/date_picker_popup/DatePickerPopup';
import { Text } from '@chakra-ui/react';
import AppColors from '../constants/AppColors';
import { formatDate } from '../utils/formatDate.util';

// accounts - date - popupModal
const AuditAccuracyDateFilter = ({ isLoading, onFilterChange,filterParams }) => {
  const [isOpenDateFilter, iodfState] = useBoolean(false);

  const [dateRanges, setDateRanges] = useState({
    currentDate:formatDate(filterParams?.end_date),
    endDate: formatDate( filterParams?.start_date),
    previousStartDate: formatDate( filterParams?.previous_end_date),
    previousEndDate: formatDate(filterParams?.previous_start_date),
  });


  const onSubmit = async dates => {
    onFilterChange({ ...dates });
    iodfState.off();
  };
  return (
    <>
      {/* <DotMenuIcon
        style={{ cursor: 'pointer' }}
        onClick={() => iodfState.on()}
      /> */}
      <Text
        cursor={'pointer'}
        _hover={{ textDecoration: 'underline' }}
        onClick={() => iodfState.on()}
        color={AppColors.primary}
      >
        {' '}
        Select Dates
      </Text>

      <DatePickerPopup
        isOpen={isOpenDateFilter}
        onClose={iodfState.off}
        onSubmit={onSubmit}
        isLoading={isLoading}
        dateRanges={dateRanges}
      />
    </>
  );
};

export default AuditAccuracyDateFilter;
