import React, { createContext, useState } from 'react';
import {
  useBoolean,
  useToastr,
  usePagination,
  paginationReturnType,
} from '@prasanna-transcend/code-quick-components';
import accountsRepository from '../repositories/AccountsRepository';
import { successToast } from '../components/successToast';

export const AccountContext = createContext({
  isLoadingAccountsList: false,
  accountsList: [],

  // specialty related
  specialtyOptions: [],
  isFetchingSpecialtyOption: false,
  isCreatingSpecialtyOption: false,
  fetchSpecialtyOptions: async () => null,
  createSpecialtyOption: async specialtyName => null,

  // insurance related
  insuranceOptions: [],
  isFetchingInsurance: false,
  isCreatingInsurance: false,
  fetchInsuranceOptions: async () => null,
  createInsuranceOption: async insuranceName => null,

  // ehr related
  ehrOptions: [],
  isFetchingEHR: false,
  isCreatingEHR: false,
  fetchEHROptions: async () => { },
  createEHROptions: async optionName => { },

  isDepartmentLoading: false,
  onDeleteDepartment: async () => { },
  getDepartment: async () => { },
  createDepartment: async () => { },
  updateDepartment: async () => { },
  errorObject: {},

  //hospital
  updateHospital: async () => { },
  isHospitalLoading: false,
  onDeleteHospital: async () => { },
  //account
  getAccount: async () => { },
  getHospital: async () => { },
  deleteAccount: async () => { },
  account: {},
  hospital: {},
  department: {},
  departmentDropdown: [],
  setDepartmentDropdown: () => { },
  getDepartmentDropdown: async () => { },
  createHospital: async () => { },

  fetchHospital: async () => { },
  hospitalData: [],
  isGettingHospitalData: false,

  getProviderEmailByKeyword: () => { },
  createProvider: () => { },
  isProviderCreating: false,
  getProvidersByDeparment: () => { },
  getProvider: () => { },
  setProviderData: () => { },
  onProviderUpdate: () => { },
  onDeleteProvider: () => { },
  //pagination
  getAccountsList: paginationReturnType,

  // create health System
  createHealthSystem: async payload => { },
  isCreatingHealthSystem: false,

  // update health system
  updateHealthSystemInformation: async (healthSystemid, payload) => { },

  // healthsystem_validation
  getHealthSystemNames: async () => { },
  hsNamesAndPrefix: [],

  // user mail ids
  getUsersMailId: async () => { },
  usersMailIds: [],
  isFetchingPhysicians: false,

  // hospital
  cqHospitalsData: async () => { },
  hospitalDatas: [],

  // updated hospital
  updatedHospitalList: {},
  isCreatingHospital: false,
  accountFilters: {},
  setAccountFilters: () => { },
  setSpecialtyOptions: () => { },
});

const AccountsProvider = ({ children }) => {
  const toast = useToastr();
  const [isLoadingAccountsList, ilalState] = useBoolean(false);
  const [accountsList, setAccountsList] = useState([]);

  // Specialty Options related states
  const [specialtyOptions, setSpecialtyOptions] = useState([]);
  const [isFetchingSpecialtyOption, ifsoState] = useBoolean(false);
  const [isCreatingSpecialtyOption, icsoState] = useBoolean(false);

  // Insurance Options related states
  const [insuranceOptions, setInsuranceOptions] = useState([]);
  const [isFetchingInsurance, ifiState] = useBoolean(false);
  const [isCreatingInsurance, icfState] = useBoolean(false);

  // EHR options related states
  const [ehrOptions, setEHROptions] = useState([]);
  const [isFetchingEHR, ifeState] = useBoolean(false);
  const [isCreatingEHR, iceState] = useBoolean(false);

  const [isDepartmentLoading, idlState] = useBoolean(false);
  const [errorObject, setErrorObject] = useState({});
  const [isHospitalLoading, ihlState] = useBoolean(false);

  const [account, setAccount] = useState({});
  const [hospital, setHospital] = useState({});
  const [department, setDepartment] = useState({});

  // Hospital related states
  const [hospitalData] = useState([]);
  const [isGettingHospitalData, ighdSate] = useBoolean(false);

  const [accountListCount, setAccountListCount] = useState(0);
  const [providerData, setProviderData] = useState([]);

  const getAccountsList1 = usePagination(getAccountsList);
  const [departmentDropdown, setDepartmentDropdown] = useState([
    { label: 'Cardiology', value: 1, id: 1 },
  ]);

  const [isCreatingHealthSystem, ichsState] = useBoolean(false);
  const [isProviderCreating, ipcState] = useBoolean(false);

  // fetchPhyscians
  const [isFetchingPhysicians, ifpState] = useBoolean(false);

  // health_system_Names_prefix
  const [hsNamesAndPrefix, setHSnamesAndPrefix] = useState([]);

  // user mail ids
  const [usersMailIds, setUserMailIds] = useState([]);

  // hospitals
  const [hospitalDatas, setHospitalDatas] = useState([]);

  // updated hospital
  const [updatedHospitalList, setUpdatedHospitalList] = useState({});

  // create hospital
  const [isCreatingHospital, ichState] = useBoolean(false);

  const [accountFilters, setAccountFilters] = useState({});

  function showErrorMessage(errors) {
    for (const key in errors) {
        const error = errors[key];
        if (typeof error === "string") {
          toast.showError({ description: error });
        } else if (typeof error === 'object') {
            showErrorMessage(error);
        }
    }
}

  async function fetchSpecialtyOptions(queryParams = {}) {
    try {
      ifsoState.on();
      const response = await accountsRepository.getSpecialtyOptions(
        queryParams
      );
      setSpecialtyOptions(response);
    } catch (e) {
      // TODO: Handle Exception
    } finally {
      ifsoState.off();
    }
  }

  async function updateHealthSystemInformation(healthSystemId, payload) {
    try {
      toast.showLoading({ description: 'Updating' });
      const response = await accountsRepository.updateHealthSystemInformation(
        healthSystemId,
        payload
      );
      const toastProps = {
        header: 'Success!',
        description: `Updated Successfully`,
      };
      successToast(toast, toastProps);
      return response;
    } catch (error) {
      const response = error.cause;
      showErrorMessage(response);
    }
  }

  async function createSpecialtyOption(specialtyName) {
    try {
      icsoState.on();
      if (insuranceOptions.findIndex(d => d.label === specialtyName) === -1) {
        setSpecialtyOptions(prev => [
          ...prev,
          { label: specialtyName, value: specialtyName },
        ]);
      }
    } catch (e) {
      toast.showError({ description: e.toString() });
    } finally {
      icsoState.off();
    }
  }

  async function fetchInsuranceOptions() {
    try {
      ifiState.on();
      const response = await accountsRepository.getInsuranceOptions();
      const insuranceList = response?.map(opt => {
        const options = {
          label: opt.name,
          value: opt.id,
        };
        return options;
      });
      setInsuranceOptions(insuranceList);
    } catch (e) {
      // TODO: Handle Exception
    } finally {
      ifiState.off();
    }
  }

  async function createInsuranceOption(insuranceName) {
    try {
      icfState.on();
      if (insuranceOptions.findIndex(d => d.label === insuranceName) === -1) {
        setInsuranceOptions(prev => [
          ...prev,
          { label: insuranceName, value: insuranceName },
        ]);
      }
    } catch (e) {
      toast.showError({ description: e.toString() });
    } finally {
      icfState.off();
    }
  }

  async function fetchEHROptions() {
    try {
      ifeState.on();
      const response = await accountsRepository.getEHROptions();
      const options = response.map(res => ({ label: res.name, value: res.id }));
      setEHROptions([...options]);
    } catch (e) {
      // TODO: Capture exception
    } finally {
      ifeState.off();
    }
  }

  async function createEHROptions(optionName) {
    try {
      iceState.on();
      if (ehrOptions.findIndex(d => d.label === optionName) === -1) {
        setEHROptions(prev => [
          ...prev,
          { label: optionName, value: optionName },
        ]);
      }
    } catch (e) {
      toast.showError({ description: e.toString() });
    } finally {
      iceState.off();
    }
  }

  async function getAccountsList(paginationParams) {
    try {
      ilalState.on();
      const response = await accountsRepository.accountsList({
        ...paginationParams,
        ...accountFilters,
      });
      setAccountsList(response.results);
      setAccountListCount(response.count);
      return response;
    } catch (error) {
      toast.showError({
        description: `${error}`,
      });
    } finally {
      ilalState.off();
    }
  }

  async function fetchHospital(hospitalId) {
    try {
      ighdSate.on();
      const response = await accountsRepository.getHospital(hospitalId);
      return response;
    } catch (e) {
      toast.showError({ description: e.toString() });
    } finally {
      ighdSate.off();
    }
  }
  // department
  const onDeleteDepartment = async departmentId => {
    //delete function
    try {
      idlState.on();
      await accountsRepository.deleteDepartment(departmentId);
      const toastProps = {
        header: 'Success!',
        description: `${'Department deleted.'}`,
      };
      successToast(toast, toastProps);
    } catch (error) {
      toast.showError({
        description: `${error.toString()}`,
      });
    } finally {
      idlState.off();
    }
  };

  const getDepartment = async departmentId => {
    //delete function
    try {
      idlState.on();
      const res = await accountsRepository.getDepartment(departmentId);
      setDepartment(res);
      return res;
    } catch (error) {
      toast.showError({
        description: `${error.toString()}`,
      });
    } finally {
      idlState.off();
    }
  };

  const createDepartment = async body => {
    //delete function
    try {
      idlState.on();
      const res = await accountsRepository.createDepartment(
        body.hospital,
        body
      );
      const toastProps = {
        header: 'Success!',
        description: `${'Department created'}`,
      };
      successToast(toast, toastProps);
      return res;
    } catch (error) {
      const duplicateDepartmentError = error.cause;
      showErrorMessage(duplicateDepartmentError)
    } finally {
      idlState.off();
    }
  };

  const updateDepartment = async (departmentId, body) => {
    //delete function
    try {
      idlState.on();
      const res = await accountsRepository.updateDepartment(departmentId, body);
      const toastProps = {
        header: 'Success!',
        description: `${'Department updated'}`,
      };
      successToast(toast, toastProps);
      return res;
    } catch (error) {
      const duplicateDepartmentError = error.cause;
      showErrorMessage(duplicateDepartmentError)
    } finally {
      idlState.off();
    }
  };

  const getAccount = async accountId => {
    //delete function
    try {
      idlState.on();
      const res = await accountsRepository.getAccount(accountId);
      setAccount(res);
    } catch (error) {
      toast.showError({
        description: `${error.toString()}`,
      });
    } finally {
      idlState.off();
    }
  };

  const getHospital = async hospitalId => {
    //delete function
    try {
      idlState.on();
      const res = await accountsRepository.getHospital(hospitalId);
      setHospital(res);
    } catch (error) {
      toast.showError({
        description: `${error.toString()}`,
      });
    } finally {
      idlState.off();
    }
  };

  const createHospital = async hospitalData => {
    try {
      ichState.on();
      const res = await accountsRepository.createHospital(hospitalData);
      const toastProps = {
        header: 'Success!',
        description: `${'Hospital Created'}`,
      };
      successToast(toast, toastProps);
      return res;
    } catch (error) {
      const response = error.cause;
      showErrorMessage(response)
    } finally {
      ichState.off();
    }
  };

  const updateHospital = async (hospitalId, body) => {
    //delete function
    try {
      ihlState.on();
      const res = await accountsRepository.updateHospital(hospitalId, body);
      setUpdatedHospitalList(res);
      const toastProps = {
        header: 'Success!',
        description: `${'Hospital updated'}`,
      };
      successToast(toast, toastProps);
      return res;
    } catch (error) {
      const response = error.cause;
      showErrorMessage(response);
    } finally {
      ihlState.off();
    }
  };
  const onDeleteHospital = async hospitalId => {
    //delete function
    try {
      ihlState.on();
      await accountsRepository.deleteHospital(hospitalId);
      const toastProps = {
        header: 'Success!',
        description: `${'Hospital deleted.'}`,
      };
      successToast(toast, toastProps);
    } catch (error) {
      toast.showError({
        description: `${error.toString()}`,
      });
    } finally {
      ihlState.off();
    }
  };
  const deleteAccount = async accountId => {
    try {
      idlState.on();
      await accountsRepository.deleteAccount(accountId);
      const temp = accountsList.filter(account => account.id !== accountId);
      setAccountsList([...temp]);
      const toastProps = {
        header: 'Success!',
        description: `${'Account deleted.'}`,
      };
      successToast(toast, toastProps);
    } catch (error) {
      toast.showError({
        description: `${error.toString()}`,
      });
    } finally {
      idlState.off();
    }
  };

  const getProviderEmailByKeyword = async keyword => {
    try {
      const response = await accountsRepository.getProviderEmail(keyword);
      return response;
    } catch (error) {
      toast.showError({
        description: `${error.toString()}`,
      });
    }
  };

  const createProvider = async (departmentId, providerBody) => {
    try {
      ipcState.on();
      const response = await accountsRepository.createProvider(
        departmentId,
        providerBody
      );
      const toastProps = {
        header: 'Success!',
        description: `${'Provider Created'}`,
      };
      successToast(toast, toastProps);
      return response;
    } catch (error) {
      const response = error.cause;
      showErrorMessage(response);
    } finally {
      ipcState.off();
    }
  };

  const getProvidersByDeparment = async (departmentId, queryParams) => {
    try {
      ifpState.on();
      const response = await accountsRepository.getProvidersByDeparment(
        departmentId,
        queryParams
      );
      setProviderData(response);
      return response;
    } catch (error) {
      toast.showError({ description: error.toString() });
    } finally {
      ifpState.off();
    }
  };

  const getProvider = async providerId => {
    try {
      const response = await accountsRepository.getProvider(providerId);
      return response;
    } catch (error) {
      toast.showError({ description: error.toString() });
    }
  };
  const onProviderUpdate = async (providerId, providerBody) => {
    try {
      const response = await accountsRepository.onProviderUpdate(
        providerId,
        providerBody
      );
      const toastProps = {
        header: 'Success!',
        description: `${'Provider Updated'}`,
      };
      successToast(toast, toastProps);
      return response;
    } catch (error) {
      const response = error.cause;
      showErrorMessage(response);
    }
  };

  const onDeleteProvider = async providerId => {
    try {
      const response = await accountsRepository.onDeleteProvider(providerId);
      const toastProps = {
        header: 'Success!',
        description: `${'Provider Deleted'}`,
      };
      successToast(toast, toastProps);
      return response;
    } catch (error) {
      toast.showError({ description: error.toString() });
    }
  };

  const getDepartmentDropdown = async hospitalId => {
    try {
      const res = await accountsRepository.getDepartmentDropdown(hospitalId);
      const data = res.map(d => ({ ...d, label: d.name, value: d.id }));
      setDepartmentDropdown(data);
    } catch (error) {
      toast.showError({
        description: `${error.toString()}`,
      });
    }
  };
  const createHealthSystem = async payload => {
    try {
      ichsState.on();
      const response = await accountsRepository.createHealthSystem(payload);
      const toastProps = {
        header: 'Success!',
        description: `Health System Created Successfully`,
      };
      successToast(toast, toastProps);
      return response;
    } catch (e) {
      const response = e.cause;
      showErrorMessage(response)
    } finally {
      ichsState.off();
    }
  };

  const getHealthSystemNames = async () => {
    try {
      const response = await accountsRepository.healthSystemNamesAndPrefix();
      setHSnamesAndPrefix(response);
      return response;
    } catch (error) {
      toast.showError({
        description: `${error.toString()}`,
      });
    }
  };

  const getUsersMailId = async () => {
    try {
      const response = await accountsRepository.cqUsersMailId();
      setUserMailIds(response);
      return response;
    } catch (error) {
      toast.showError({
        description: `${error.toString()}`,
      });
    }
  };

  const cqHospitalsData = async () => {
    try {
      const response = await accountsRepository.cqHospitalsData();
      setHospitalDatas(response);
      return response;
    } catch (error) {
      toast.showError({
        description: `${error.toString()}`,
      });
    }
  };

  const mContext = {
    isLoadingAccountsList,
    accountsList,

    // specialty related
    specialtyOptions,
    isFetchingSpecialtyOption,
    isCreatingSpecialtyOption,
    fetchSpecialtyOptions,
    createSpecialtyOption,

    // insurance related
    insuranceOptions,
    isFetchingInsurance,
    isCreatingInsurance,
    fetchInsuranceOptions,
    createInsuranceOption,

    // ehr related
    ehrOptions,
    isFetchingEHR,
    isCreatingEHR,
    fetchEHROptions,
    createEHROptions,

    isDepartmentLoading,
    onDeleteDepartment,
    getDepartment,
    createDepartment,
    updateDepartment,
    errorObject,

    getAccount,
    getHospital,
    account,
    hospital,
    department,
    deleteAccount,

    getProviderEmailByKeyword,
    createProvider,
    // creating provider <Boolean/>
    isProviderCreating,
    getProvidersByDeparment,
    getProvider,
    onProviderUpdate,
    onDeleteProvider,

    getAccountsList: getAccountsList1,
    accountListCount,

    hospitalData,
    providerData,
    setProviderData,
    fetchHospital,
    isGettingHospitalData,
    createHospital,

    updateHospital,
    isHospitalLoading,
    onDeleteHospital,
    departmentDropdown,
    setDepartmentDropdown,
    getDepartmentDropdown,

    // create health System
    createHealthSystem,
    isCreatingHealthSystem,
    // update health system
    updateHealthSystemInformation,
    isFetchingPhysicians,

    // healthsystem_validation
    getHealthSystemNames,
    hsNamesAndPrefix,

    // users MAil id
    getUsersMailId,
    usersMailIds,

    // hospital
    cqHospitalsData,
    hospitalDatas,
    // updated hospital list
    updatedHospitalList,
    isCreatingHospital,
    setAccountFilters,
    accountFilters,
    setSpecialtyOptions,
  };
  return (
    <AccountContext.Provider value={mContext}>
      {children}
    </AccountContext.Provider>
  );
};

export default AccountsProvider;
