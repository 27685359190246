import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import chartUploadRepository from "../repositories/ChartUploadRespository";
import { useBoolean } from '@chakra-ui/react'
import ROUTES from "../constants/Routes";
import { useToastr } from "@prasanna-transcend/code-quick-components";
import { successToast } from "../components/successToast";

export const UploadContext = createContext({});

const ChartUploadProvider = props => {
    const toast = useToastr()
    const navigate = useNavigate();
    let newCharts = []
    const [isSubmitting, isState] = useBoolean(false)
    const [uploadedCharts, setUploadedCharts] = useState([])
    const [clientUsers, setClientUsers] = useState([])

    const uploadCharts = async (files, index, clientId) => {
        const formData = new FormData()
        if (index === 0) {
            newCharts = files.map(f => {
                return {
                    name: f.name,
                    size: `${Math.floor(f.size / 1000)}kb`,
                    value: 0
                }
            })
        }
        formData.append('client', clientId)
        formData.append('upload_chart', files[index])
        //upload the chart and set the uploadedChart
        const uploadProgress = (data) => {
            newCharts[index] = { ...newCharts[index], value: Math.round((data.loaded / data.total) * 100) }
            setUploadedCharts([...newCharts])
        }

        if (files.length > index) {
            try {
                const response = await chartUploadRepository.uploadCharts(formData, uploadProgress)
                if (response.status === 201) {
                    newCharts[index] = {
                        ...newCharts[index],
                        id: response.data.file_obj.id,
                        preview_url: response.data.file_obj.preview_url,
                        uploaded: true,
                        cancelled: false,
                        value: 100
                    }
                    setUploadedCharts([...newCharts])
                }
                const toastProps = {
                    header: 'Success!',
                    description: `Files successfully uploaded`,  
                }
                successToast(toast, toastProps)
            } catch (e) {
                newCharts[index] = {
                    ...newCharts[index],
                    uploaded: false,
                    cancelled: true,
                    value: 100
                }
                setUploadedCharts([...newCharts])
                const message = e.response?.data?.message
                toast.showError({
                    description: message,
                })
            } finally {
                uploadCharts(files, index + 1, clientId)
            }
        }
    }

    const submitUploadedCharts = async (clientId) => {
        //submit the uploaded chart and redirect to audit list page
        //submit onlu the none deleted ones
        try {
            isState.on()
            let fileIds = []
            uploadedCharts.forEach(f => {
                if (!f.cancelled) {
                    fileIds.push(f.id)
                }
            })
            const formData = new FormData()
            formData.append('file_ids', fileIds.toString())
            formData.append('client', clientId)

            await chartUploadRepository.submitUploadedCharts(formData)
            setUploadedCharts([])
            const toastProps = {
                header: 'Success!',
                description: `Chart successfully uploaded`,  
            }
            successToast(toast, toastProps)
            navigate(ROUTES.ASSIGNED_AUDITS);

        } catch (e) {
            const message = e.response?.data?.message
            toast.showError({
                description: message,
            })
        } finally {
            isState.off();

        }
    }

    const deleteChart = async (id) => {
        //delete the chart and set uploadedChart data to deleted
        try {
            await chartUploadRepository.deleteChart(id)
        } catch (e) {
            const message = e.response?.data?.message
            toast.showError({
                description: message,
            })
        } finally {
            const index = uploadedCharts.findIndex(f => f.id === id)
            uploadedCharts[index] = { ...uploadedCharts[index], uploaded: false, cancelled: true }
            setUploadedCharts([...uploadedCharts])
        }
    }

    const getUsers = async () => {
        try {
            const response = await chartUploadRepository.getClientUsers()
            if (response.length) {
                const temp = response.map(user => { return { label: `${user.user.first_name} ${user.user.last_name}`, value: user.user.id } })
                setClientUsers(temp)
            }
        } catch (e) {
            //to do
        }
    }

    const mContext = {
        isSubmitting,
        uploadedCharts,
        clientUsers,

        uploadCharts,
        submitUploadedCharts,
        deleteChart,
        getUsers,
        setUploadedCharts
    };

    return (
        <UploadContext.Provider value={mContext}>
            {props.children}
        </UploadContext.Provider>
    );
};

export default ChartUploadProvider;
