/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    useContext,
    useEffect,
    useMemo,
} from 'react';
import {
    Box,
    HStack,
    Text,
} from '@chakra-ui/react';
import {
    CQReactTable,
} from '@prasanna-transcend/code-quick-components';
import {
    UrgentIcon,
} from '../../../../constants/IconData';

import { createColumnHelper } from '@tanstack/react-table';
import { getAuditStatus } from '../../../../constants/constants';
import useCQMediaQuery from '../../../../hooks/useCQMediaQuery';
import { format } from 'date-fns';

import '../style.css';
import { renderActionComponent } from '../MyAuditsComponents';
import { defaultReactTableProp, paginationProps } from '../../../../utils/my_audits.utils';
import { useNavigate } from 'react-router-dom';
import { UserManagementContext } from '../../../../providers/UserManagementProvider';

const columnHelper = createColumnHelper();

const AllMyAuditTable = () => {
    const myAuditContext = useContext(UserManagementContext);
    const navigate = useNavigate();

    // media query
    const canScroll = useCQMediaQuery({
        lg: false,
        md: false,
        sm: true
    })

    const {
        isUpdating,
        getAuditUpload,
        filterParams
    } = myAuditContext;

    useEffect(() => {
        getAuditUpload.loadAPI();
    }, [filterParams]);


    const columns = useMemo(() => [
        columnHelper.accessor('upload_id', {
            header: () => 'Upload #',
            cell: info => {
                return <HStack justifyContent={'flex-start'} alignItems={'center'} spacing={10}>
                    {info.row.original?.urgent ? <UrgentIcon /> : <Box ml={2} />}
                    <Text>{info.renderValue()}</Text>
                </HStack>
            },
        }),
        columnHelper.accessor(row => row.uploaded_date, {
            id: 'uploaded_date',
            header: () => <span>Upload Date</span>,
            cell: info => {
                const cellValue = info.getValue();
                if (!cellValue) {
                    return 'n/a';
                }

                return format(new Date(cellValue), 'MM/dd/yyyy')
            },
        }),
        columnHelper.accessor(row => row.total_page, {
            id: 'total_page',
            header: () => <span>Pages</span>,
            cell: info => {
                const cellValue = info.getValue();
                if (!cellValue) {
                    return 'n/a';
                }

                return cellValue
            },
        }),
        columnHelper.accessor('specialties', {
            header: () => 'Specialties',
            cell: info => {
                const cellValue = info.getValue();
                if (!cellValue) {
                    return 'n/a';
                }

                return cellValue.name === 'ent' ? 'ENT' : cellValue.name

            },
        }),

        columnHelper.accessor('status', {
            header: () => 'Status',
            cell: info => <Text>{getAuditStatus(info.row.original?.status)}</Text>
        }),

        columnHelper.accessor(row => row, {
            id: 'actions',
            header: () => 'Actions',
            enableSorting: false,
            cell: info => {
                const obj = info.getValue();
                return renderActionComponent(obj, navigate);
            }
        }),
    ], [myAuditContext.isAuditUploadListLoading]);

    function handlePreviousPage() {
        myAuditContext.getAuditUpload.loadPrevious();
    }

    function handleNextPage() {
        myAuditContext.getAuditUpload.loadNext();
    }

    function handleSizeChange(size) {
        myAuditContext.getAuditUpload({ size });
    }

    return (
        <Box pointerEvents={isUpdating ? 'none' : 'inherit'} overflowX={canScroll ? 'scroll' : 'inherit'}>
            <CQReactTable
                manualSortBy={true}
                isLoading={myAuditContext.isAuditUploadListLoading}
                data={myAuditContext.auditUploadList}
                columns={columns}
                {...defaultReactTableProp}
                {...paginationProps(getAuditUpload)}
                onPreviousPage={handlePreviousPage}
                onNextPage={handleNextPage}
                onSizeChange={handleSizeChange}
            />
        </Box>
    );
};
export default AllMyAuditTable;
