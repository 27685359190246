/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Center,
  Circle,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import {
  CQTable,
  TextInput,
  useBoolean,
  useToastr,
} from '@prasanna-transcend/code-quick-components';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import '../../../components/pdf_viewer/modal.css';
import AppColors from '../../../constants/AppColors';
import { AddIcon, TrashIcon } from '../../../constants/IconData';
import { FONT_FAMILY } from '../../../constants/Theme';
import { AuditContext } from '../../../providers/AuditProvider';
import { getTableColumn } from './specialtyColumnData';
import SplitAuditSpecialtyRow from './SplitAuditSpecialtyRow';
import {
  getIsOpen,
  getLabel,
  maxLimitCheck,
  overlappedCheck,
} from './splitAuditsUtils';
import { USER_DATA } from '../../../constants/PreferenceKeys';
import { successToast } from '../../../components/successToast';
import AssignAuditorCell from '../../../components/audit_table/AssignAuditorCell';

const SplitAuditModal = ({ isOpen, onClose, currentUpload }) => {
  const [splitAudits, setSplitAudits] = useState([]);
  const [splitAuditErrors, setSplitAuditErrors] = useState([{}]);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [isSplitAudit, isaState] = useBoolean(false);
  const [formError, setFormError] = useState(true);

  const userData = localStorage.getItem(USER_DATA)
    ? JSON.parse(localStorage.getItem(USER_DATA))
    : {};
  const auditContext = useContext(AuditContext);
  const { users, specialties, createSplitAudit } = auditContext;
  const toast = useToastr();

  const calculateError = splitAuditData => {
    let error = false;
    for (let i = 0; i < splitAuditData.length; i++) {
      if (Object.keys(splitAuditData[i]).length !== 5) {
        error = true;
        break;
      }
      for (let j = 0; j < Object.keys(splitAuditData[i]).length; j++) {
        const fieldName = Object.keys(splitAuditData[i])[j];
        if (
          splitAuditData[i][fieldName] === '' ||
          splitAuditData[i][fieldName] === null
        ) {
          error = true;
          break;
        }
      }
    }
    setFormError(error);
  };

  const updateAudits = async (auditUpload, index, fieldName, value) => {
    switch (fieldName) {
      case 'page_number_from':
        if (value === '') {
          splitAudits[index][fieldName] = null;
        } else {
          if (
            auditUpload.page_number_to &&
            value > auditUpload.page_number_to
          ) {
            splitAuditErrors[index]['larger'] = true;
          } else {
            splitAuditErrors[index]['larger'] = false;
          }
          const from = Number(value);
          overlappedCheck(
            splitAudits,
            index,
            from,
            'fromOverlapped',
            splitAuditErrors,
            setSplitAuditErrors
          );
          maxLimitCheck(
            index,
            currentUpload.total_page,
            from,
            'fromMaxLimit',
            splitAuditErrors,
            setSplitAuditErrors
          );
          splitAudits[index][fieldName] = from;
          setSplitAuditErrors([...splitAuditErrors]);
        }
        break;
      case 'page_number_to':
        if (value === '') {
          splitAudits[index][fieldName] = null;
        } else {
          const to = Number(value);
          if (
            auditUpload.page_number_from &&
            value < auditUpload.page_number_from
          ) {
            splitAuditErrors[index]['smaller'] = true;
          } else {
            splitAuditErrors[index]['smaller'] = false;
          }
          overlappedCheck(
            splitAudits,
            index,
            to,
            'toOverlapped',
            splitAuditErrors,
            setSplitAuditErrors
          );
          maxLimitCheck(
            index,
            currentUpload.total_page,
            to,
            'toMaxLimit',
            splitAuditErrors,
            setSplitAuditErrors
          );
          splitAudits[index][fieldName] = Number(value);
          setSplitAuditErrors([...splitAuditErrors]);
        }
        break;
      case 'auditor':
        splitAudits[index][fieldName] = value;
        break;
      case 'specialty':
        splitAudits[index][fieldName] = value;
        splitAudits[index]['auditor'] = null;

        break;
      default:
        splitAudits[index][fieldName] = value;
    }
    setSplitAudits([...splitAudits]);
    calculateError(splitAudits);

    //update chart accordingly
  };
  let columnData = getTableColumn(updateAudits);
  let cellProps = [],
    headerProps = [];

  useEffect(() => {
    setSplitAuditErrors([{}]);
    setSplitAudits([{ chart_id: `${currentUpload.upload_id}-A` }]);
  }, [currentUpload]);

  if (!splitAudits.length) {
    setSplitAudits([{ chart_id: `${currentUpload.upload_id}-A` }]);
  }

  cellProps = columnData.map(data => {
    const { minWidth, width, headerStyle, cellStyle } = data;
    headerProps.push({ style: { minWidth, width, ...headerStyle } });
    return { style: { minWidth, width, ...cellStyle } };
  });

  const deleteSplitAudit = index => {
    if (splitAudits.length > 1) {
      setSplitAudits(splitAudits.filter((d, i) => i !== index));
      setSplitAuditErrors(splitAuditErrors.filter((d, i) => i !== index));
    }
  };

  const rowData = useMemo(() => {
    if (!splitAudits?.length) {
      return [];
    }
    return splitAudits.map((auditUpload, index) => {
      let selectedLabel = null;
      let auditorIndex = null;
      if (auditUpload.auditor === userData?.id) {
        selectedLabel = null;
      } else {
        auditorIndex = users.findIndex(
          o => o.value === auditUpload.auditor && o.role === 'AUDITOR'
        );
        if (auditorIndex !== -1) {
          selectedLabel = users[auditorIndex];
        }
      }

      return {
        auditUpload,
        chart_id: (
          <Text display={'flex'} justifyContent={'center'} fontSize="sm">
            {auditUpload.chart_id.length > 10
              ? `${auditUpload.chart_id.substring(0, 10)}...`
              : auditUpload.chart_id}
          </Text>
        ),
        from_page: (
          <Box>
            <TextInput
              fontSize="sm"
              style={{ height: '50px' }}
              id="page_number_from"
              name="page_number_from"
              type={'number'}
              value={
                auditUpload.page_number_from === null
                  ? ''
                  : auditUpload.page_number_from
              }
              onChange={e =>
                updateAudits(
                  auditUpload,
                  index,
                  'page_number_from',
                  e.target.value
                )
              }
              textAlign="center"
              paddingInline={2}
            />
            <Tooltip
              hasArrow
              label={getLabel(splitAuditErrors[index], 'page_number_from')}
              bg={AppColors.secondary}
              isOpen={getIsOpen(splitAuditErrors[index], 'page_number_from')}
            >
              <Box ml={1}></Box>
            </Tooltip>
          </Box>
        ),
        to_page: (
          <Box>
            <TextInput
              fontSize="sm"
              id="page_number_to"
              style={{ height: '50px' }}
              name="page_number_to"
              type={'number'}
              value={
                auditUpload.page_number_to === null
                  ? ''
                  : auditUpload.page_number_to
              }
              onChange={e =>
                updateAudits(
                  auditUpload,
                  index,
                  'page_number_to',
                  e.target.value
                )
              }
              textAlign="center"
              paddingInline={2}
            />
            <Tooltip
              hasArrow
              label={getLabel(splitAuditErrors[index], 'page_number_to')}
              bg={AppColors.secondary}
              isOpen={getIsOpen(splitAuditErrors[index], 'page_number_to')}
            >
              <Box ml={1}></Box>
            </Tooltip>
          </Box>
        ),
        specialty: (
          <SplitAuditSpecialtyRow
            onSpecialtySelect={value => {
              updateAudits(auditUpload, index, 'specialty', value);
            }}
            selectedValue={specialties.find(
              s => s.value === auditUpload.specialty
            )}
            currentUpload={currentUpload}
          />
        ),
        assigned_to: (
          <AssignAuditorCell
            auditUpload={auditUpload}
            selectedValue={selectedLabel}
            onChange={e => {
              updateAudits(auditUpload, index, 'auditor', e);
            }}
            selectedSpecialtyId={splitAudits[index]['specialty']}
            assignToSelf={() => {
              updateAudits(auditUpload, index, 'auditor', userData?.id);
            }}
          />
        ),
        actions:
          index === 0 ? null : (
            <TrashIcon
              onClick={() => deleteSplitAudit(index)}
              style={{ height: 30, width: 30, cursor: 'pointer' }}
            />
          ),
      };
    });
  }, [
    JSON.stringify(splitAudits),
    currentIndex,
    JSON.stringify(splitAuditErrors),
  ]);

  const splitAuditSubmit = async () => {
    try {
      isaState.on();
      await createSplitAudit(currentUpload.id, splitAudits);
      if (onClose) {
        onClose();
      }
      const toastProps = {
        header: 'Success!',
        description: `${'Split Audit.'}`,
      };
      successToast(toast, toastProps);
      auditContext.getAuditUpload.loadAPI();
      auditContext.getAuditNewUploads.loadAPI();
      auditContext.getAuditArchived.loadAPI();
      auditContext.getAuditRebuttals.loadAPI();
    } catch (err) {
      toast.showError({
        description: `${err}`,
      });
      // throw err
    } finally {
      isaState.off();
    }
  };

  return (
    <>
      <Modal
        size={'xl'}
        isOpen={isOpen}
        onClose={() => {
          if (onClose) {
            onClose();
          }
          setCurrentIndex(null);
          setSplitAuditErrors([{}]);
          setSplitAudits([]);
        }}
        isCentered
        onOverlayClick={() => {
          setCurrentIndex(null);
          if (onClose) {
            onClose();
          }
          setSplitAuditErrors([{}]);
          setSplitAudits([]);
        }}
      >
        <ModalOverlay
          bg={AppColors.modalOverlayColor}
          style={{ opacity: 0.8 }}
        />
        <ModalContent maxW={'50%'}>
          <Box position={'absolute'} top={-50} right={-50}>
            <Circle>
              <ModalCloseButton
                size={'md'}
                style={{ color: AppColors.white }}
                backgroundColor={AppColors.closeButtonColor}
                p={3}
                borderRadius={50}
              />
            </Circle>
          </Box>
          <ModalHeader mt={2} mb={5}>
            <Center fontFamily={FONT_FAMILY.baiJamurjee}>
              <Text fontSize={'24px'}>Split Audit</Text>
            </Center>
          </ModalHeader>
          <ModalBody
            maxHeight={350}
            overflow="scroll"
            overflowX={'hidden'}
            sx={{
              '&::-webkit-scrollbar': {
                width: '7px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 7px lightGrey',
                width: '7px',
                borderRadius: '7px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'grey',
                borderRadius: '7px',
              },
            }}
          >
            <CQTable
              UpIcon={null}
              DownIcon={null}
              columnData={columnData}
              rowData={rowData}
              headerColor={'white'}
              selectedRowColor={'white'}
              tableProps={{
                style: { width: '100%', zIndex: 1 },
              }}
              specificHeaderProps={headerProps}
              specificCellProps={cellProps}
            />
            <HStack m={5} mt={4}>
              <AddIcon
                style={{ height: '5', width: '5', cursor: 'pointer' }}
                onClick={() => {
                  const length = splitAudits.length;
                  setSplitAudits(prev => [
                    ...prev,
                    {
                      chart_id: `${
                        currentUpload.upload_id
                      }-${String.fromCharCode(65 + length)}`,
                    },
                  ]);
                  setSplitAuditErrors(prev => [...prev, {}]);
                  setFormError(true);
                }}
              />
              <Text fontSize={'sm'}>Split</Text>
            </HStack>
            <HStack justifyContent={'end'} mb={5}>
              <Button
                borderRadius="3xl"
                color="white"
                fontSize="sm"
                bg={AppColors.primary}
                style={{ paddingInline: '35px' }}
                height={'50px'}
                _hover={{
                  bg: AppColors.white,
                  color: AppColors.primary,
                  border: `2px solid ${AppColors.primary}`,
                }}
                type="submit"
                isLoading={isSplitAudit}
                onClick={() => splitAuditSubmit()}
                disabled={
                  formError ||
                  splitAuditErrors.some(
                    a => a.toMaxLimit || a.fromMaxLimit || a.smaller || a.larger
                  )
                }
              >
                Assign
              </Button>
              <Button
                onClick={() => {
                  if (onClose) {
                    onClose();
                  }
                  setSplitAuditErrors([{}]);
                  setSplitAudits([]);
                  setCurrentIndex(null);
                }}
                _hover={{
                  bg: AppColors.secondary,
                  color: AppColors.white,
                }}
                border={`2px solid ${AppColors.secondary}`}
                borderRadius="3xl"
                color={AppColors.secondary}
                cursor="pointer"
                fontFamily={FONT_FAMILY.ptSans}
                height={'50px'}
                fontSize="sm"
                px={'35px'}
              >
                Cancel
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
export { SplitAuditModal };
