/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import Handsontable from 'handsontable';
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.min.css';
import {
  AGREE_DISAGREE_COL,
  AUDIT_COLUMN_HEADER,
  AUDIT_SHEET_HEADER,
  AUDIT_SHEET_KEY,
  DOLLAR_COL,
  NUMERIC_KEYS,
  RENDERING_AS_COL,
  RESPONSE_AS_COL,
} from '../constants';
import { registerAllModules } from 'handsontable/registry';
import '../styles.css';
import { useContext } from 'react';
import { AuditSheetContext } from '../../../providers/AuditSheetProvider';
import { useInterval } from '../../../hooks';
import { useToastr } from '@prasanna-transcend/code-quick-components';
import { getRowColArray, getRowColIndex } from '../getRowColumn';
import AppColors from '../../../constants/AppColors';
import { registerCustomCells } from './hands_on_table_renderers/CustomRenderers';
import { format } from 'date-fns';
import { toTitleCase } from '../../../utils/common.utils';
import {
  creatColumnHeader,
  createColumnDataName,
  emptyDataPrefilledObject,
  findAgreeDisAgreeColumn,
  findDollorColumn,
  findHighlightedColumn,
  findNumericColumns,
  findResponseColumnIndex,
  getAuditSheetKeys,
  isColumnMatched,
  isEmptySheet,
  prepareAuditSheetColumn,
  prepareAuditSheetHeader,
} from '../../../utils/auditsheet.utils';
import { dynamicColumnKeywords } from '../../../constants/constants';
import { removeNullObjects, sanitize } from './sanitize';

registerCustomCells({});

function getScaledRowHeight(scale) {
  return `${24 * scale}px`;
}

function getScaledHeaderFontSize(scale) {
  return 13 * scale + 'px';
}

function getScaledRowFontSize(scale) {
  return 14 * scale + 'px';
}

//  -------------------component starts here-----------------------------

const AuditSheet = props => {
  // refs
  const hotRef = useRef(null);

  // props

  /**
   *  { updateSheet } update the data in setState(setSheet);
   *  { updateAuditSheet } create audit sheet(POST) and getSheets and update sheet;
   *  { sheet } gives a current sheet not sheets
   */

  const { uploadId, updateAuditSheet, updateSheet } = props;
  const { onCommentClick, closeComment, height } = props;
  const { removeRows, view, sheet, scale = 1 } = props;

  // context
  const auditSheetContext = useContext(AuditSheetContext);

  const { industryCodes, getComments, currentUpload } = auditSheetContext;
  const { commentFlags, providerOptions } = auditSheetContext;

  //  common states
  const { highlightedColumn, setHilightedColumn } = auditSheetContext;
  const { dollarColumn, setDollorColumn } = auditSheetContext;
  const { auditSheetColumnsKeys, setAuditSheetColumnsKeys } = auditSheetContext;
  const { agreeDisAgreeCol, setAgreeDisAgreeCol } = auditSheetContext;

  //  common boleans
  const { isAuditedDollarDisabled, iaddState } = auditSheetContext;
  const { isProviderDollarDisabled, ipddState } = auditSheetContext;

  const [columnData, setColumnData] = useState([]);
  const [auditSheetHeaders, setAuditSheetHeaders] = useState([]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  // states
  const [sheetData, setSheetData] = useState([]);
  const [windowSize, setWindowSize] = useState(getWindowSize().innerWidth);
  const [localHeader, setLocalHeader] = useState(auditSheetHeaders);
  const [localColumnData, setLocalColumnData] = useState(columnData);
  const [numericColumns, setNumericColumns] = useState([]);
  const [responseColumn, setResponseColumn] = useState(null);

  // other hooks
  const toast = useToastr();

  useInterval(() => {
    //call update audit sheet
    if (!view) {
      updateAuditSheet();
    }
  }, 50000);

  registerAllModules(); // importing all modules in compoenent level

  // formatting date and assigning the renderer name ( object -> name )
  function setLocalSheetData(data) {
    if (!data) {
      setSheetData([]);
    }

    const nextSheetData = [];

    for (let i = 0; i < data.length; i++) {
      const datum = data[i];

      const renderingValue = datum.rendering;

      if (renderingValue) {
        const isObject =
          typeof renderingValue === 'object' &&
          Object.keys(renderingValue).length;

        if (isObject) {
          const renderName = toTitleCase(
            `${renderingValue.first_name} ${renderingValue.last_name}`
          );
          datum.rendering = renderName;

          datum.old_rendering = renderingValue;
        } else {
          const providerOption = providerOptions.find(
            p => p.id === renderingValue
          );

          if (providerOption) {
            datum.rendering = providerOption.value;
          }
        }
      }

      if (datum.enc_dt) {
        const d = Date.parse(datum.enc_dt);
        if (d) {
          datum.enc_dt = format(new Date(d), 'MM-dd-yyyy');
        }
      }
      nextSheetData.push(datum);
    }

    //  des-object and set to setSheetData(local)
    setSheetData(nextSheetData);
  }

  // lifeCycle Hooks
  React.useEffect(() => {
    const reDestructuredData = sheet.data;

    // spreading in object
    const _reDestructuredData = reDestructuredData.map(sd => {
      const tempObj = { ...sd, ...sd?.additional_attributes };

      delete tempObj.additional_attributes;

      return tempObj;
    });

    // -- preparing dynamic column headers --
    const auditSheetColumnsheaders =
      prepareAuditSheetHeader(_reDestructuredData);
    setAuditSheetHeaders(auditSheetColumnsheaders);

    // -- preparing dynamic auditsheetcolummn --
    const dynamicColumnData = prepareAuditSheetColumn(
      _reDestructuredData,
      windowSize,
      view,
      providerOptions,
      isAuditedDollarDisabled,
      isProviderDollarDisabled
    );

    if (!!dynamicColumnData.length) {
      setColumnData(dynamicColumnData);
    }
  }, [sheet]);

  React.useEffect(() => {
    setColumnData(localColumnData);
    setAuditSheetHeaders(localHeader);
  }, [localHeader, localColumnData]);

  React.useEffect(() => {
    const _hilghtedColumn = findHighlightedColumn(columnData);
    const _dollorColumn = findDollorColumn(columnData);
    const _auditSheetkey = getAuditSheetKeys(columnData);
    const _agreeDisagree = findAgreeDisAgreeColumn(columnData);
    const _numericColumn = findNumericColumns(columnData);
    const _responseColumn = findResponseColumnIndex(columnData);

    setResponseColumn(_responseColumn);
    setAuditSheetColumnsKeys([..._auditSheetkey]);
    setHilightedColumn([..._hilghtedColumn]);
    setDollorColumn([..._dollorColumn]);
    setAgreeDisAgreeCol([..._agreeDisagree]);
    setNumericColumns(_numericColumn);
  }, [columnData.length]);

  useEffect(() => {
    registerCustomCells({ scale });
  }, [scale]);

  useEffect(() => {
    // when sheet data changes it will destructure rendering , enc_dt values update in sheetdata(state)
    if (sheet?.data) {
      setLocalSheetData(sheet.data);
    }

    // sheet is empty , remove additional columns

    const isNewSheet = isEmptySheet(sheet.data);

    const preFilledObject = emptyDataPrefilledObject(currentUpload);

    if (isNewSheet && Object.keys(currentUpload).length) {
      const preFilledEmptyObject = sheet.data.map((s, i) => {
        if (i === 0) {
          return preFilledObject;
        } else {
          return s;
        }
      });
      updateSheet(preFilledEmptyObject);

      const auditSheetColumnsheaders =
        prepareAuditSheetHeader(preFilledEmptyObject);
      setAuditSheetHeaders(auditSheetColumnsheaders);

      //  -- preparing dynamic auditsheetcolummn --
      const dynamicColumnData = prepareAuditSheetColumn(
        preFilledEmptyObject,
        windowSize,
        view,
        providerOptions,
        isAuditedDollarDisabled,
        isProviderDollarDisabled
      );

      if (!!dynamicColumnData.length) {
        setColumnData(dynamicColumnData);
      }
    }
  }, [sheet?.data, currentUpload]);

  useEffect(() => {
    const hot = hotRef.current.hotInstance;

    if (
      Object.keys(auditSheetContext.selectedCell).length &&
      sheetData.length
    ) {
      const { row, col } = auditSheetContext.selectedCell;
      const index = sheetData.findIndex(d => d.id === row);
      if (index !== -1) {
        hot.selectCell(index, col);
      }
    } else {
      hot.deselectCell();
    }
  }, [auditSheetContext.selectedCell]);

  function columnHighlight(
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties
  ) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    td.style.background = AppColors.lightPink;
    td.style.fontSize = getScaledRowFontSize(scale);
    td.style.textAlign = 'center';
  }

  function commentCell(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    td.className = `htCommentCell`;
    td.style.fontSize = getScaledRowFontSize(scale);
    td.style.textAlign = col !== RENDERING_AS_COL && 'center';
    const COL = highlightedColumn; // [6, 7, 15, 16];

    if (COL.includes(col)) {
      td.style.background = AppColors.lightPink;
    }
    if (agreeDisAgreeCol.includes(col)) {
      // AGREE_DISAGREE_COL
      td.innerHTML = value === true ? 'Yes' : '-';
    }
    if (dollarColumn.includes(col)) {
      //DOLLAR_COL
      td.innerHTML = value ? `${value}` : null; //$
    }
  }

  function updateSheetData(nextSheetData, row, rowIndex) {
    if (rowIndex != null) {
      nextSheetData[rowIndex] = {
        ...nextSheetData[rowIndex],
        ...row,
        row_id: null,
        file_name: currentUpload.upload_id,
        chart_id: currentUpload.id,
      };
    } else {
      nextSheetData.push({ ...row, id: null });
    }
    return nextSheetData;
  }

  function srvcHandler(
    newValue,
    columnName,
    nextSheetData,
    isNewRow,
    rowIndex
  ) {
    if (newValue === '-') {
      const row = {
        [columnName]: '',
        audited_dollar_value: industryCodes[newValue],
      };
      nextSheetData = updateSheetData(
        nextSheetData,
        row,
        isNewRow ? null : rowIndex
      );
      iaddState.on();
    } else if (
      Object.keys(industryCodes).length &&
      Object.keys(industryCodes).includes(newValue)
    ) {
      const row = {
        [columnName]: newValue,
        provider_dollar_value: industryCodes[newValue],
      };

      nextSheetData = updateSheetData(
        nextSheetData,
        row,
        isNewRow ? null : rowIndex
      );
      ipddState.on();
    } else {
      toast.showError({
        description: 'This Billed CPT is not available.',
      });
      const row = {
        srvcs_no: null,
        provider_dollar_value: null,
      };
      nextSheetData = updateSheetData(
        nextSheetData,
        row,
        isNewRow ? null : rowIndex
      );
    }
  }

  function auditCodeHandler(
    newValue,
    columnName,
    nextSheetData,
    isNewRow,
    rowIndex
  ) {
    if (newValue === '-') {
      const row = {
        [columnName]: '',
        audited_dollar_value: industryCodes[newValue],
      };
      nextSheetData = updateSheetData(
        nextSheetData,
        row,
        isNewRow ? null : rowIndex
      );
      iaddState.on();
    } else if (
      Object.keys(industryCodes).length &&
      Object.keys(industryCodes).includes(newValue)
    ) {
      const row = {
        [columnName]: newValue,
        audited_dollar_value: industryCodes[newValue],
      };
      nextSheetData = updateSheetData(
        nextSheetData,
        row,
        isNewRow ? null : rowIndex
      );
      iaddState.on();
    } else {
      toast.showError({
        description: 'This Audited CPT is not available.',
      });
      const row = {
        audited_cpt: null,
        audited_dollar_value: null,
      };
      nextSheetData = updateSheetData(
        nextSheetData,
        row,
        isNewRow ? null : rowIndex
      );
    }
  }

  function agreeHandler(
    newValue,
    columnName,
    nextSheetData,
    rowIndex,
    isNewRow
  ) {
    const row = {
      ...nextSheetData[rowIndex],
      [columnName]:
        newValue === ''
          ? null
          : newValue?.toLowerCase() === 'yes' ||
            newValue?.toLowerCase() === 'true'
          ? true
          : false,
      disagree:
        newValue === ''
          ? null
          : newValue?.toLowerCase() === 'yes' ||
            newValue?.toLowerCase() === 'true'
          ? false
          : true,
    };

    const isAgreee = row.agree === true && row.disAgree === false;

    nextSheetData = updateSheetData(
      nextSheetData,
      row,
      isNewRow ? null : rowIndex
    );

    const valueInLowerase = newValue?.toLowerCase();

    if (valueInLowerase === 'yes' || valueInLowerase === 'true' || isAgreee) {
      nextSheetData[rowIndex].audited_code = nextSheetData[rowIndex].srvcs_no;
      nextSheetData[rowIndex].audited_cpt = nextSheetData[rowIndex].srvcs_no;
      nextSheetData[rowIndex].audited_rvu =
        nextSheetData[rowIndex].provider_rvu;
      nextSheetData[rowIndex].audited_dollar_value =
        nextSheetData[rowIndex].provider_dollar_value;
    }

    if (valueInLowerase === 'no') {
      nextSheetData[rowIndex].audited_code = null;
      nextSheetData[rowIndex].audited_cpt = null;
      nextSheetData[rowIndex].audited_rvu = null;
      nextSheetData[rowIndex].audited_dollar_value = null;
    }
  }

  function addAuditedColumn(changes, nextSheetData, isAgree, disagree) {
    // getting audutedcpt column index
    const audutedCptColumnIndex = columnData.reduce((acc, col, i) => {
      if (col.data === 'audited_cpt') {
        acc = i;
      }

      return acc;
    }, null);

    // isempty column
    const isEmptyColumn = hotRef.current?.hotInstance?.isEmptyCol(
      audutedCptColumnIndex
    );

    // rowIndex===0
    const addAuditCptColumn = isAgree && isEmptyColumn;

    let existingHeaders = auditSheetHeaders;
    let existingColumnData = columnData;

    const newColumnData = {
      data: 'audited_cpt_2',
      type: 'text',
      width: windowSize * 0.15,
      readOnly: view,
      renderer: 'customStylesRenderer',
    };

    if (addAuditCptColumn) {
      existingHeaders.splice(audutedCptColumnIndex + 1, 0, 'Audited CPT 2');

      existingColumnData.splice(audutedCptColumnIndex + 1, 0, newColumnData);

      setLocalHeader(existingHeaders);
      setLocalColumnData(existingColumnData);
    }

    nextSheetData = nextSheetData.map(nsd => {
      if (nsd.id) {
        return {
          ...nsd,
          [newColumnData.data]: null,
        };
      } else {
        return nsd;
      }
    });
  }

  function disAgreeHandler(
    newValue,
    columnName,
    nextSheetData,
    isNewRow,
    rowIndex
  ) {
    const disagreeRow = {
      [columnName]:
        newValue === ''
          ? null
          : newValue?.toLowerCase() === 'yes' ||
            newValue?.toLowerCase() === 'true'
          ? true
          : false,
      agree:
        newValue === ''
          ? null
          : newValue?.toLowerCase() === 'yes' ||
            newValue?.toLowerCase() === 'true'
          ? false
          : true,
    };

    const isDisagree =
      disagreeRow.disagree === true && disagreeRow.agree === false;

    nextSheetData = updateSheetData(
      nextSheetData,
      disagreeRow,
      isNewRow ? null : rowIndex
    );

    const valueInLowerase = newValue?.toLowerCase();

    if (valueInLowerase === 'yes' || valueInLowerase === 'true' || isDisagree) {
      nextSheetData[rowIndex].audited_code = null;
      nextSheetData[rowIndex].audited_cpt = null;
      nextSheetData[rowIndex].audited_rvu = null;
      nextSheetData[rowIndex].audited_dollar_value = null;
    }

    if (valueInLowerase === 'no') {
      nextSheetData[rowIndex].audited_code = nextSheetData[rowIndex].srvcs_no;
      nextSheetData[rowIndex].audited_cpt = nextSheetData[rowIndex].srvcs_no;
      nextSheetData[rowIndex].audited_rvu =
        nextSheetData[rowIndex].provider_rvu;
      nextSheetData[rowIndex].audited_dollar_value =
        nextSheetData[rowIndex].provider_dollar_value;
    }
  }

  function encdtHandler(
    newValue,
    columnName,
    nextSheetData,
    isNewRow,
    rowIndex
  ) {
    let data = newValue;
    if (data) {
      const d = Date.parse(data);
      if (d) {
        const encDtRow1 = {
          [columnName]: format(new Date(d), 'MM-dd-yyyy'),
        };
        nextSheetData = updateSheetData(
          nextSheetData,
          encDtRow1,
          isNewRow ? null : rowIndex
        );
      } else {
        toast.showError({ description: 'Please, enter a valid date' });
      }
    } else {
      const encDtRow1 = {
        [columnName]: null,
      };
      nextSheetData = updateSheetData(
        nextSheetData,
        encDtRow1,
        isNewRow ? null : newValue
      );
    }
  }

  function renderingHandler(
    newValue,
    columnName,
    nextSheetData,
    isNewRow,
    rowIndex
  ) {
    const obj = providerOptions.find(
      p => p.value.toLowerCase() === newValue.toLowerCase()
    );
    let renderingData = {
      [columnName]: null,
    };
    if (obj) {
      renderingData = {
        [columnName]: newValue,
      };
    } else {
      toast.showError({
        description: 'This Rendering value is not available.',
      });
    }
    nextSheetData = updateSheetData(
      nextSheetData,
      renderingData,
      isNewRow ? null : rowIndex
    );
  }

  function defaultColumnHandler(
    newValue,
    columnName,
    nextSheetData,
    isNewRow,
    rowIndex
  ) {
    const defaultRow = {
      [columnName]: newValue === '' ? null : newValue,
    };
    nextSheetData = updateSheetData(
      nextSheetData,
      defaultRow,
      isNewRow ? null : rowIndex
    );
  }

  function onBeforeHotChange(change) {
    let nextSheetData = [...sheetData];

    change.map(changes => {
      const rowIndex = changes[0];
      const columnName = changes[1];
      const oldValue = changes[2];
      const newValue = changes[3];

      const isNewRow = rowIndex >= sheetData.length;
      switch (columnName) {
        case AUDIT_COLUMN_HEADER.SRVCS_NO: // billed code
          srvcHandler(newValue, columnName, nextSheetData, isNewRow, rowIndex);

          break;
        case AUDIT_COLUMN_HEADER.AUDITED_CPT:
          auditCodeHandler(
            newValue,
            columnName,
            nextSheetData,
            isNewRow,
            rowIndex
          );

          break;
        case AUDIT_COLUMN_HEADER.AGREE:
          agreeHandler(newValue, columnName, nextSheetData, rowIndex, isNewRow);

          break;
        case AUDIT_COLUMN_HEADER.DISAGREE:
          disAgreeHandler(
            newValue,
            columnName,
            nextSheetData,
            isNewRow,
            rowIndex
          );

          break;
        case AUDIT_COLUMN_HEADER.ENC_DT:
          encdtHandler(newValue, columnName, nextSheetData, isNewRow, rowIndex);

          break;
        case AUDIT_COLUMN_HEADER.RENDERING:
          renderingHandler(
            newValue,
            columnName,
            nextSheetData,
            isNewRow,
            rowIndex
          );

          break;
        default:
          defaultColumnHandler(
            newValue,
            columnName,
            nextSheetData,
            isNewRow,
            rowIndex
          );
          break;
      }
    });

    const _change = change[0];
    const [rowIndex, columnName, oldValue, newValue] = _change;

    const isAgree = nextSheetData[rowIndex].agree;
    const isNotDisAgree = nextSheetData[rowIndex].disagree;

    if (isAgree) {
      addAuditedColumn(change, nextSheetData, isAgree, isNotDisAgree);
    }

    const hasAddedColumn = addColumn(change, nextSheetData);

    const isAdded = hasAddedColumn && Object.keys(hasAddedColumn).length;

    if (isAdded) {
      nextSheetData = nextSheetData.map(nsd => {
        if (nsd.id) {
          return {
            ...nsd,
            [hasAddedColumn.data]: null,
          };
        } else {
          return nsd;
        }
      });
    }

    setLocalSheetData(nextSheetData);

    const isNewRow = change.some(d => {
      return !sheetData[d[0]].id;
    });

    //update audit sheet on first cell update in a row
    if (isNewRow) {
      // for new row  / id is null
      updateAuditSheet({ ...sheet, data: nextSheetData });
    } else {
      updateSheet(nextSheetData);
    }

    return false;
  }

  function hasComment(row, col) {
    if (row < sheetData?.length) {
      const colData = auditSheetColumnsKeys[col]; //AUDIT_SHEET_KEY
      const rowId = sheetData[row]?.id;
      if (rowId && commentFlags && Object.keys(commentFlags).length) {
        if (commentFlags[rowId]?.includes(colData)) {
          return true;
        }
      }
    }
    return false;
  }

  function columnHeaders() {
    // auditSheetHeaders
    return auditSheetHeaders.map((header, i) => {
      const fontSize = getScaledHeaderFontSize(scale);
      return `<div style="font-size: ${fontSize};"class="header">${header}</div>`;
    });
  }

  // context options
  function contextOption() {
    return {
      async callback(key, selection, clickEvent) {
        // Common callback for all options
        onCommentClick(
          selection,
          { pageX: clickEvent.screenX, pageY: clickEvent.screenY },
          key === 'comments',
          false
        );

        if (key === 'comments') {
          const { row, column } = getRowColArray(selection[0], sheet);
          await getComments(uploadId, {
            row,
            column,
          });
        }
      },
      items: {
        row_above: {
          name: () => '<p>Insert row above</p>',
          disabled() {
            return view;
          },
        },

        row_below: {
          disabled() {
            return view;
          },
        },

        remove_row: {
          callback:async function (key, selection) {
            const { rows } = getRowColIndex(selection[0], sheet);
            removeRows(rows);
            await updateAuditSheet()
          },

          disabled() {
            return view;
          },
        },

        cut: {
          disabled() {
            return view;
          },
        },

        copy: {
          disabled() {
            return view;
          },
        },

        // undo: {
        //   disabled() {
        //     return view;
        //   },
        // },

        comments: {
          name: 'Comments',
          hidden() {
            // `hidden` can be a boolean or a function
            // Hide the option when the first column was clicked
            const selectedIndex = this.getSelectedLast()[2];
            return !sheetData[selectedIndex].id; // `this` === hot
          },
        },
      },
    };
  }

  // onKeyPress
  function onKeyPress(e) {
    // column number
    let col = this.getSelectedLast()[1];

    var evt = e || window.event; // IE support
    var key = evt.charCode || evt.keyCode || 0;

    // check for cut and paste
    var isClipboard = false;
    var ctrlDown = evt.ctrlKey || evt.metaKey; // Mac support

    // Check for Alt+Gr (http://en.wikipedia.org/wiki/AltGr_key)
    if (ctrlDown && evt.altKey) isClipboard = false;
    // Check for ctrl+c, v and x
    else if (ctrlDown && key === 67) isClipboard = true; // c
    else if (ctrlDown && key === 86) isClipboard = true; // v
    else if (ctrlDown && key === 88) isClipboard = true; // x
    else if (ctrlDown && evt.key === 'z') isClipboard = true;
    const isNumeric =
      NUMERIC_KEYS.includes(key) ||
      (key >= 35 && key <= 40) ||
      (key >= 48 && key <= 57) ||
      (key >= 96 && key <= 105);

    switch (col) {
      case 0:
        if ((!isNumeric && !isClipboard) || e.shiftKey) {
          // prevent alpha characters
          e.stopImmediatePropagation();
          e.preventDefault();
        }
        break;
      case 2:
        if ((!isNumeric && !isClipboard) || e.shiftKey) {
          // prevent alpha characters
          e.stopImmediatePropagation();
          e.preventDefault();
        }
        break;
      default:
        break;
    }

    numericColumns.forEach(nc => {
      switch (col) {
        case nc:
          if ((!isNumeric && !isClipboard) || e.shiftKey) {
            // prevent alpha characters
            e.stopImmediatePropagation();
            e.preventDefault();
          }
          break;
        default:
          break;
      }
    });
  }

  // onMouseClick on cell
  function _afterOnCellMouseDown(e, p) {
    // e - event , p - cellCords[ row , column ]
    closeComment();

    const rowId = sheetData[p.row]?.id;

    if (p.col === responseColumn && rowId) {
      onCommentClick(
        [
          {
            start: { row: p.row, col: p.col },
            end: { row: p.row, col: p.col },
          },
        ],
        { pageX: e.screenX + 100, pageY: e.screenY + 100 },
        false,
        true
      );
    } else {
      onCommentClick(
        [
          {
            start: { row: p.row, col: p.col },
            end: { row: p.row, col: p.col },
          },
        ],
        { pageX: e.screenX + 100, pageY: e.screenY + 100 },
        false,
        false
      );
    }
  }

  function cellProps(row, col) {
    var cellProperties = {};

    const COL = highlightedColumn ?? [6, 14];
    if (COL.includes(col)) {
      cellProperties.renderer = columnHighlight; // uses lookup map
    }

    if (hasComment(row, col)) {
      cellProperties.renderer = commentCell; // uses comment map
    }

    return cellProperties;
  }

  function onPaste(data, coords) {
    const [startRow, startCol] = [coords[0].startRow, coords[0].startCol];
    const actualData = data[0];

    const rvuIndices = columnData.findIndex(col => col.data === 'provider_rvu');

    // Check if the destination column is 'enc_dt'
    if (startCol === rvuIndices) {
      // Check if any of the pasted values is not a number
      if (actualData.some(value => isNaN(+value))) {
        return false;
      }
    }

    // Continue with the paste operation for other columns or valid numeric values
    return true;
  }

  // table settings
  /** @type {import('handsontable/settings').GridSettings} */

  const hotSettings = {
    //sheet data
    data: sheetData,

    height: height,

    // sheet column header
    colHeaders: columnHeaders(),

    //  right-click or context menu
    contextMenu: contextOption(),

    minSpareRows: 10,

    licenseKey: 'non-commercial-and-evaluation',

    //beforeChange when onChange returns [ columnIndex, columnName,oldValue, newValue ]
    beforeChange: onBeforeHotChange,

    beforeKeyDown: onKeyPress,

    outsideClickDeselects: false,

    afterOnCellMouseDown: _afterOnCellMouseDown,

    cells: cellProps,

    // onpaste

    beforePaste: onPaste,

    // hide columns
    // hiddenColumns:{
    //   columns:[0]
    // }
  };

  const hotTableDefaultSettings = {
    ...hotSettings,
    rowHeights: getScaledRowHeight(scale),
    stretchH: 'all',
    autoColumnSize: false,
    autoRowSize: false,
    wordWrap: true,
  };

  function addColumn(changes, nextSheetData) {
    let haveValue = null;

    for (const _change of changes) {
      const [_rowId, _columnName, _oldValue, _newValue] = _change;
      if (_newValue) {
        haveValue = true;
        break;
      }
    }

    const [row, column, oldValue, newValue] = changes[0];

    const _selectedIndex = columnData.reduce((acc, cd, i) => {
      if (cd.data === column) {
        acc = i;
      }
      return acc;
    }, null);

    // const [rowIndex, selectedColumnIndex] =
    //   hotRef.current?.hotInstance.getSelected()[0];

    const selectedColumnIndex = _selectedIndex;

    const isEmptyColumn = hotRef.current?.hotInstance?.isEmptyCol(
      changes[0][1]
    );

    const newColumnHeader = auditSheetHeaders[_selectedIndex];

    const _newColumnHeader = creatColumnHeader(newColumnHeader);

    const newColumnName = createColumnDataName(column);

    const newColumnData = {
      data: newColumnName,
      type: 'text',
      width: windowSize * 0.15,
      readOnly: view,
      renderer: 'customStylesRenderer',
    };

    let existingHeaders = auditSheetHeaders;
    let existingColumnData = columnData;

    const colAlreadyExist = nextSheetData.find(cd => {
      const hasAreadyxist = cd.hasOwnProperty(newColumnName);
      if (hasAreadyxist && cd.id) {
        return cd;
      }
    });

    function _addColumn() {
      const canAddColumn = isColumnMatched(column, dynamicColumnKeywords);

      if (canAddColumn) {
        existingHeaders.splice(selectedColumnIndex + 1, 0, _newColumnHeader);

        existingColumnData.splice(selectedColumnIndex + 1, 0, newColumnData);

        setLocalHeader(existingHeaders);
        setLocalColumnData(existingColumnData);

        return newColumnData;
      }

      return null;
    }

    if (isEmptyColumn && haveValue && !colAlreadyExist) {
      return _addColumn();
    }
  }

  return (
    <HotTable
      ref={hotRef}
      columns={columnData}
      // colHeaders={true}
      data={sheetData} //arrangeData(dummySheetData)
      // undo={true}
      settings={{ ...hotTableDefaultSettings, manualColumnResize: true }}
    />
  );
};
export default AuditSheet;
