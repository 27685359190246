import { useBoolean } from '@prasanna-transcend/code-quick-components';
import React from 'react';
import NotesActivityDrawer from './notes_activities/NotesActivityDrawer';
import { NoteIcon } from '../../../constants/IconData';

function NotesDrawer({onOpenNotes}) {
  const [isOpenNotes, ionState] = useBoolean(false);

  return (
    <>
      <NoteIcon
        style={{
          width: 40,
          height: 40,
          cursor: 'pointer',
          display: 'inline-block',
        }}
        onClick={() => {
          ionState.on()
          onOpenNotes()
        }}
      />
      <NotesActivityDrawer isOpen={isOpenNotes} onClose={ionState.off} onOpenNotes={onOpenNotes}/>
    </>
  );
}

export default NotesDrawer;
