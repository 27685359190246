import { ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import CQManagerPortalApp from './CQManagerPortalApp';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import 'react-perfect-scrollbar/dist/css/styles.css';

const container = document.getElementById('cq-manager-portal');
const root = ReactDOM.createRoot(container);

root.render(
  <StrictMode>
    <ColorModeScript />
    <CQManagerPortalApp />
  </StrictMode>
);

serviceWorker.unregister();
reportWebVitals();
