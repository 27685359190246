import { FormTextInput } from '@prasanna-transcend/code-quick-components';
import React, { useRef, useState } from 'react';
import { EditIcon, PersonIcon } from '../../../constants/IconData';

const EDIT_TEXT_FIELD = 'EDIT_INPUT';
const SHOW = 'DISABLE_INPUT';
function FormInputField(props) {
  const ref = useRef(null);
  const [mode, setMode] = useState(SHOW);
  let editInput = async () => {
    await setMode(EDIT_TEXT_FIELD);
    ref.current.focus();
  };
  return (
    <>
      <FormTextInput
        ref={ref}
        height={'50px'}
        name={props.name}
        control={props.control}
        id={props.id}
        style={{ opacity: '1' }}
        disabled={mode === SHOW}
        rightIcon={
          props.rightIcon || (
            <EditIcon
              id={`edit-icon-first_name`}
              onClick={() => {
                editInput();
              }}
              style={{ cursor: 'pointer',marginTop:'10px'}}
            />
          )
        }
        leftIcon={props.leftIcon || <PersonIcon  style={{ cursor: 'pointer',marginTop:'10px' }}  />}
        size="md"
        {...props}
      />
    </>
  );
}

export default FormInputField;
