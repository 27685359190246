/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Grid,
  GridItem,
  HStack,
  Text,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, NavLink, useLocation, useParams } from 'react-router-dom';
import CQContainer from '../../components/layout/CQContainer';
import AppColors from '../../constants/AppColors';
import { BackArrowIcon } from '../../constants/IconData';
import ROUTES from '../../constants/Routes';
import { FONT_FAMILY } from '../../constants/Theme';
import accountsRepository from '../../repositories/AccountsRepository';
import { getHeaderCellProps } from '../../utils/assigned_audit.util';
import { replaceRoute } from '../../utils/common.utils';
import AccountsChartSection from './sections/AccountsChartSection';
import AuditAccuracySection from './sections/AuditAccuracySection';
import MembersSection from './sections/MembersSection';
import MetricSection from './sections/MetricSection';
import StatisticsSection from './sections/StatisticsSection';
import SubCollectionSection from './sections/SubCollectionSection';

const columnHelper = createColumnHelper();

const DepartmentViewContainer = props => {
  const params = useParams();
  const location = useLocation();
  const { accountId, hospitalId, departmentId } = params;

  const [isFetchingPhysicians, ifpState] = useBoolean(false);

  const [physicians, setPhysicians] = useState([]);

  const queryParam = useMemo(() => ({ department_id: departmentId }), []);

  async function fetchPhysicians() {
    try {
      ifpState.on();
      const response = await accountsRepository.getAccountProviders(accountId);
      setPhysicians(response);
    } catch (e) {
    } finally {
      ifpState.off();
    }
  }

  useEffect(() => {
    fetchPhysicians();
  }, []);

  // department columns
  const physicianColumns = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        header: () => 'Physician Name',
        enableSorting: false,
      }),
      columnHelper.accessor('active_audits', {
        header: () => 'Uploads',
        enableSorting: false,
      }),
      columnHelper.accessor('chart_accuracy', {
        header: () => 'RI Industry',
        enableSorting: false,
        cell: info => {
          if (info.row.original.chart_accuracy) {
            return info.row.original.chart_accuracy;
          } else {
            return 'N/A';
          }
        },
      }),
      columnHelper.accessor('cq_rev_opp', {
        header: () => 'RI Link',
        enableSorting: false,
      }),
      columnHelper.accessor('cq_rev_opp', {
        header: () => 'Organization Rank',
        enableSorting: false,
      }),
      columnHelper.accessor('actions', {
        header: () => 'Actions',
        enableSorting: false,
        cell: info => {
          const physicianId = info.row.original.id ?? 1;
          const accountId = params.accountId;

          const navigationRoute = replaceRoute(ROUTES.GET_ACCOUNT_PHYSICIAN, {
            hospitalId,
            accountId,
            departmentId,
            physicianId,
          });

          return (
            <Link
              to={navigationRoute}
              state={{
                accountName: location.state?.accountName,
                departmentName: location.state?.departmentName,
                hospitalName: location.state?.hospitalName,
                physicianName: info.row.original?.name,
              }}
            >
              <Button
                size={'md'}
                px={16}
                borderRadius={'full'}
                colorScheme={'secondary'}
              >
                View
              </Button>
            </Link>
          );
        },
      }),
    ];
  }, []);

  // columns
  const statisticRows = [
    {
      label: 'Total Audits',
      accessor: 'total_audits',
    },
    {
      label: 'In Progress',
      accessor: 'in_progress',
    },
    {
      label: 'Completed',
      accessor: 'completed',
    },
    {
      label: 'Open Rebuttals',
      accessor: 'open_rebuttals',
    },
  ];

  function _getHeaderCellProps(header) {
    return {
      ...getHeaderCellProps(header),
      justifyContent: header.id === 'name' ? 'flex-start' : 'center',
    };
  }

  function _getCellProps(row, cell) {
    const isName = cell.column.id === 'name';
    const textAlign = isName ? 'left' : 'center';
    const textTransform = isName ? 'capitalize' : 'none';

    return {
      fontSize: 'md',
      textAlign,
      textTransform,
      style: {
        fontWeight: 'bold',
        color: AppColors.secondary,
      },
    };
  }

  function renderBreadCrumb() {
    const accountViewRoute = replaceRoute(ROUTES.GET_ACCOUNT, {
      accountId: accountId,
    });
    const hospitalViewRoute = replaceRoute(ROUTES.GET_ACCOUNT_HOSPITAL, {
      accountId,
      hospitalId,
    });

    return (
      <Breadcrumb>
        <BreadcrumbItem>
          <NavLink to={ROUTES.ACCOUNTS}>
            <Text
              fontFamily={FONT_FAMILY.baiJamurjee}
              fontWeight="bold"
              fontSize={'2xl'}
            >
              Accounts
            </Text>
          </NavLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <NavLink to={accountViewRoute} state={location.state}>
            <Text fontFamily={FONT_FAMILY.baiJamurjee}>
              {location.state?.accountName}
            </Text>
          </NavLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <NavLink to={hospitalViewRoute} state={location.state}>
            <Text textTransform={'capitalize'}>
              {location.state?.hospitalName}
            </Text>
          </NavLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <Text textTransform={'capitalize'}>
            {location.state?.departmentName}
          </Text>
        </BreadcrumbItem>
      </Breadcrumb>
    );
  }

  function renderBackToAccounts() {
    return (
      // Without HStack, this NavLink is occupying entire width
      <HStack>
        <NavLink to={ROUTES.ACCOUNTS}>
          <Box>
            <HStack my={2} spacing={'md'}>
              <BackArrowIcon
                style={{ width: 40, height: 40, cursor: 'pointer' }}
              />
              <Text
                fontSize={'md'}
                fontWeight="bold"
                size={'md'}
                mx={2}
                fontFamily={FONT_FAMILY.openSans}
                color={AppColors.secondary}
              >
                Back to All Accounts
              </Text>
            </HStack>
          </Box>
        </NavLink>
      </HStack>
    );
  }

  function renderStatistics() {
    return (
      <GridItem bgColor={'#fff'} p={8} borderRadius={'lg'} shadow={'md'}>
        <StatisticsSection
          rows={statisticRows}
          sectionTitle={'Department Statistics'}
          queryParam={queryParam}
        />
      </GridItem>
    );
  }

  function renderAuditAccuracy() {
    return (
      <GridItem bgColor={'#fff'} p={8} borderRadius={'lg'} shadow={'md'}>
        <AuditAccuracySection queryParam={queryParam} />
      </GridItem>
    );
  }

  function renderMetric() {
    return (
      <GridItem bgColor={'#fff'} p={8} borderRadius={'lg'} shadow={'md'}>
        <MetricSection queryParam={queryParam} />
      </GridItem>
    );
  }

  function renderMembers() {
    return (
      <GridItem
        bgColor={'#fff'}
        p={8}
        borderRadius={'lg'}
        shadow={'md'}
      >
        <MembersSection accountId={accountId} queryParam={queryParam} />
      </GridItem>
    );
  }

  function renderPhysicians() {
    return (
      <SubCollectionSection
        sectionTitle={'Physicians'}
        columns={physicianColumns}
        data={physicians}
        isLoading={isFetchingPhysicians}
        tableProps={{
          getCellProps: _getCellProps,
          getHeaderCellProps: _getHeaderCellProps,
        }}
      />
    );
  }

  function renderTable() {
    return (
      <GridItem colSpan={2}>
        <VStack align={'stretch'}>
          {renderPhysicians()}
          <Box my={4} />
          <AccountsChartSection queryParam={queryParam} />
        </VStack>
      </GridItem>
    );
  }

  function renderMainContent() {
    return (
      <Box>
        <Grid templateColumns="repeat(3, 1fr)" mt={4} gap={4}>
          {renderStatistics()}
          {renderAuditAccuracy()}
          {renderMetric()}
        </Grid>

        <Box my={4} />

        <Grid templateColumns="repeat(3, 1fr)" gap={4}>
          {renderMembers()}
          {renderTable()}
        </Grid>
      </Box>
    );
  }

  return (
    <CQContainer>
      {renderBreadCrumb()}
      {renderBackToAccounts()}
      {renderMainContent()}
    </CQContainer>
  );
};

export default DepartmentViewContainer;
