import {
  useBoolean,
  useToastr,
} from '@prasanna-transcend/code-quick-components';
import { format } from 'date-fns';
import { createContext, useState } from 'react';
import reportRepository from '../repositories/ReportRepository';

export const ReportContext = createContext({
  getWeekWiseErrorChart: async () => {},
  getWeekWiseErrorTable: async () => {},
  getWeekdaysAccuracyChart: async () => {},
  getWeekWiseCQAccuracyTable: async () => {},
  getErrorParameterDropdown: async () => {},
  getAuditorTableData: async () => {},
  getAuditorDropdown: async () => {},

  isWeekErrorChartLoading: false,
  isWeekErrorTableLoading: false,
  isWeekAccuracyChartLoading: false,
  isWeekAccuracyTableLoading: false,
  isAuditorTableLoading: false,

  weekErrorChartData: {},
  weekErrorTableData: [],
  weekAccuracyChartData: {},
  weekAccuracyTableData: [],
  errorOptions: [],

  auditorTableData: [],
  auditorOptions: [],
  currentMonth: '',

  getHealthSystems: async () => {},
  getHospitals: async () => {},
  getSpecialties: async () => {},

  healthSystems: [],
  hospitals: [],
  specialties: [],
  updateHospitalOptions: async () => {},
  updateSpecialtiesOptions: async () => {},
});

const ReportProvider = props => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  const month = format(date, 'MMM');
  const year = format(date, 'yy');
  const currentMonth = `${month} ’${year}`;
  const toast = useToastr();
  const [isWeekErrorTableLoading, iwetState] = useBoolean(false);
  const [weekErrorTableData, setWeekErrorTableData] = useState([]);
  const [isWeekErrorChartLoading, iwecState] = useBoolean(false);
  const [weekErrorChartData, setWeekErrorChartData] = useState({});
  const [isWeekAccuracyChartLoading, iwacState] = useBoolean(false);
  const [weekAccuracyChartData, setWeekAccuracyChartData] = useState({});
  const [isWeekAccuracyTableLoading, iwatState] = useBoolean(false);
  const [weekAccuracyTableData, setWeekAccuracyTableData] = useState([]);
  const [errorOptions, setErrorOptions] = useState([]);
  const [isAuditorTableLoading, iatState] = useBoolean(false);
  const [auditorTableData, setAuditorTableData] = useState([]);
  const [auditorOptions, setAuditorOptions] = useState([]);
  const [healthSystems, setHealthSystems] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [specialties, setSpecialties] = useState([]);

  const getWeekWiseErrorChart = async query => {
    try {
      iwecState.on();
      const response = await reportRepository.getWeekWiseError({
        graph: 'True',
        ...query,
      });
      setWeekErrorChartData(response);
    } catch (e) {
      setWeekErrorChartData({});
      return e.message;
    } finally {
      iwecState.off();
    }
  };

  const getWeekWiseErrorTable = async filterParams => {
    try {
      iwetState.on();
      const response = await reportRepository.getWeekWiseErrorTrend(
        filterParams
      );
      setWeekErrorTableData(response);
    } catch (e) {
      setWeekErrorTableData([]);
      return e.message;
    } finally {
      iwetState.off();
    }
  };

  const getWeekdaysAccuracyChart = async query => {
    try {
      iwacState.on();
      const response = await reportRepository.getWeekdaysAccuracyChart(query);
      setWeekAccuracyChartData(response);
    } catch (e) {
      setWeekAccuracyChartData({});
      return e.message;
    } finally {
      iwacState.off();
    }
  };

  const getWeekWiseCQAccuracyTable = async query => {
    try {
      iwatState.on();
      const response = await reportRepository.getWeekWiseCQAccuracyError(query);
      setWeekAccuracyTableData(response);
    } catch (e) {
      setWeekAccuracyTableData([]);
      return e.message;
    } finally {
      iwatState.off();
    }
  };

  const getErrorParameterDropdown = async () => {
    try {
      const response = await reportRepository.getErrorDropdown();
      setErrorOptions(response);
    } catch (e) {
      return e.message;
    }
  };

  // Auditor Report
  const getAuditorTableData = async filterParams => {
    if (filterParams.auditor === '') {
      delete filterParams.auditor;
    }
    try {
      iatState.on();
      const response = await reportRepository.getAuditorQualityReport(
        filterParams
      );
      setAuditorTableData(response);
    } catch (e) {
      setAuditorTableData([]);
      return e.message;
    } finally {
      iatState.off();
    }
  };

  const getAuditorDropdown = async () => {
    try {
      const response = await reportRepository.getAuditorQualityDropdown();
      const temp = response.map(res => ({
        label: `${res.first_name} ${res.last_name}`,
        value: res.id,
      }));
      setAuditorOptions([...temp]);
    } catch (e) {
      return e.message;
    }
  };

  const getHealthSystems = async (params = {}) => {
    try {
      const response = await reportRepository.getHealthSystemDropdown(params);
      const temp = response.map(res => ({ label: res.name, value: res.id }));
      setHealthSystems([...temp]);
      return temp;
    } catch (e) {
      return e.message;
    }
  };

  const getHospitals = async params => {
    try {
      const response = await reportRepository.getHospitalDropdown(params);
      const temp = response.map(res => ({ label: res.name, value: res.id }));
      setHospitals([...temp]);
      return temp;
    } catch (e) {
      return e.message;
    }
  };

  const getSpecialties = async params => {
    try {
      const response = await reportRepository.getSpecialtyDropdown(params);
      const temp = response.map(res => ({ label: res.name, value: res.id }));
      setSpecialties([...temp]);
      return temp;
    } catch (e) {
      return e.message;
    }
  };

  const updateHospitalOptions = async params => {
    try {
      const response = await reportRepository.updateHospitalOption(params);
      const temp = response.map(res => ({ label: res.name, value: res.id }));
      setHospitals([...temp]);
      return temp;
    } catch (error) {
      toast.showError({ description: error.toString() });
    }
  };

  const updateSpecialtiesOptions = async params => {
    try {
      const response = await reportRepository.updateSpecialtiesOptions(params);
      const temp = response.map(res => ({ label: res.name, value: res.id }));
      setSpecialties([...temp]);
      return temp;
    } catch (error) {
      toast.showError({ description: error.toString() });
    }
  };

  const mContext = {
    getWeekWiseErrorChart,
    getWeekWiseErrorTable,
    getWeekdaysAccuracyChart,
    getWeekWiseCQAccuracyTable,
    getErrorParameterDropdown,
    getAuditorTableData,
    getAuditorDropdown,

    isWeekErrorChartLoading,
    isWeekErrorTableLoading,
    isWeekAccuracyChartLoading,
    isWeekAccuracyTableLoading,
    isAuditorTableLoading,

    weekErrorChartData,
    weekErrorTableData,
    weekAccuracyChartData,
    weekAccuracyTableData,
    errorOptions,

    auditorTableData,
    auditorOptions,
    currentMonth,

    getHealthSystems,
    getHospitals,
    getSpecialties,
    healthSystems,
    hospitals,
    specialties,
    updateHospitalOptions,
    updateSpecialtiesOptions,
  };

  return (
    <ReportContext.Provider value={mContext}>
      {props.children}
    </ReportContext.Provider>
  );
};
export default ReportProvider;
