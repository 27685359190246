import {
  Box,
  Button,
  Center,
  HStack,
  Progress,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import React, { useMemo, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { acceptStyle, baseStyle, focusedStyle, rejectStyle } from '../styles';
import {
  ClientSelectIcon,
  CrossIcon,
  PinIcon,
  RedCrossIcon,
  TickIcon,
  UploadIcon,
} from '../../../constants/IconData';
import AppColors from '../../../constants/AppColors';
import { components } from 'react-select';
import { UploadContext } from '../../../providers/ChartUploadProvider';
import { FONT_FAMILY } from '../../../constants/Theme';
import { CQSelect } from '@prasanna-transcend/code-quick-components';

const OfflineUpload = props => {
  const [clientId, setClientId] = useState('');
  const [errorText, setErrorText] = useState('');
  const {
    isSubmitting,
    uploadedCharts,
    setUploadedCharts,
    clientUsers,
    uploadCharts,
    deleteChart,
    submitUploadedCharts,
  } = useContext(UploadContext);
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        'text/doc': ['.doc', '.docx', '.pdf', '.xls', '.xlsx', '.hl7'],
      },
      useFsAccessApi: false,
      onDrop: async acceptedFiles => {
        // call upload chart here
        if (!clientId) {
          setErrorText('Select anyone client');
        } else {
          await uploadCharts(acceptedFiles, 0, clientId);
        }
      },
    });
  const submitCharts = async () => {
    await submitUploadedCharts(clientId);
  };
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const handleRemoveFile = async id => {
    await deleteChart(id);
  };
  const AddedUserIcon = props => {
    return (
      <components.Control {...props}>
        <span style={{ marginLeft: 5 }}>
          <ClientSelectIcon />
        </span>
        {props.children}
      </components.Control>
    );
  };

  const handleRemoveState = index => {
    const data = uploadedCharts.filter((chart, i) => index !== i);
    setUploadedCharts([...data]);
  };
  return (
    <Box py={10} px={10}>
      <Box>
        <Center>
          <Text
            fontSize={'30px'}
            fontFamily={FONT_FAMILY.baiJamurjee}
            fontWeight={500}
          >
            Upload Chart
          </Text>
        </Center>
        <Center>
          <Box
            borderRadius={10}
            boxShadow="0 0 10px 0 rgba(0,0,0,0.05)"
            minHeight="170px"
            width="60%"
            mt={10}
            bg={'white'}
          >
            <VStack m={8} alignSelf="center">
              <CQSelect
                options={clientUsers}
                variant="primary"
                isSearchable={false}
                placeholder={'Select Client'}
                onChange={e => {
                  setErrorText('');
                  setClientId(e.value);
                }}
                components={{
                  IndicatorSeparator: null,
                  Control: AddedUserIcon,
                }}
                styles={{
                  control: s => ({
                    ...s,
                    borderRadius: 7,
                    paddingRight: 10,
                    paddingLeft: 10,
                  }),
                }}
                errorText={errorText}
              />
            </VStack>
            <Box m={8} className="container" style={{ cursor: 'pointer' }}>
              <Box {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <HStack spacing="30px">
                  <UploadIcon />
                  <Box ml={5}>
                    <HStack alignItems={'center'}>
                      <PinIcon />
                      <Text color={'black'} fontSize={'24px'}>
                        Add file or drop files here
                      </Text>
                    </HStack>
                    <HStack>
                      <Text color={'black'} fontSize={'24px'}>
                        Format :
                      </Text>
                      <Text color={'black'} fontSize={'24px'} fontWeight="bold">
                        {' '}
                        XLS, XLSX, PDF, DOC, DOCX, HL7
                      </Text>
                    </HStack>
                  </Box>
                </HStack>
              </Box>
            </Box>
            {uploadedCharts?.length ? (
              <PerfectScrollbar
                style={{
                  maxHeight: '200px',
                  margin: 35,
                  paddingRight: 10,
                  paddingBottom: 10,
                }}
              >
                {uploadedCharts.map((chart, index) => {
                  return (
                    <Box mt={3} mr={2}>
                      <HStack mb={2}>
                        <Text fontFamily={FONT_FAMILY.ptSans}>
                          {chart.name}
                        </Text>
                        <Spacer />
                        <Text fontFamily={FONT_FAMILY.ptSans}>
                          {chart.size}
                        </Text>
                        {chart.uploaded ? (
                          <CrossIcon
                            onClick={() => handleRemoveFile(chart.id)}
                            style={{ cursor: 'pointer' }}
                          />
                        ) : null}
                        {chart.uploaded ? <TickIcon /> : null}
                        {chart.cancelled ? (
                          <RedCrossIcon
                            onClick={() => handleRemoveState(index)}
                            style={{ cursor: 'pointer' }}
                          />
                        ) : null}
                      </HStack>
                      <Progress
                        value={chart.value}
                        size="xs"
                        colorScheme={chart.cancelled ? 'red' : 'teal'}
                        borderRadius={10}
                      />
                    </Box>
                  );
                })}
              </PerfectScrollbar>
            ) : null}
            {uploadedCharts?.length ? (
              <Center mb={8}>
                <Button
                  isLoading={isSubmitting}
                  onClick={() => submitCharts()}
                  variant="outline"
                  borderRadius={20}
                  borderWidth={2}
                  px={10}
                  borderColor={AppColors.secondary}
                  _hover={{
                    transition: 'all .1s ease',
                    bgColor: AppColors.white,
                    color: AppColors.secondary,
                    outline: `2px solid ${AppColors.secondary}`,
                  }}
                  disabled={
                    !(
                      uploadedCharts.every(chart => chart.id) &&
                      uploadedCharts.some(chart => chart.uploaded)
                    )
                  }
                >
                  Submit
                </Button>
              </Center>
            ) : null}
          </Box>
        </Center>
      </Box>
      <HStack display={'flex'} justifyContent={'center'} mt={2}>
        <Text fontSize={'14px'} fontFamily={FONT_FAMILY.ptSans}>
          By uploading files, you agree to our{' '}
        </Text>
        <Text
          fontSize={'14px'}
          fontFamily={FONT_FAMILY.ptSans}
          fontWeight="bold"
        >
          {' '}
          Terms of Service
        </Text>
      </HStack>
    </Box>
  );
};

export default OfflineUpload;
