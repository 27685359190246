// eslint-disable-next-line no-unused-vars
import { paginationReturnType } from '@prasanna-transcend/code-quick-components';
import AppColors from '../constants/AppColors';
import {
  DownIcon,
  SortIcon,
  UpIcon,
  RightIcon,
  LeftIcon,
  LeftEnableIcon,
  RightEnableIcon,
} from '../constants/IconData';
import { FONT_FAMILY } from '../constants/Theme';
import { getRowObject } from '../pages/assigned_audits/components/getRowObject';

/**
 *
 * @param {paginationReturnType} pagination
 */
export function paginationProps(pagination) {
    const canGoBack = pagination?.currentPage !== 1;
    const canGoNext = pagination?.totalPage !== pagination.currentPage;
  return {
    total: pagination.totalCount,
    pageCount: pagination.totalPage,
    size: pagination.showSize,
    currentPage: pagination.currentPage,
    LeftIcon: canGoBack ? <LeftEnableIcon /> : <LeftIcon />,
    RightIcon: canGoNext ? <RightEnableIcon /> : <RightIcon />
  };
}

export const defaultReactTableProp = {
  renderSortUpIcon,
  renderSortIcon,
  renderSortDownIcon,

  getCellProps,
  getHeaderCellProps,
  getRowProps,

  RightIcon: <RightIcon />,
  LeftIcon: <LeftIcon />,
};

export function renderSortUpIcon() {
  return (
    <UpIcon
      style={{
        width: 10,
        height: 10,
        marginLeft: 1,
      }}
    />
  );
}

export function renderSortDownIcon() {
  return (
    <DownIcon
      style={{
        width: 10,
        height: 10,
        marginLeft: 1,
      }}
    />
  );
}

export function renderSortIcon() {
  return (
    <SortIcon
      style={{
        width: 15,
        height: 15,
        marginLeft: 1,
      }}
    />
  );
}

export function getHeaderCellProps(header) {
  const startingHeaderId = ['members', 'name', 'actions'];
  return {
    textTransform: 'none',
    fontFamily: FONT_FAMILY.ptSans,
    fontWeight: 'bold',
    color: 'black',
    fontSize: '16px',
    justifyContent: startingHeaderId.includes(header.id)
      ? 'flex-start'
      : 'center',
  };
}

const defaultRowOptions = {
  colorRow: true,
  ignoreUrgent: false,
  backgroundColor: null,
};

export function getRowProps(row, options = defaultRowOptions) {
  const isUrgent = row.original?.urgent;
  const rowObject = getRowObject(row.original.status);
  const backgroundColor = isUrgent ? AppColors.lightDanger : rowObject.bgColor;

  const style = { backgroundColor };

  return { style, fontSize: '16px' };
}

export function getCellProps(cell, data) {
  let fontWeight = 'normal';

  const headerId = ['name', 'members', 'actions'];
  const targetHeader = data?.id.split('_')[1];
  return {
    style: {
      fontFamily: FONT_FAMILY.ptSans,
      color: AppColors.secondary,
      fontWeight: fontWeight,
      textAlign: headerId.includes(targetHeader) ? 'left' : 'center',
      fontSize: '16px',
    },
  };
}
