/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Avatar, Box, HStack, Text, useBoolean } from '@chakra-ui/react';
import {
  CQReactTable,
  DotMenu,
  useToastr,
} from '@prasanna-transcend/code-quick-components';
import { DotMenuIcon, UrgentIcon } from '../../../../constants/IconData';
import { AuditContext } from '../../../../providers/AuditProvider';
import { AuthContext } from '../../../../providers/AuthProvider';
import SpecialtyRow from '../../../../components/audit_table/SpecialityRow';
import { createColumnHelper } from '@tanstack/react-table';
import { toTitleCase } from '../../../../utils/common.utils';
import {
  ASSIGNED_AUDITS_ORDERING,
  ENABLE_EDIT_STATUS,
  getAuditStatus,
} from '../../../../constants/constants';
import useCQMediaQuery from '../../../../hooks/useCQMediaQuery';
import {
  defaultReactTableProp,
  paginationProps,
} from '../../../../utils/assigned_audit.util';
import { format, formatDistanceStrict } from 'date-fns';

import '../style.css';
import TotalPageCell from '../../../../components/audit_table/TotalPageCell';
import AssignAuditorCell from '../../../../components/audit_table/AssignAuditorCell';
import { SplitAuditModal } from '../SplitAuditModal';
import AuditModal from '../../../../components/modal/audit_modal/AuditModal';
import FileViewAction from '../FileViewAction';
import DeleteConfirmation from '../confirmation_modals/DeleteConfirmation';
import UrgentConfirmation from '../confirmation_modals/UrgentConfirmation';
import { successToast } from '../../../../components/successToast';
import AppColors from '../../../../constants/AppColors';
import { isPageEditable } from '../../isPageEditable';

const columnHelper = createColumnHelper();

const AllAuditTable = () => {
  const auditContext = useContext(AuditContext);
  const authContext = useContext(AuthContext);
  const toast = useToastr();
  useEffect(() => {
    if (auditContext.isAssigningChartToSelf) {
      toast.showLoading({
        description: `${'Loading'}`,
      });
    }
  }, [auditContext.isAssigningChartToSelf]);

  // state
  const [currentSplitUpload, setCurrentSplitUpload] = useState({});

  // booleans
  const [showSplitModal, ssmState] = useBoolean(false);
  const [selectedRowId, setSelectedRowId] = useState('');
  const [reqMissingBool, setReqMissingBool] = useBoolean(false);
  const [textArea, setTextArea] = useState('');
  const [textAreaValidation, setTextAreaValidation] = useBoolean(false);
  const [ordering, setOrdering] = useState('');

  // media query
  const canScroll = useCQMediaQuery({
    lg: false,
    md: false,
    sm: true,
  });

  const { isUpdating, getAuditUpload, updateSubmittedChart, filterParams } =
    auditContext;

  useEffect(() => {
    getAuditUpload.loadAPI();
  }, [filterParams, getAuditUpload.showOrdering]);

  useEffect(() => {
    if (ASSIGNED_AUDITS_ORDERING.includes(ordering)) {
      getAuditUpload.setParams({ ordering });
    }
  }, [ordering]);

  async function updateSpecialty(specialtyId, rowId) {
    updateSubmittedChart(rowId, {
      assigned_specialties: specialtyId,
      assigned_auditor: null,
    }).catch(e => { });
  }

  async function updateTotalPage(totalPage, rowId) {
    return updateSubmittedChart(rowId, { total_page: totalPage });
  }

  async function assignAuditor(auditorId, rowId) {
    updateSubmittedChart(rowId, { assigned_auditor: auditorId })
      .then(r => {
        const toastProps = {
          header: 'Success!',
          description: 'Auditor Assigned successfully',
        };
        successToast(toast, toastProps);
      })
      .catch(() => { });
  }

  function handleSplitAudit(row) {
    ssmState.on();
    setCurrentSplitUpload(row);
  }

  const requestMissingInfo = async () => {
    if (textArea?.length === 0 || textArea === ' ') {
      setTextAreaValidation.on();
    } else {
      await auditContext.requestMissingInfo(selectedRowId, textArea);
      setReqMissingBool.off();
    }
  };

  const renderActionComponent = auditUpload => {
    return (
      <HStack>
        <FileViewAction auditUpload={auditUpload} />
        <DotMenu
          icon={
            <DotMenuIcon
              style={{ width: '5px', height: '27px', color: '#D8D8D8' }}
            />
          }
          menuDataItem={[
            {
              isDisabled:
                !!auditUpload.is_splitted || auditUpload.total_page == 0,
              onClick: () => handleSplitAudit(auditUpload),
              children: (
                <Box p="5px" color={'black'}>
                  Split Audit
                </Box>
              ),
            },
            {
              children: <UrgentConfirmation data={auditUpload} />,
            },
            {
              children: (
                <div
                  onClick={() => {
                    setReqMissingBool.on();
                    setSelectedRowId(auditUpload.id);
                  }}
                  style={{ color: 'black', padding: '5px' }}
                >
                  Request Missing Info
                </div>
              ),
            },
            {
              children: (
                <DeleteConfirmation
                  data={auditUpload}
                  loadList={() => {
                    getAuditUpload.loadAPI();
                  }}
                />
              ),
            },
          ]}
        />
      </HStack>
    );
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        // id: 'chart_id',
        enableSorting: false,
        header: () => '',
        cell: info => {
          return (
            <HStack
              textAlign={'left'}
              justifyContent={'flex-start'}
              alignItems={'center'}
              spacing={3}
            >
              {info.row.original?.urgent ? (
                <UrgentIcon style={{ width: 20, height: 8 }} />
              ) : (
                <Box ml={2} />
              )}
            </HStack>
          );
        },
      }),
      columnHelper.accessor('upload_id', {
        id: 'chart_id',
        header: () => 'Upload #',
        cell: info => {
          return (
            <HStack
              textAlign={'left'}
              justifyContent={'flex-start'}
              alignItems={'center'}
              spacing={3}
            >
              <Text>{info.renderValue()}</Text>
            </HStack>
          );
        },
      }),
      columnHelper.accessor(row => row.uploaded_date, {
        id: 'upload_date',
        header: () => <span>Chart Upload</span>,
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return 'N/A';
          }

          return format(new Date(cellValue), 'MM/dd/yyyy');
        },
      }),
      columnHelper.accessor(p => p.client_name?.first_name, {
        id: 'client',
        header: () => 'Uploaded By',
        cell: info => {
          const name = toTitleCase(
            `${info.row.original.client_name?.first_name} ${info.row.original.client_name?.last_name}`
          );
          return <Text lineHeight={'27px'}>{name}</Text>;
        },
      }),
      columnHelper.accessor(row => row.specialties?.name, {
        header: () => 'Specialties',
        id: 'specialty__name',
        enableSorting: false,
        cell: info =>
          !info.row?.original?.specialties?.name ||
            !info.row.original?.assigned_auditor ||
            ENABLE_EDIT_STATUS.includes(info.row?.original.status) ? (
            <SpecialtyRow
              selectedValue={info.row.original?.specialties?.id}
              specialties={
                info.row.original.client_name.specialties?.length
                  ? info.row.original.client_name.specialties.map(d => ({
                    label: d.name,
                    value: d.id,
                  }))
                  : []
              }
              onChange={specialtyId =>
                updateSpecialty(specialtyId, info.row.original.id)
              }
            />
          ) : (
            toTitleCase(info.row?.original?.specialties?.name) ?? 'N/A'
          ),
      }),
      columnHelper.accessor('total_page', {
        header: () => 'Pages',
        id: 'total_pages',
        enableSorting: false,
        cell: info => {
          const isEditable = isPageEditable(info.row.original);

          if (!isEditable) {
            return <Text>{info.renderValue()}</Text>;
          }

          return (
            <TotalPageCell
              value={info.row.original?.total_page}
              onSave={v => updateTotalPage(v, info.row.original.id)}
            />
          );
        },
      }),
      columnHelper.accessor('status', {
        header: () => 'Status',
        id: 'status',
        cell: info => (
          <Text lineHeight={'26px'}>
            {getAuditStatus(info.row.original?.status)}
          </Text>
        ),
      }),
      columnHelper.accessor('updated_date', {
        header: () => 'Last Updated',
        id: 'updated_date',
        cell: info => {
          let updatedAt = info.getValue();

          updatedAt = updatedAt
            ? formatDistanceStrict(new Date(updatedAt), new Date(), {
              addSuffix: true,
            })
            : 'N/A';

          return (
            <Text>
              {updatedAt !== 'N/A' ? toTitleCase(updatedAt) : updatedAt}
            </Text>
          );
        },
      }),
      columnHelper.accessor('assigned_auditor', {
        header: () => 'Assigned to',
        id: 'auditor',
        enableSorting: false,
        cell: info => {
          const assignedAuditor = info.row.original?.assigned_auditor;
          let selectedValue = null;
          if (assignedAuditor) {
            selectedValue = {
              label: toTitleCase(
                `${assignedAuditor.first_name} ${assignedAuditor.last_name}`
              ),
              value: assignedAuditor.id,
              isCurrentUser: assignedAuditor.id === authContext.currentUser?.id,
            };
          }
          return !selectedValue ||
            !info.row?.original?.specialties?.name ||
            ENABLE_EDIT_STATUS.includes(info.row?.original.status) ? (
            <HStack w={200}>
              <AssignAuditorCell
                auditUpload={info.row.original}
                selectedValue={selectedValue}
                onChange={e => {
                  assignAuditor(e, info.row.original.id);
                }}
                selectedSpecialtyId={info.row.original.specialties?.id}
              />
            </HStack>
          ) : (
            <HStack>
              <Avatar
                name={selectedValue?.label}
                bg={AppColors.secondary}
                size="sm"
                color="white"
              // mr={2}
              />
              <Text>{selectedValue?.label ?? 'N/A'}</Text>
            </HStack>
          );
        },
      }),
      columnHelper.accessor('actions', {
        header: '',
        enableSorting: false,
        cell: info => {
          return renderActionComponent(info.row.original);
        },
      }),
    ],
    [auditContext.isAuditUploadListLoading, auditContext.auditUploadList]
  );

  function handlePreviousPage() {
    auditContext.getAuditUpload.loadPrevious();
  }

  function handleNextPage() {
    auditContext.getAuditUpload.loadNext();
  }

  function handleSizeChange(size) {
    auditContext.getAuditUpload({ size });
  }

  return (
    <Box
      mt={3}
      pointerEvents={isUpdating ? 'none' : 'inherit'}
      overflowX={canScroll ? 'scroll' : 'inherit'}
    >
      <CQReactTable
        manualSortBy={true}
        isLoading={auditContext.isAuditUploadListLoading}
        data={auditContext.auditUploadList}
        columns={columns}
        {...defaultReactTableProp}
        {...paginationProps(getAuditUpload)}
        onPreviousPage={handlePreviousPage}
        onNextPage={handleNextPage}
        onSizeChange={handleSizeChange}
        onAsc={header => {
          setOrdering(header);
        }}
        onDesc={header => {
          setOrdering(`-${header}`);
        }}
      />

      <SplitAuditModal
        isOpen={showSplitModal}
        onClose={ssmState.off}
        currentUpload={currentSplitUpload}
      />

      <AuditModal
        isOpen={reqMissingBool}
        onClose={() => setReqMissingBool.off()}
        title={'Audit Hold - Information Request'}
        text={
          'This will change the audit status to “on hold” until the missing information is received.'
        }
        keyText={'Note : '}
        placeholder={'Chart 12345 is missing information...'}
        height={159}
        width={772}
        submitButton={{
          children: 'Send',
          isLoading: auditContext.isReqMissing,
          loadingText: 'Sending',
          spinnerPlacement: 'end',
          disabled: !textArea,
          onClick: () => {
            requestMissingInfo();
          },
        }}
        cancelButton={{
          children: 'Cancel',
          onClick: () => {
            setReqMissingBool.off();
          },
        }}
        onChange={e => {
          setTextArea(e.target.value);
        }}
        setAlert={textAreaValidation}
      />
    </Box>
  );
};
export default AllAuditTable;
