import { useBoolean } from '@chakra-ui/react';
import { useToastr } from '@prasanna-transcend/code-quick-components';
import React, { useState } from 'react';
import faqHelpRepository from '../repositories/FaqRepository';

export const HelpContext = React.createContext({
  getFaqQuestions: async () => {},
  isFetching: false,
  faqQuestions: [],
});

function HelpProvider(props) {
  const toast = useToastr();
  const [isFetching, ifState] = useBoolean(false);
  const [faqQuestions, setFaqQuestions] = useState([]);

  const getFaqQuestions = async () => {
    try {
      ifState.on();
      const response = await faqHelpRepository.getFaqQuestions();
      const orderedQuestion = response
        ?.sort((q, p) => q.order - p.order)
        ?.filter(f => f.is_active);
      setFaqQuestions(orderedQuestion);
      return response;
    } catch (error) {
      toast.showError({
        description: `${error.toString()}`,
      });
    } finally {
      ifState.off();
    }
  };
  const mContext = {
    getFaqQuestions,
    isFetching,
    faqQuestions,
  };
  return (
    <HelpContext.Provider value={mContext}>
      {props.children}
    </HelpContext.Provider>
  );
}

export default HelpProvider;
