/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    useContext,
    useEffect,
    useMemo,
} from 'react';
import {
    Box,
    HStack,
    Text,
} from '@chakra-ui/react';
import {
    CQReactTable,
} from '@prasanna-transcend/code-quick-components';
import {
    UrgentIcon,
} from '../../../../constants/IconData';

import { createColumnHelper } from '@tanstack/react-table';
import { getAuditStatus } from '../../../../constants/constants';
import useCQMediaQuery from '../../../../hooks/useCQMediaQuery';
import { format, formatDistanceStrict } from 'date-fns';

import '../style.css';
import { renderActionComponent } from '../MyAuditsComponents';
import { defaultReactTableProp, paginationProps } from '../../../../utils/my_audits.utils';
import { useNavigate } from 'react-router-dom';
import { UserManagementContext } from '../../../../providers/UserManagementProvider';
import { toTitleCase } from '../../../../utils/common.utils';

const columnHelper = createColumnHelper();

const MyRebuttalTable = () => {
    const myAuditContext = useContext(UserManagementContext);
    const navigate = useNavigate();

    // media query
    const canScroll = useCQMediaQuery({
        lg: false,
        md: false,
        sm: true
    })

    const {
        isUpdating,
        getAuditRebuttals,
        filterParams
    } = myAuditContext;

    useEffect(() => {
        getAuditRebuttals.loadAPI();
    }, [filterParams]);


    const columns = useMemo(() => [
        columnHelper.accessor('upload_id', {
            header: () => 'Upload #',
            cell: info => {
                return <HStack justifyContent={'flex-start'} alignItems={'center'} spacing={10}>
                    {info.row.original?.urgent ? <UrgentIcon /> : <Box ml={2} />}
                    <Text>{info.renderValue()}</Text>
                </HStack>
            },
        }),
        columnHelper.accessor(row => row.uploaded_date, {
            id: 'uploaded_date',
            header: () => <span>Chart Upload</span>,
            cell: info => {
                const cellValue = info.getValue();
                if (!cellValue) {
                    return 'n/a';
                }

                return format(new Date(cellValue), 'MM/dd/yyyy')
            },
        }),
        //need to modify
        columnHelper.accessor(row => row.audited_date, {
            id: 'audited_date',
            header: () => <span>Audit Upload</span>,
            cell: info => {
                const cellValue = info.getValue();
                if (!cellValue) {
                    return 'n/a';
                }

                return format(new Date(cellValue), 'MM/dd/yyyy')
            },
        }),

        columnHelper.accessor(p => p.specialties?.name, {
            header: () => 'Specialties',
            id: 'specialty__name',
            cell: info => {
                const specialties = info.row.original.specialties?.name;

                if (!specialties) {
                    return 'N/A';
                }
                return specialties === 'ent' ? 'ENT' : toTitleCase(specialties);
            },
        }),
        columnHelper.accessor(p => p.assigned_qa?.first_name, {
            header: () => 'QA',
            id: 'qa',
            cell: info => {
                const cellValue = info.row.original.assigned_qa;
                if (!cellValue) {
                    return 'N/A';
                }

                return `${cellValue.first_name} ${cellValue.last_name}`;
            },
        }),
        columnHelper.accessor('status', {
            header: () => 'Status',
            cell: info => <Text>{getAuditStatus(info.row.original?.status)}</Text>
        }),

        columnHelper.accessor('updated_date', {
            header: () => 'Last Updated',
            cell: info => {
                let updatedAt = info.getValue();

                updatedAt = updatedAt ? formatDistanceStrict(
                    new Date(updatedAt),
                    new Date(),
                    {
                        addSuffix: true,
                    }
                )
                    : 'N/A';

                return <Text>{updatedAt}</Text>
            }
        }),
        columnHelper.accessor(row => row, {
            id: 'actions',
            header: () => 'Actions',
            enableSorting: false,
            cell: info => {
                const obj = info.getValue();
                return renderActionComponent(obj, navigate);
            }
        }),
    ], [myAuditContext.isAuditUploadListLoading]);

    function handlePreviousPage() {
        myAuditContext.getAuditRebuttals.loadPrevious();
    }

    function handleNextPage() {
        myAuditContext.getAuditRebuttals.loadNext();
    }

    function handleSizeChange(size) {
        myAuditContext.getAuditRebuttals({ size });
    }

    return (
        <Box pointerEvents={isUpdating ? 'none' : 'inherit'} overflowX={canScroll ? 'scroll' : 'inherit'}>
            <CQReactTable
                manualSortBy={true}
                isLoading={myAuditContext.isAuditUploadListLoading}
                data={myAuditContext.auditRebuttalsList}
                columns={columns}
                {...defaultReactTableProp}
                {...paginationProps(getAuditRebuttals)}
                onPreviousPage={handlePreviousPage}
                onNextPage={handleNextPage}
                onSizeChange={handleSizeChange}
            />
        </Box>
    );
};
export default MyRebuttalTable;
