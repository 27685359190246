/* eslint-disable react-hooks/exhaustive-deps */
import { AddIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Button, HStack, Text, useBoolean } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormTextInput,
  TextInput,
} from '@prasanna-transcend/code-quick-components';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import AppColors from '../../../constants/AppColors';
import { CREATE_MODE } from '../../../constants/constants';
import { ClientSelectIcon } from '../../../constants/IconData';
import ROUTES from '../../../constants/Routes';
import { FONT_FAMILY } from '../../../constants/Theme';
import { useComponentVisible } from '../../../hooks';
import { TeamContext } from '../../../providers/TeamProvider';
import { replaceRoute } from '../../../utils/common.utils';
import { ADD_TEAM_SCHEMA } from '../../../validation/Team.validation';
import { CustomMenuComponent } from './CustomMenuComponent';
import MemberList from './MemberList';
import SpecialtiesModal from './SpecialtiesModal';

const defaultValues = {
  name: '',
  specialties: [],
  members: [],
};

const TeamForm = props => {
  const {
    createTeam,
    updateTeam,
    getTeam,
    specialties,
    memberList,
    isLoading,
    getUserFromSpecialties,
  } = useContext(TeamContext);
  const { mode, teamId } = props;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (teamId) {
      //call get team by id and set the form values
      getTeam(teamId).then(response => {
        reset({
          ...response,
          members: response.members.map(m => m.id),
          specialties: response.specialties,
        });
      });
    }

    if (location?.state && Object.keys(location?.state).length) {
      getPreviousTeamData();
    }
  }, []);

  const getPreviousTeamData = async () => {
    if (location?.state.specialties?.length) {
      await getUserFromSpecialties({
        specialties: location?.state.specialties,
      });
    }

    reset({
      name: location?.state.name,
      specialties: location?.state.specialties,
      members: location?.state.members?.map(m => m),
    });
  };

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  // booleans
  const [isOpen, ioState] = useBoolean(false);
  // form
  const teamForm = useForm({
    resolver: yupResolver(ADD_TEAM_SCHEMA),
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
    setError: setCustomError,
  } = teamForm;

  const setErrors = errorObject => {
    const errorKeys = Object.keys(errorObject);
    errorKeys.forEach(key => {
      setCustomError(key, { type: 'custom', message: errorObject[key] });
    });
  };

  const handleCreateTeam = async formData => {
    //if create call create else call edit
    if (mode === CREATE_MODE) {
      const response = await createTeam(formData);
      if (response?.errors) {
        setErrors(response.errors);
      } else {
        navigate(ROUTES.TEAM);
      }
    } else {
      const response = await updateTeam(formData, teamId);
      if (response?.errors) {
        setErrors(response.errors);
      } else {
        navigate(ROUTES.TEAM);
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleCreateTeam)}>
        <HStack m={4} spacing={'8rem'}>
          <Box w={'sm'}>
            <Text
              fontSize={'sm'}
              fontFamily={FONT_FAMILY.ptSans}
              fontWeight={'bold'}
              mb={2}
            >
              Team Name
            </Text>
            <Box w="452px">
              <FormTextInput
                fontSize={'sm'}
                name="name"
                control={control}
                style={{ height: '50px' }}
                id="name"
                size="lg"
                placeholder="Team Name"
                errorTextStyle={{ fontSize: '14px', color: 'red' }}
              />
            </Box>
          </Box>
          <Box w="sm">
            <Text
              fontSize={'sm'}
              fontFamily={FONT_FAMILY.ptSans}
              fontWeight={'bold'}
              mb={3}
            >
              Specialties
            </Text>
            {!(getValues('specialties').length && specialties.length) ? (
              <Text
                fontSize={'sm'}
                color={AppColors.secondary}
                fontWeight="bold"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  ioState.on();
                }}
              >
                <AddIcon w={2} h={2} mr={1} />
                Add Specialties
              </Text>
            ) : (
              <Text
                textTransform={'capitalize'}
                fontFamily={FONT_FAMILY.ptSans}
                fontSize={'sm'}
              >
                {getValues('specialties')
                  .map(p => {
                    const obj = specialties.find(s => s.id === p);
                    return obj && Object.keys(obj).length
                      ? obj.name === 'ent'
                        ? 'ENT'
                        : obj.name
                      : '';
                  })
                  .toString()}{' '}
                |
                <span
                  style={{
                    fontWeight: 'bold',
                    color: AppColors.secondary,
                    marginLeft: 3,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    ioState.on();
                  }}
                >
                  Change
                </span>
              </Text>
            )}
            {errors['specialties']?.message && (
              <Text fontSize={'sm'} color={'red'}>
                Select atleast one specialties
              </Text>
            )}
          </Box>
        </HStack>
        <HStack mt={4} spacing={'8rem'} ml={4}>
          <Box w="sm">
            <Text
              fontSize={'sm'}
              fontFamily={FONT_FAMILY.ptSans}
              fontWeight={'bold'}
              mb={2}
            >
              Team Members
            </Text>
            <Box w="452px" ref={ref}>
              <TextInput
                onClick={() => {
                  setIsComponentVisible(prev => !prev);
                }}
                value={'Add Members'}
                style={{ height: '50px' }}
                fontSize="sm"
                rightIcon={
                  <ChevronDownIcon
                    style={{ marginTop: '10px' }}
                    onClick={() => {
                      setIsComponentVisible(prev => !prev);
                    }}
                  />
                }
              />
              {isComponentVisible && (
                <CustomMenuComponent
                  options={memberList}
                  selected={getValues('members')}
                  setSelected={data => setValue('members', data)}
                  setCustomError={setCustomError}
                />
              )}
              {errors['members']?.message && (
                <Text fontSize={'sm'} color={'red'}>
                  Select atleast one Members
                </Text>
              )}
            </Box>
          </Box>

          <Box w="sm" pt={'20px'}>
            <HStack>
              <ClientSelectIcon
                style={{ marginRight: 0, width: 15, height: 15 }}
              />
              <Text
                style={{ cursor: 'pointer' }}
                fontSize={'sm'}
                fontFamily={FONT_FAMILY.ptSans}
                fontWeight={'bold'}
                color={AppColors.secondary}
                onClick={() => {
                  const createNewUser = replaceRoute(
                    ROUTES.CREATE_NEW_USER_BY_TEAM,
                    {
                      teamId,
                    }
                  );
                  navigate(createNewUser, {
                    state: {
                      name: getValues('name'),
                      specialties: getValues('specialties'),
                      members: getValues('members'),
                      addNewMember: true,
                    },
                  });
                }}
              >
                Add New User
              </Text>
            </HStack>
          </Box>
        </HStack>
        {!isComponentVisible ? (
          <HStack ml={4}>
            <Box w="452px">
              <MemberList
                getValues={getValues}
                selected={getValues('members')}
                setSelected={data => setValue('members', data)}
              />
            </Box>{' '}
          </HStack>
        ) : null}
        <Box mt={12} ml={5}>
          <HStack>
            <Button
              mr={1}
              variant="outline"
              color={AppColors.secondary}
              height={'50px'}
              style={{
                paddingInline: '35px',
                // outlineColor: AppColors.secondary,
                border: `2px solid ${AppColors.secondary}`,
              }}
              outline={`2px`}
              _hover={{
                transition: 'all .3s ease',
                bgColor: AppColors.secondary,
                color: AppColors.white,
              }}
              fontSize="sm"
              borderRadius="3xl"
              onClick={() => {
                navigate(ROUTES.TEAM);
              }}
            >
              Cancel
            </Button>
            <Button
              borderRadius="3xl"
              color="white"
              fontSize="sm"
              bg={AppColors.primary}
              height={'50px'}
              style={{ paddingInline: '35px' }}
              type="submit"
              isLoading={isLoading}
              outline={`2px`}
              _hover={{
                transition: 'all .3s ease',
                bgColor: AppColors.white,
                color: AppColors.primary,
                outline: `2px solid ${AppColors.primary}`,
              }}
            >
              {mode === CREATE_MODE ? 'Create Team' : 'Save Changes'}
            </Button>
          </HStack>
        </Box>
      </form>

      <SpecialtiesModal
        specialties={specialties}
        isOpen={isOpen}
        onClose={ioState.off}
        selected={getValues('specialties')}
        setSelected={data => setValue('specialties', data)}
        setCustomError={setCustomError}
      />
    </>
  );
};
export default TeamForm;
