import { Box, Button, Heading, HStack } from '@chakra-ui/react';
import {
  TextInput,
  useBoolean,
} from '@prasanna-transcend/code-quick-components';
import React, { useContext, useEffect } from 'react';
import CQContainer from '../../components/layout/CQContainer';
import AppColors from '../../constants/AppColors';
import { SearchIcon } from '../../constants/IconData';
import { FONT_FAMILY } from '../../constants/Theme';
import { withProvider } from '../../hoc/withProvider';
import AccountsProvider, {
  AccountContext,
} from '../../providers/AccountsProvider';
import AddHealthSystemAccountForm from './forms/AddHealthSystemAccountForm';
import AccountsTable from './table/AccountsTable';

function AccountsContainer() {
  const accountsContext = useContext(AccountContext);
  const { getAccountsList, accountFilters } = accountsContext;
  const [searchItem, setSearchItem] = React.useState('');
  const [ordering, setOrdering] = React.useState('');

  const [accountFormVisible, afvState] = useBoolean(false);

  function handleOpenAddAccount() {
    afvState.on();
  }

  const onSearchAction = async () => {
    accountsContext.setAccountFilters(prev => ({ ...prev, search: searchItem }));
  };

  useEffect(() => {
    getAccountsList.loadAPI();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [accountFilters, getAccountsList.showOrdering
  ]);

  const onFilterChange = e => {
    if (e && e.target.value) {
      getAccountsList.setParams({ page: 1 })
      setSearchItem(e.target.value);
    } else {
      setSearchItem('');
    }
  };

  useEffect(() => {
    getAccountsList.setParams({ ordering });
  }, [ordering]);

  return (
    <CQContainer>
      <HStack
        w={'100%'}
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={4}
      >
        <Heading
          variant={'h6'}
          fontSize={'x-large'}
          fontFamily={FONT_FAMILY.baiJamurjee}
          fontWeight={600}
          width={'106px'}
          height={'30px'}
        >
          Accounts
        </Heading>
        <HStack>
          <Button
            variant="solid"
            style={{height:'50px'}}
            borderRadius={25}
            fontWeight={500}
            backgroundColor={AppColors.primary}
            width={'186px'}
            paddingInline={'80px'}
            onClick={handleOpenAddAccount}
            height={'40px'}
            color={'white'}
            outline={`2px`}
            _hover={{
              transition: 'all .1s ease',
              bgColor: AppColors.white,
              color: AppColors.primary,
              outline: `2px solid ${AppColors.primary}`,
            }}
          >
            + Add New Account
          </Button>
          <TextInput
            borderRadius={'md'}
            style={{height:'50px'}}
            rightIconProps={{ zIndex: 0 }}
            placeholder="Search"
            rightIcon={<SearchIcon onClick={onSearchAction} />}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                onSearchAction();
              }
            }}
            onChange={e => onFilterChange(e)}
          />
        </HStack>
      </HStack>

      <Box bg="white" p={4} w="100%" borderRadius="md" ml={0}>
        <AccountsTable searchValue={searchItem} setOrdering={setOrdering} />
      </Box>

      {/* FORM MODAL */}
      <AddHealthSystemAccountForm
        isOpen={accountFormVisible}
        onClose={afvState.off}
      />
    </CQContainer>
  );
}

export default withProvider(AccountsProvider, AccountsContainer);
