/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import CQContainer from '../../components/layout/CQContainer';
import { withProvider } from '../../hoc/withProvider';
import { FONT_FAMILY } from '../../constants/Theme';
import TeamProvider, { TeamContext } from '../../providers/TeamProvider';
import Team from './components/Team';
import { Text, HStack, Spacer } from '@chakra-ui/react';
import { TextInput } from '@prasanna-transcend/code-quick-components';
import { AddIcon } from '@chakra-ui/icons';
import AppColors from '../../constants/AppColors';
import { SearchIcon } from '../../constants/IconData';
import ROUTES from '../../constants/Routes';
import { useNavigate } from 'react-router-dom';

const TeamContainer = props => {
  const navigate = useNavigate();
  const [onSearchText, setSearchText] = useState('');
  const { handleApplyFilter, filterParams, getTeamList } =
    useContext(TeamContext);

  useEffect(() => {
    getTeamList.loadAPI();
  }, [filterParams]);

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, searchText: onSearchText });
  };

  const onFilterChange = e => {
    if (e) {
      getTeamList.setParams({ page: 1 });
      setSearchText(e.target.value);
    } else {
      setSearchText('');
    }
  };
  return (
    <>
      <CQContainer py={30} px={30}>
        <HStack mb={4}>
          <Text
            fontFamily={FONT_FAMILY.baiJamurjee}
            fontWeight={600}
            fontSize={'2xl'}
          >
            Teams
          </Text>
          <Spacer />
          <HStack>
            <Button
              bg={AppColors.primary}
              _hover={{
                bg: AppColors.white,
                color: AppColors.primary,
                border: `2px solid ${AppColors.primary}`,
              }}
              fontSize={'sm'}
              fontWeight="normal"
              size="md"
              borderRadius="3xl"
              color={'#fff'}
              px={10}
              fontStyle={'16px'}
              height={'50px'}
              width={'200px'}
              onClick={() => {
                navigate(ROUTES.ADD_TEAM);
              }}
            >
              <AddIcon w={2} h={2} mr={1} />
              Add New Team
            </Button>
            <TextInput
              height={'50px'}
              value={onSearchText}
              onChange={e => onFilterChange(e)}
              onKeyDown={e => {
                if (e.keyCode === 13) {
                  onSearchAction();
                }
              }}
              wrapperProps={{ bg: 'transparent' }}
              bg={'white'}
              rightIconProps={{ zIndex: 0 }}
              placeholder="Search"
              rightIcon={
                <SearchIcon
                  style={{ marginTop: '10px' }}
                  onClick={onSearchAction}
                />
              }
            />
          </HStack>
        </HStack>
        <Box bg="white" p={4} w="100%" h="150%" borderRadius="md" ml={0}>
          <Team />
        </Box>
      </CQContainer>
    </>
  );
};

export default withProvider(TeamProvider, TeamContainer);
